import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {CardHeader} from "@mui/material";
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import {
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Grid as DHXGrid,
    message as DHXMessage,
    Window as DHXWindow,
    Form as DHXForm
} from 'dhx-suite';
import 'dhx-suite/codebase/suite.min.css'
import axios from "axios";
import {invoiceReportGridColumn} from "./componentsConfig/invoiceReportGridColumn";
import {invoiceReportExportExcelGridColumn} from "./componentsConfig/invoiceReportExportExcelGridColumn";
import {invoiceReportGridFormConfig} from "./componentsConfig/invoiceReportGridFormConfig";
import {invoiceReportGridDeleteFormConfig} from "./componentsConfig/invoiceReportGridDeleteFormConfig";
import {windowFootAccept,windowFootClear,windowFootClose} from "./componentsConfig/windowConfig";
import {toolbarConfig} from "./componentsConfig/toolbarConfig";
import './style.css';
import Image_error from '../../../assets/images/error.png';
import Image_warning from '../../../assets/images/warning.png';
import api_url from "../../../context/UrlContext.js";
import Progress from "../../../assets/images/icons8-iphone-spinner.gif";

let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}


const InvoiceReport = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState(false);
    const [canEdit ,setCanEdit] = useState(false);
    const [canDelete ,setCanDelete] = useState(false);
    const [canPrint ,setCanPrint] = useState(false);

    let hotelsListId = "0";

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
        hotelsListId = hotel;
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXInvoiceReportLayout = useRef(null);

    let currentDate = "";
    let searchType = "month";

    useEffect(() => {

        currentDate = getDate(Date.now());

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:17},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>資料載入中</span></div>",
            css: "dhxProgressWindow1"
        });



        const dhxInvoiceReportLayout = new DHXLayout(DHXInvoiceReportLayout.current,{
            rows:[
                {id:"invoiceReportToolbar", height: "content"},
                {id: "invoiceReportGrid", height:"600px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {});
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    axios.post(apiServer+"/reports/searchInvoiceReportGrid",{
                        itemId1:hotel,itemId2:searchType,itemId3:toolbar1.getState("invoiceStatus"),itemId4:toolbar1.getState("updateStatus"),itemId5:toolbar1.getState("isDiscount"),itemId6:toolbar1.getState("startDate"),itemId7:toolbar1.getState("endDate")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    invoiceReportGrid.data.removeAll();
                                    invoiceReportGrid.data.parse(response.data);
                                    invoiceReportExportGrid.data.removeAll();
                                    invoiceReportExportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "day":
                    searchType = "day";
                    axios.post(apiServer+"/reports/searchInvoiceReportGrid",{
                        itemId1:hotel,itemId2:"day",itemId3:toolbar1.getState("invoiceStatus"),itemId4:toolbar1.getState("updateStatus"),itemId5:toolbar1.getState("isDiscount")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    invoiceReportGrid.data.removeAll();
                                    invoiceReportGrid.data.parse(response.data);
                                    invoiceReportExportGrid.data.removeAll();
                                    invoiceReportExportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "week":
                    searchType = "week";
                    axios.post(apiServer+"/reports/searchInvoiceReportGrid",{
                        itemId1:hotel,itemId2:"week",itemId3:toolbar1.getState("invoiceStatus"),itemId4:toolbar1.getState("updateStatus"),itemId5:toolbar1.getState("isDiscount")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    invoiceReportGrid.data.removeAll();
                                    invoiceReportGrid.data.parse(response.data);
                                    invoiceReportExportGrid.data.removeAll();
                                    invoiceReportExportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "month":
                    searchType = "month";
                    axios.post(apiServer+"/reports/searchInvoiceReportGrid",{
                        itemId1:hotel,itemId2:"month",itemId3:toolbar1.getState("invoiceStatus"),itemId4:toolbar1.getState("updateStatus"),itemId5:toolbar1.getState("isDiscount")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    invoiceReportGrid.data.removeAll();
                                    invoiceReportGrid.data.parse(response.data);
                                    invoiceReportExportGrid.data.removeAll();
                                    invoiceReportExportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "year":
                    searchType = "year";
                    axios.post(apiServer+"/reports/searchInvoiceReportGrid",{
                        itemId1:hotel,itemId2:"year",itemId3:toolbar1.getState("invoiceStatus"),itemId4:toolbar1.getState("updateStatus"),itemId5:toolbar1.getState("isDiscount")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    invoiceReportGrid.data.removeAll();
                                    invoiceReportGrid.data.parse(response.data);
                                    invoiceReportExportGrid.data.removeAll();
                                    invoiceReportExportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "search":
                    if((toolbar1.getState("startDate") === "" || toolbar1.getState("endDate") === "" || toolbar1.getState("startDate") === undefined || toolbar1.getState("endDate") === undefined)){
                        DHXMessage({
                            text: "請選擇日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(toolbar1.getState("startDate") !== "" && toolbar1.getState("endDate") !== "" && toolbar1.getState("startDate") !== undefined && toolbar1.getState("endDate") !== undefined && (toolbar1.getState("startDate") > toolbar1.getState("endDate"))){
                        DHXMessage({
                            text: "開始日期不得大於結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    searchType = "range";
                    axios.post(apiServer+"/reports/searchInvoiceReportGrid",{
                        itemId1:hotel,itemId2:searchType,itemId3:toolbar1.getState("invoiceStatus"),itemId4:toolbar1.getState("updateStatus"),itemId5:toolbar1.getState("isDiscount"),itemId6:toolbar1.getState("startDate"),itemId7:toolbar1.getState("endDate")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    invoiceReportGrid.data.removeAll();
                                    invoiceReportGrid.data.parse(response.data);
                                    invoiceReportExportGrid.data.removeAll();
                                    invoiceReportExportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "exportExcel":
                    invoiceReportExportGrid.export.xlsx({
                        name:"匯出營收發票月報表"+Date.now(),
                        url: "//export.dhtmlx.com/excel"
                    });
                    // currentDate = getDate(toolbar1.getState("searchTime"));
                    // axios.post(apiServer+"/reports/loadSalesReportGrid",{itemId1:hotel,itemId2:currentDate},headers)
                    //     .then((response) =>{
                    //         if(response.status === 200){
                    //             salesReportGrid.data.removeAll();
                    //             salesReportGrid.data.parse(response.data);
                    //         }else{
                    //             console.log("Server Error")
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         console.log(error);
                    //     });
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbarConfig)));

        const invoiceReportExportGrid = new DHXGrid(null, {
            columns: invoiceReportExportExcelGridColumn,
        });

        const invoiceReportGrid = new DHXGrid(null,{
            columns: invoiceReportGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "invoiceReportGrid_edit": function (e, data) {
                        if(canEdit){

                            invoiceReportGridForm.clear();
                            invoiceReportGridForm.setValue({"TMPId":"0"});

                            dhxInvoiceReportGridWindow1.header.data.update("title", {value: "編輯發票紀錄視窗"});

                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/loadProductInfo_invoiceGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response1) =>{
                                if(response1.status === 200) {
                                    if(response1.data){
                                        invoiceReportGridForm.setValue(response1.data[0]);
                                        dhxInvoiceReportGridWindow1.show();
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "invoiceReportGrid_delete": function (e, data) {

                        invoiceReportGridDeleteForm.clear();
                        invoiceReportGridDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎？");
                        // if(canDelete){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/loadProductInfo_invoiceGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) => {
                                if (response.status === 200) {
                                    if(response.data){
                                        invoiceReportGridDeleteForm.setValue(response.data[0])
                                        dhxInvoiceReportGridWindow2.show();
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            });
                        // }else{
                        //     dhxProgressWindow1.hide();
                        //     DHXMessage({
                        //         text: "請先確認擁有刪除的權限",
                        //         icon:"dxi dxi-close",
                        //         css: "dhx_message--error",
                        //         expire:5000
                        //     });
                        // }
                    }
                }
            }
        });

        axios.post(apiServer+"/reports/loadInvoiceReportGrid",{itemId1:hotel,itemId2:currentDate},headers)
            .then((response) =>{
                if(response.status === 200){
                    invoiceReportGrid.data.removeAll();
                    invoiceReportGrid.data.parse(response.data);
                    invoiceReportExportGrid.data.removeAll();
                    invoiceReportExportGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        dhxInvoiceReportLayout.getCell("invoiceReportToolbar").attach(toolbar1);
        dhxInvoiceReportLayout.getCell("invoiceReportGrid").attach(invoiceReportGrid);



        //編輯訂單視窗_產品資訊_發票紀錄_新增/編輯視窗
        const dhxInvoiceReportGridWindow1 = new DHXWindow({
            title: "編輯發票紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 400,
            footer: true,
            css:"dhxInvoiceReportGridWindow1"
        });

        dhxInvoiceReportGridWindow1.footer.data.add({type:"spacer"});
        dhxInvoiceReportGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxInvoiceReportGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxInvoiceReportGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(invoiceReportGridForm.getItem("AL103").getValue() === ""){
                        DHXMessage({
                            text: "請填入開立日期時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(invoiceReportGridForm.getItem("AL101").getValue() === ""){
                        DHXMessage({
                            text: "請填入發票號碼",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(invoiceReportGridForm.getItem("AL102").getValue() === ""){
                        DHXMessage({
                            text: "請填入發票金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/orders/checkInvoiceNumber",
                        invoiceReportGridForm.getValue(),headers)
                        .then((data) =>{
                            if(data.status === 200){
                                if(data.data){
                                    if(data.data[0].result === 0){
                                        DHXMessage({
                                            text: "發票號碼重複",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:10000
                                        });
                                        dhxProgressWindow1.hide();
                                    }else{
                                        axios.post(apiServer+"/orders/updateProductInfo_invoiceGridFormData",
                                            invoiceReportGridForm.getValue(),headers
                                        ).then((response) =>{
                                            if(response.status === 200){
                                                if(response.data){
                                                    DHXMessage({
                                                        text: response.data[0].message,
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--success",
                                                        expire:10000
                                                    });

                                                    axios.post(apiServer+"/reports/searchInvoiceReportGrid",{
                                                        itemId1:hotel,itemId2:searchType,itemId3:toolbar1.getState("invoiceStatus"),itemId4:toolbar1.getState("updateStatus"),itemId5:toolbar1.getState("isDiscount"),itemId6:toolbar1.getState("startDate"),itemId7:toolbar1.getState("endDate")
                                                    },headers)
                                                        .then((response) =>{
                                                            if(response.status === 200){
                                                                if(response.data){
                                                                    invoiceReportGrid.data.removeAll();
                                                                    invoiceReportGrid.data.parse(response.data);
                                                                    invoiceReportExportGrid.data.removeAll();
                                                                    invoiceReportExportGrid.data.parse(response.data);
                                                                    dhxInvoiceReportGridWindow1.hide();
                                                                    dhxProgressWindow1.hide();
                                                                }else{
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                }
                                                            }else{
                                                                dhxProgressWindow1.hide();
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        });
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                            .catch((error) => {
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });



                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxInvoiceReportGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const invoiceReportGridForm = new DHXForm(null,{
            rows: invoiceReportGridFormConfig
        });

        dhxInvoiceReportGridWindow1.attach(invoiceReportGridForm);

        //編輯訂單視窗_產品資訊_發票紀錄_刪除付款紀錄視窗
        const dhxInvoiceReportGridWindow2 = new DHXWindow({
            title: "刪除發票紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 630,
            footer: true,
            css:"dhxInvoiceReportGridWindow2"
        });

        dhxInvoiceReportGridWindow2.footer.data.add({type:"spacer"});
        dhxInvoiceReportGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxInvoiceReportGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxInvoiceReportGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(invoiceReportGridDeleteForm.getItem("AL110").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/deleteInvoice",
                        invoiceReportGridDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                axios.post(apiServer+"/reports/searchInvoiceReportGrid",{
                                    itemId1:hotel,itemId2:searchType,itemId3:toolbar1.getState("invoiceStatus"),itemId4:toolbar1.getState("updateStatus"),itemId5:toolbar1.getState("isDiscount"),itemId6:toolbar1.getState("startDate"),itemId7:toolbar1.getState("endDate")
                                },headers)
                                    .then((response) =>{
                                        if(response.status === 200){
                                            if(response.data){
                                                invoiceReportGrid.data.removeAll();
                                                invoiceReportGrid.data.parse(response.data);
                                                invoiceReportExportGrid.data.removeAll();
                                                invoiceReportExportGrid.data.parse(response.data);
                                                dhxInvoiceReportGridWindow2.hide();
                                                dhxProgressWindow1.hide();
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxInvoiceReportGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let invoiceReportGridDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"invoiceReportGridDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "invoiceReportGridDeleteForm"},
            ]
        });

        const invoiceReportGridDeleteForm = new DHXForm(null,{
            rows: invoiceReportGridDeleteFormConfig
        });

        dhxInvoiceReportGridWindow2.attach(invoiceReportGridDeleteFormLayout);
        invoiceReportGridDeleteFormLayout.getCell("invoiceReportGridDeleteForm").attach(invoiceReportGridDeleteForm);



        return () => {
            dhxInvoiceReportLayout && dhxInvoiceReportLayout.destructor();
        };
    }, [canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <h4 className={"gridTitle"}>營收發票月報表</h4>
                                <MDBox py={1} px={1}>
                                    <div ref={DHXInvoiceReportLayout}></div>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>

                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default InvoiceReport;
