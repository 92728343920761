let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}
export const faultReportFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {type: "input", name: "AA003", hidden: true, value: "0"},
    {type: "input", name: "AA004", hidden: true, value: "0"},
    {
        type: "text",
        name: "note",
        value: "此功能會扣掉庫存，請謹慎選取",
        css: "faultReportForm_tip",
    },
    {
        cols:[
            {
                type: "datepicker",
                label: "*通報日期：",
                dateFormat: "%Y-%m-%d",
                width:"33%",
                css:"rightMargin",
                name: "AA103",
                value:Date.now(),
            },
            {
                type: "datepicker",
                label: "*鎖定開始日期：",
                dateFormat: "%Y-%m-%d 00:00:00",
                width:"33%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin",
                name: "AA104",
            },
            {
                type: "datepicker",
                label: "*鎖定結束日期：",
                dateFormat: "%Y-%m-%d 23:59:59",
                width:"33%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin",
                name: "AA105",
            },
        ],
    },
    {
        cols:[
            {
                type: "input",
                label: "房型名稱：",
                css:"rightMargin",
                readOnly: true,
                width: "49.7%",
                name: "roomType",
            },
            {
                type: "input",
                label: "房間名稱：",
                css:"rightMargin",
                readOnly: true,
                width: "49.7%",
                name: "roomName",
            },
        ],
    },
    {
        type: "select",
        label: "*故障原因：",
        options: [
            {
                value: "0",
                content: "---請選擇---",
                disabled: true
            },
        ],
        name: "AA006",
    },
    {
        type: "textarea",
        label: "備註：",
        value: "",
        height: 100,
        name: "AA106",
    },
]