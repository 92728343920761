export const passwordGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:80,
        // template: function (e) {
        //     return '<i title="edit" class="fa-solid fa-pen passwordGrid_edit" style="color: #466fc4;font-size: 0;">'+e+'</i>&nbsp;<i title="delete" class="fa-solid fa-trash-can pwdMangGrid_delete" style="color: #c94747;font-size: 0;">'+e+'</i>';
        // }
    },
    { minWidth: 80, id: "item_no", width:80, header: [{ text: "項次" }] },
    { minWidth: 80, id: "pwdMang_user", width:150, header: [{ text: "使用人" },{content: "inputFilter"}] },
    // { id: "pwdMang_key_id", width:100, header: [{ text: "ID" },{content: "inputFilter"}] },
    { id: "pwdMang_roomName", width:150, header: [{ text: "房間" },{content: "inputFilter"}] },
    { id: "pwdMang_pwd", width:100, header: [{ text: "密碼" },{content: "inputFilter"}] },
    // { id: "pwdMang_pwdType", width:80, header: [{ text: "狀態" },{content: "inputFilter"}] },
    { id: "pwdMang_startTime", width:150, header: [{ text: "密碼開始日期" },{content: "inputFilter"}] },
    { id: "pwdMang_endTime", width:150, header: [{ text: "密碼結束日期" },{content: "inputFilter"}] },
    // { id: "pwdMang_optionStatus", width:200, header: [{ text: "Option Status" },{content: "inputFilter"}] },
    { width:120, id: "created_by", header: [{ text: "建立人員" }] },
    { width:150, id: "created_at", header: [{ text: "建立時間" }] },
    { width:120, id: "updated_by", header: [{ text: "更新人員" }] },
    { width:150, id: "updated_at", header: [{ text: "更新時間" }] },
];