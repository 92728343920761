export const mainProductGridColumn = [
    { id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:100,
        // template: function (e) {
        //     return "<i title='edit' class='dxi dxi-pencil mainProductGrid_edit' style='color: #466fc4;cursor: pointer'>"+e
        //         +"</i>&nbsp;<i title='delete' class='dxi dxi-delete-forever mainProductGrid_delete' style='color: #c94747;cursor: pointer'>"+e
        //         +"</i>";
        // }
    },
    { width: 60, id: "item_no", header: [{ text: "項次" }] },
    { width: 120, id: "main_products_type", header: [ { text: "主要產品類別" },{ content: "selectFilter" }],},
    { width: 120,id: "hotel_name",header: [{ text: "飯店名稱" },{ content: "inputFilter"}] },
    { width: 60, id: "serial_number", header: [{ text: "排序" }] },
    { width: 125,id: "main_products_name",header: [{ text: "房型名稱" },{ content: "inputFilter"}] },
    { width: 100,id: "status",header: [{ text: "狀態" },{ content: "inputFilter"}] },
    { width: 100,id: "fixed_price",header: [{ text: "定價" },{ content: "inputFilter"}] },
    { width: 120,id: "main_products_code",header: [{ text: "房型代號" },{ content: "inputFilter"}] },
    { width: 150,id: "hotelnabe",header: [{ text: "HotelNabe房型編號" },{ content: "inputFilter"}] },
    { width: 200,id: "stop_reason",header: [{ text: "停用原因" },{ content: "inputFilter"}] },
    { width:120, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:150, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:120, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:150, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]