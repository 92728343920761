import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    Tabbar as DHXTabbar,
    Grid as DHXGrid,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Form as DHXForm,
    message as DHXMessage,
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import {CardHeader} from "@mui/material";
import {orderGridColumn} from "./componentsConfig/orderGridColumn";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {orderFormConfig} from "./componentsConfig/orderFormConfig";
import {windowFootAccept, windowFootClear, windowFootClose, windowFootSend} from "./componentsConfig/windowConfig";
import {deleteFormConfig} from "./componentsConfig/deleteFormConfig";
import './style.css';
import {productInfo_otherPrdGridToolbarConfig} from "./componentsConfig/productInfo_otherPrdGridToolbarConfig";
import {productInfo_otherPrdGridFormConfig} from "./componentsConfig/productInfo_otherPrdGridFormConfig";
import {productInfo_otherPrdGridDeleteFormConfig} from "./componentsConfig/productInfo_otherPrdGridDeleteFormConfig";
import {productInfo_roomGridColumn} from "./componentsConfig/productInfo_roomGridColumn";
import {productInfo_otherPrdGridColumn} from "./componentsConfig/productInfo_otherPrdGridColumn";
import {productInfo_summaryFormConfig} from "./componentsConfig/productInfo_summaryFormConfig";
import {alertSetGridColumn} from "./componentsConfig/alertSetGridColumn";
import {alertSetGridFormConfig} from "./componentsConfig/alertSetGridFormConfig";
import {alertSetGridToolbarConfig} from "./componentsConfig/alertSetGridToolbarConfig";
import {alertSetGrid_deleteFormConfig} from "./componentsConfig/alertSetGrid_deleteFormConfig";
import {productInfo_roomGridToolbarConfig} from "./componentsConfig/productInfo_roomGridToolbarConfig";
import {productInfo_roomGridFormConfig} from "./componentsConfig/productInfo_roomGridFormConfig";
import {productInfo_roomGridDeleteFormConfig} from "./componentsConfig/productInfo_roomGridDeleteFormConfig"
import {orderInfo_purchaserGridColumn} from "./componentsConfig/orderInfo_purchaserGridColumn";
import {orderInfo_guestGridColumn} from "./componentsConfig/orderInfo_guestGridColumn";
import {orderInfo_guestGridToolbarConfig} from "./componentsConfig/orderInfo_guestGridToolbarConfig";
import {orderInfo_guestGridFormConfig,orderInfo_guestGridDeleteFormConfig} from "./componentsConfig/orderInfo_guestGridFormConfig";
import {orderInfo_purchaserGridFormConfig} from "./componentsConfig/orderInfo_purchaserGridFormConfig";
import {newOrder_purchaserGridFormConfig} from "./componentsConfig/newOrder_purchaserGridFormConfig";
import {newOrder_roomGridColumn} from "./componentsConfig/newOrder_roomGridColumn";
import {newOrder_roomGridFormConfig} from "./componentsConfig/newOrder_roomGridFormConfig";
import {newOrder_roomGridToolbarConfig} from "./componentsConfig/newOrder_roomGridToolbarConfig";
import {productInfo_payLogGridToolbarConfig} from "./componentsConfig/productInfo_payLogGridToolbarConfig";
import {productInfo_payLogGridFormConfig} from "./componentsConfig/productInfo_payLogGridFormConfig";
import {productInfo_payLogGridDeleteFormConfig} from "./componentsConfig/productInfo_payLogGridDeleteFormConfig";
import {productInfo_payLogGridColumn} from "./componentsConfig/productInfo_payLogGridColumn";
import {newOrder_payLogGridToolbarConfig} from "./componentsConfig/newOrder_payLogGridToolbarConfig";
import {newOrder_payLogGridFormConfig} from "./componentsConfig/newOrder_payLogGridFormConfig";
import {newOrder_payLogGridDeleteFormConfig} from "./componentsConfig/newOrder_payLogGridDeleteFormConfig";
import {newOrder_payLogGridColumn} from "./componentsConfig/newOrder_payLogGridColumn";
import Image_error from '../../../assets/images/error.png';
import axios from "axios";
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";
import {orderExportExcelGridColumn} from "./componentsConfig/orderExportExcelGridColumn";
import {newOrder_restRoomGridFormConfig} from "./componentsConfig/newOrder_restRoomGridFormConfig";
import {productInfo_restRoomGridFormConfig} from "./componentsConfig/productInfo_restRoomGridFormConfig";
import {productInfo_sendNewPayLinkFormConfig} from "./componentsConfig/productInfo_sendNewPayLinkFormConfig";
import Progress from "../../../assets/images/icons8-iphone-spinner.gif";
import {productInfo_invoiceGridColumn} from "../orderBasicSetting/componentsConfig/productInfo_invoiceGridColumn";
import {productInfo_invoiceGridToolbarConfig} from "../orderBasicSetting/componentsConfig/productInfo_invoiceGridToolbarConfig";
import {productInfo_invoiceGridFormConfig} from "../orderBasicSetting/componentsConfig/productInfo_invoiceGridFormConfig";
import {productInfo_invoiceGridDeleteFormConfig} from "../orderBasicSetting/componentsConfig/productInfo_invoiceGridDeleteFormConfig";

let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

let dateTime = new Date();
dateTime = dateTime.setDate(dateTime.getDate()+1);
dateTime = new Date(dateTime);

let DateDiff = function (date1, date2) {
    let strDate, oDate1, oDate2, result;
    strDate = date1.split("-");
    oDate1 = new Date(strDate[1] + '-' + strDate[2] + '-' + strDate[0]);
    strDate = date2.split("-");
    oDate2 = new Date(strDate[1] + '-' + strDate[2] + '-' + strDate[0]);
    result = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24);
    return result;
};

const UnpaidOrderBasicSetting = () => {

    const DHXOrderGridLayout = useRef(null);

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    let TMPId = "0";

    let toolbar1StartTimeValue = "",toolbar1EndTimeValue = "",toolbar1KeywordValue = "";

    let AA103 = 0, AA104 = 0;
    let restTypeText = '',price = '';
    let isNeedFirstChangeEventRest = false;
    let isNeedFirstChangeEvent = false;
    let isSaleSourceNeedChange = false;


    let newOrder_restRoomGridForm_AG004 = "";
    let newOrder_restRoomGridForm_AG005 = "";
    let productInfo_restRoomGridForm_AG004 = "";
    let productInfo_restRoomGridForm_AG005 = "";
    let newOrder_roomGridForm_AG004 = "";
    let newOrder_roomGridForm_AG005 = "";
    let productInfo_roomGridForm_AG004 = "";
    let productInfo_roomGridForm_AG005 = "";

    let orderGridSpanTmp = "";

    useEffect(()=>{

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:31},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>資料載入中</span></div>",
            css: "dhxProgressWindow1"
        });

        const dhxOrderGridLayout = new DHXLayout(DHXOrderGridLayout.current,{
            rows:[
                {id:"orderGridToolbar", height: "content"},
                {id:"orderGridLayout", height: "500px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        dhxProgressWindow1.show();
                        axios.get(apiServer+"/orders/getTMPId",headers)
                            .then((data) =>{
                                if(data.status === 200){
                                    if(data.data){
                                        TMPId = data.data[0].TMPId;
                                        newOrder_purchaserInfoForm.clear();
                                        newOrder_purchaserInfoForm.getItem("AA138").setValue("1");
                                        newOrder_purchaserInfoForm.getItem("saleSource").disable();
                                        newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                                        dhxProgressWindow1.hide();

                                        newOrder_roomGridWindow1.attach(newOrder_roomGridForm);
                                        newOrder_roomGrid.data.removeAll();
                                        newOrder_payLogGrid.data.removeAll();

                                        axios.get(apiServer+"/orders/loadCountiesList",headers)
                                            .then((response) =>{
                                                newOrder_purchaserInfoForm.getItem("AA004").setOptions(response.data);
                                            })
                                            .catch((error) => {
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });

                                        axios.get(apiServer+"/orders/loadOrderSourceList",headers)
                                            .then((response) =>{
                                                newOrder_purchaserInfoForm.getItem("orderSource").setOptions(response.data);
                                                newOrder_purchaserInfoForm.getItem("orderSource").setValue("0");
                                            })
                                            .catch((error) => {
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });

                                        dhxNewOrderWindow1.show();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });

                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }

                    break;
                case "search":

                    if((toolbar1.getState("toolbar1_startTime") === "" || toolbar1.getState("toolbar1_endTime") === "" || toolbar1.getState("toolbar1_startTime") === undefined || toolbar1.getState("toolbar1_endTime") === undefined) && (toolbar1.getState("toolbar1_keyword") === undefined || toolbar1.getState("toolbar1_keyword").trim() === "" )){
                        DHXMessage({
                            text: "請選擇日期或關鍵字",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(toolbar1.getState("toolbar1_startTime") !== "" && toolbar1.getState("toolbar1_endTime") !== "" && toolbar1.getState("toolbar1_startTime") !== undefined && toolbar1.getState("toolbar1_endTime") !== undefined && (toolbar1.getState("toolbar1_startTime") > toolbar1.getState("toolbar1_endTime"))){
                        DHXMessage({
                            text: "入住開始日期不得大於結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    toolbar1StartTimeValue = "";
                    toolbar1EndTimeValue = "";
                    toolbar1KeywordValue = "";

                    if(toolbar1.getState("toolbar1_keyword") !== undefined && toolbar1.getState("toolbar1_keyword").trim() !== ""){
                        toolbar1KeywordValue = toolbar1.getState("toolbar1_keyword").trim();
                    };

                    if(toolbar1.getState("toolbar1_startTime") !== "" && toolbar1.getState("toolbar1_endTime") !== "" && toolbar1.getState("toolbar1_startTime") !== undefined && toolbar1.getState("toolbar1_endTime") !== undefined){
                        toolbar1StartTimeValue = toolbar1.getState("toolbar1_startTime");
                        toolbar1EndTimeValue = toolbar1.getState("toolbar1_endTime");
                    };

                    dhxOrderGrid.data.removeAll();
                    axios.post(apiServer+"/orders/searchOrderGrid", {
                        itemId1: toolbar1StartTimeValue, itemId2: toolbar1EndTimeValue, itemId3:hotel, itemId4:toolbar1KeywordValue
                    },headers).then((data) =>{
                        dhxOrderGrid.data.parse(data.data);
                    });

                    orderExportExcelGrid.data.removeAll();
                    axios.post(apiServer+"/orders/searchOrderExportExcelGrid", {
                        itemId1: toolbar1StartTimeValue, itemId2: toolbar1EndTimeValue, itemId3:hotel, itemId4:toolbar1KeywordValue
                    },headers).then((data) =>{;
                        orderExportExcelGrid.data.parse(data.data);
                    });

                    break;
                case "reload":
                    dhxProgressWindow1.show();
                    dhxOrderGrid.data.removeAll();

                    if(orderGridSpanTmp != ""){
                        orderGridSpanTmp.map((item)=>{
                            dhxOrderGrid.removeSpan(item.row,item.column);
                        })
                    }

                    axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                        itemId1:hotel
                    },headers)
                        .then((data) =>{
                            if (data.status === 200){
                                if(data.data){
                                    dhxOrderGrid.data.parse(data.data.order_json);

                                    orderGridSpanTmp = data.data.spanArray;

                                    setTimeout(()=>{
                                        if(data.data.spanArray.length != 0){
                                            data.data.spanArray.map((item)=>{
                                                dhxOrderGrid.addSpan(item);
                                                dhxProgressWindow1.hide();
                                            })
                                        }else{
                                            dhxProgressWindow1.hide();
                                        };
                                    },1000)

                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    orderExportExcelGrid.data.removeAll();
                    axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                        itemId1:hotel
                    },headers)
                        .then((data) =>{
                            if(data.status === 200){
                                if(data.data){
                                    orderExportExcelGrid.data.parse(data.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "exportExcel":

                    orderExportExcelGrid.export.xlsx({
                        name:"匯出未付款訂單"+Date.now(),
                        url: "//export.dhtmlx.com/excel"
                    });
                    break;
                default:
                    break;
            }
        });

        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        //訂單列表
        const dhxOrderGrid = new DHXGrid(null,{
            columns: orderGridColumn,
            autoWidth: true,
            resizable: true,
            tooltip: false,
            sortable:false,
            // data: data1,
            rowCss: function (row) {
                switch (row.order_status_code){
                    case "0":
                        return "grid_unpay";
                    case "1":
                        return "grid_pay";
                    case "2":
                        return "";
                    case "3":
                        return "room_noRoom";
                    case "9":
                        return "grid_delete";
                }
            },
            eventHandlers: {
                onclick: {
                    "orderGrid_edit": function (e, data) {
                        isNeedFirstChangeEventRest = false;
                        isNeedFirstChangeEvent = false;
                        isSaleSourceNeedChange = false;

                        if(canEdit){
                            orderForm.clear();

                            dhxProgressWindow1.show();

                            orderForm.getItem("AA001").setValue(data.row.order_id);
                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: data.row.order_id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        orderForm.setValue(response.data[0]);

                                        if(response.data[0].unpaid > 0){
                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                        }else{
                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                        }

                                        orderForm.getItem("AA006").setOptions(response.data[0].orderSourceItem);
                                        orderForm.getItem("AA006").setValue(response.data[0].AA006)
                                        orderForm.getItem("AA008").setOptions(response.data[0].saleSourceItem);
                                        orderForm.getItem("AA008").setValue(response.data[0].AA008)

                                        setTimeout(()=>{
                                            productInfo_otherPrdGridForm.getItem("AC002").setValue(orderForm.getItem("AA001").getValue());
                                            productInfo_otherPrdGridForm.getItem("AC003").setValue(orderForm.getItem("AA002").getValue());

                                            orderInfo_purchaserGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadPurchaserGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                orderInfo_purchaserGrid.data.parse(data.data);
                                            });

                                            orderInfo_guestGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadGuestGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                orderInfo_guestGrid.data.parse(data.data);
                                            });

                                            productInfo_roomGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadRoomGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                if(data.status === 200){
                                                    if(data.data){
                                                        productInfo_roomGrid.data.parse(data.data);

                                                        dhxOrderWindow1.show();
                                                        dhxTabbar.setActive("orderInfo");
                                                        dhxProgressWindow1.hide();
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            });

                                            alertSetGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadAlertSetGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                alertSetGrid.data.parse(data.data);
                                            });

                                            productInfo_payLogGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadPayLogGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                productInfo_payLogGrid.data.parse(data.data);
                                            });

                                            productInfo_invoiceGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadInvoiceGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                productInfo_invoiceGrid.data.parse(data.data);
                                            });

                                            productInfo_otherPrdGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                productInfo_otherPrdGrid.data.parse(data.data);
                                            });

                                        },500)

                                        isSaleSourceNeedChange = true;
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }

                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });


                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "orderGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.get(apiServer+"/orders/loadHotelBookingCanceTermList",headers)
                                .then((response) =>{
                                    orderDeleteForm.getItem("AA005").setOptions(response.data);
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: data.row.order_id
                            },headers).then((response) =>{
                                orderDeleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            dhxOrderWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "viewOrderDetail": function (e, data) {
                        e.preventDefault();

                        isNeedFirstChangeEventRest = false;
                        isNeedFirstChangeEvent = false;
                        isSaleSourceNeedChange = false;
                        if(canEdit){

                            orderForm.clear();

                            dhxProgressWindow1.show();

                            orderForm.getItem("AA001").setValue(data.row.order_id);
                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: data.row.order_id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        orderForm.setValue(response.data[0]);

                                        if(response.data[0].unpaid > 0){
                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                        }else{
                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                        }

                                        orderForm.getItem("AA006").setOptions(response.data[0].orderSourceItem);
                                        orderForm.getItem("AA006").setValue(response.data[0].AA006)
                                        orderForm.getItem("AA008").setOptions(response.data[0].saleSourceItem);
                                        orderForm.getItem("AA008").setValue(response.data[0].AA008)

                                        isSaleSourceNeedChange = true;

                                        setTimeout(()=>{
                                            productInfo_otherPrdGridForm.getItem("AC002").setValue(orderForm.getItem("AA001").getValue());
                                            productInfo_otherPrdGridForm.getItem("AC003").setValue(orderForm.getItem("AA002").getValue());

                                            orderInfo_purchaserGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadPurchaserGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                orderInfo_purchaserGrid.data.parse(data.data);
                                            });

                                            orderInfo_guestGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadGuestGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                orderInfo_guestGrid.data.parse(data.data);
                                            });

                                            productInfo_roomGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadRoomGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                if(data.status === 200){
                                                    if(data.data){
                                                        productInfo_roomGrid.data.parse(data.data);

                                                        dhxOrderWindow1.show();
                                                        dhxTabbar.setActive("orderInfo");
                                                        dhxProgressWindow1.hide();
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            });

                                            alertSetGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadAlertSetGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                alertSetGrid.data.parse(data.data);
                                            });

                                            productInfo_payLogGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadPayLogGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                productInfo_payLogGrid.data.parse(data.data);
                                            });

                                            productInfo_invoiceGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadInvoiceGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                productInfo_invoiceGrid.data.parse(data.data);
                                            });

                                            productInfo_otherPrdGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                                                itemId1: data.row.order_id
                                            },headers).then((data) =>{
                                                productInfo_otherPrdGrid.data.parse(data.data);
                                            });

                                        },500)
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                }
            }
        });

        if(orderGridSpanTmp != ""){
            orderGridSpanTmp.map((item)=>{
                dhxOrderGrid.removeSpan(item.row,item.column);
            })
        }

        axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
            itemId1:hotel
        },headers)
            .then((data) =>{
                if (data.status === 200){
                    if(data.data){

                        dhxOrderGrid.data.removeAll();
                        dhxOrderGrid.data.parse(data.data.order_json);

                        orderGridSpanTmp = data.data.spanArray;

                        setTimeout(()=>{
                            if(data.data.spanArray.length != 0){
                                data.data.spanArray.map((item)=>{
                                    dhxOrderGrid.addSpan(item);
                                    dhxProgressWindow1.hide();
                                })
                            }else{
                                dhxProgressWindow1.hide();
                            };
                        },1000)

                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    dhxProgressWindow1.hide();
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
            .catch((error) => {
                dhxProgressWindow1.hide();
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
            itemId1:hotel
        },headers)
            .then((data) =>{
                if(data.status === 200){
                    if(data.data){
                        orderExportExcelGrid.data.removeAll();
                        orderExportExcelGrid.data.parse(data.data);
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        dhxOrderGridLayout.getCell("orderGridToolbar").attach(toolbar1);
        dhxOrderGridLayout.getCell("orderGridLayout").attach(dhxOrderGrid);

        //編輯訂單視窗
        const dhxOrderWindow1 = new DHXWindow({
            title: "訂單詳細視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 600,
            width: 800,
            footer: true,
            css: "dhxOrderWindow1"
        });

        dhxOrderWindow1.footer.data.add({type:"spacer"});
        dhxOrderWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    dhxProgressWindow1.show();
                    let orderFormValue = Object.assign({},orderForm.getValue(), {TMPId: "0"});
                    axios.post(apiServer+"/orders/updateOrderFormData",
                        orderFormValue,
                        headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                toolbar1StartTimeValue = toolbar1.getState("toolbar1_startTime");
                                toolbar1EndTimeValue = toolbar1.getState("toolbar1_endTime");

                                toolbar1KeywordValue = "";
                                if(toolbar1.getState("toolbar1_keyword") !== undefined && toolbar1.getState("toolbar1_keyword").trim() !== ""){
                                    toolbar1KeywordValue = toolbar1.getState("toolbar1_keyword").trim();
                                };

                                dhxOrderGrid.data.removeAll();
                                axios.post(apiServer+"/orders/searchOrderGrid", {
                                    itemId1: toolbar1StartTimeValue, itemId2: toolbar1EndTimeValue, itemId3:hotel, itemId4:toolbar1KeywordValue
                                },headers).then((data) =>{
                                    if(data.status === 200){
                                        if(data.data){
                                            dhxOrderGrid.data.parse(data.data);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                });

                                orderExportExcelGrid.data.removeAll();
                                axios.post(apiServer+"/orders/searchOrderExportExcelGrid", {
                                    itemId1: toolbar1StartTimeValue, itemId2: toolbar1EndTimeValue, itemId3:hotel, itemId4:toolbar1KeywordValue
                                },headers).then((data) =>{;
                                    orderExportExcelGrid.data.parse(data.data);
                                });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    dhxOrderWindow1.hide();
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        let dhxTabbar = new DHXTabbar(null,{
            views: [
                {tab: "訂單資訊", id: "orderInfo"},
                {tab: "產品資訊", id: "productInfo"},
                {tab: "提醒設定", id: "alertSet"},
            ],
        });

        dhxOrderWindow1.attach(dhxTabbar);

        //編輯訂單視窗_產品資訊
        let orderBasicSetTabLayout1 = new DHXLayout(null,{
            rows:[
                {id:"orderInfo_orderInfo", height: "340px", css: "orderInfo_orderInfo"},
                {id:"orderInfo_purchaserGridTitle", height: "30px",html:"<span style='font-weight: bold'>訂房人</span>"},
                {id:"orderInfo_purchaserGrid", height: "100px"},
                {id:"orderInfo_guestGridToolbar", height: "50px", css: "orderInfo_guestGridToolbar"},
                {id:"orderInfo_guestGrid",height: "200px"},
            ]
        });

        //編輯訂單視窗_訂單資訊
        const orderForm = new DHXForm(null,{
            rows: orderFormConfig,
            css: "orderForm"
        });

        orderForm.getItem("AA006").events.on("change", function (name, value){
            console.log(value);
            if(isSaleSourceNeedChange){
                axios.post(apiServer+"/orders/loadSaleSourceList", {
                        itemId1: orderForm.getItem("AA006").getValue()
                    },headers
                ).then((response) =>{
                    if(response.status === 200){
                        if(response.data){
                            orderForm.getItem("AA008").setOptions(response.data);
                            orderForm.getItem("AA008").setValue("0");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
            }
        });

        orderBasicSetTabLayout1.getCell("orderInfo_orderInfo").attach(orderForm);

        //編輯訂單視窗_產品資訊_房間
        let orderInfo_purchaserGrid = new DHXGrid(null,{
            columns: orderInfo_purchaserGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "orderInfo_purchaserGrid_edit": function (e, data) {
                        if(canEdit){
                            orderInfo_purchaserGridForm.getItem("AA001").setValue(data.row.id);

                            axios.get(apiServer+"/orders/loadCountiesList",headers).then((response) =>{
                                orderInfo_purchaserGridForm.getItem("AA004").setOptions(response.data);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                orderInfo_purchaserGridForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxOrderInfo_purchaserGridWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                }
            }
        });

        //編輯訂單視窗_產品資訊_編輯訂房人視窗
        const dhxOrderInfo_purchaserGridWindow1 = new DHXWindow({
            title: "編輯訂房人視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:700,
            height: 500,
            footer: true,
            css: "dhxOrderInfo_purchaserGridWindow1"
        });

        dhxOrderInfo_purchaserGridWindow1.footer.data.add({type:"spacer"});
        dhxOrderInfo_purchaserGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderInfo_purchaserGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderInfo_purchaserGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(orderInfo_purchaserGridForm.getItem("AA106").getValue() === ""){
                        DHXMessage({
                            text: "請填入姓名",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    // if(orderInfo_purchaserGridForm.getItem("AA109").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填入電話",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/orders/updatePurchaser",
                        orderInfo_purchaserGridForm.getValue(),
                        headers).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            orderInfo_purchaserGridForm.clear();
                            orderInfo_purchaserGridForm.setValue({"AA001": "0"});

                            dhxOrderInfo_purchaserGridWindow1.hide();

                            axios.post(apiServer+"/orders/loadPurchaserGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                orderInfo_purchaserGrid.data.removeAll();
                                orderInfo_purchaserGrid.data.parse(data.data);
                            });

                            dhxOrderGrid.data.removeAll();

                            if(orderGridSpanTmp != ""){
                                orderGridSpanTmp.map((item)=>{
                                    dhxOrderGrid.removeSpan(item.row,item.column);
                                })
                            }

                            axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                                itemId1:hotel
                            },headers)
                                .then((data) =>{
                                    if (data.status === 200){
                                        if(data.data){

                                            dhxOrderGrid.data.removeAll();
                                            dhxOrderGrid.data.parse(data.data.order_json);

                                            orderGridSpanTmp = data.data.spanArray;

                                            setTimeout(()=>{
                                                if(data.data.spanArray.length != 0){
                                                    data.data.spanArray.map((item)=>{
                                                        dhxOrderGrid.addSpan(item);
                                                        dhxProgressWindow1.hide();
                                                    })
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                };
                                            },1000)

                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            orderExportExcelGrid.data.removeAll();
                            axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                                itemId1:hotel
                            },headers)
                                .then((data) =>{
                                    if(data.status === 200){
                                        if(data.data){
                                            orderExportExcelGrid.data.parse(data.data);
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderInfo_purchaserGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const orderInfo_purchaserGridForm = new DHXForm(null,{
            rows: orderInfo_purchaserGridFormConfig
        });

        dhxOrderInfo_purchaserGridWindow1.attach(orderInfo_purchaserGridForm);

        // 編輯訂單視窗_訂單資訊_入住人
        let orderInfo_guestGrid = new DHXGrid(null,{
            columns: orderInfo_guestGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "orderInfo_guestGrid_edit": function (e, data) {
                        if(canEdit){
                            orderInfo_guestGridForm.clear();
                            dhxOrderInfo_guestGridWindow1.header.data.update("title", {value: "編輯入住人視窗"});

                            orderInfo_guestGridForm.getItem("AF001").setValue(data.row.id);

                            axios.post(apiServer+"/orders/loadGuestFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                orderInfo_guestGridForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });

                            dhxOrderInfo_guestGridWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "orderInfo_guestGrid_delete": function (e, data) {
                        if(canDelete){
                            guestGrid_deleteForm.getItem("AF001").setValue(data.row.id);
                            axios.post(apiServer+"/orders/loadGuestFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                guestGrid_deleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxOrderInfo_guestGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            },
        });

        const orderInfo_guestGridToolbar = new DHXToolbar(null, {
        });
        orderInfo_guestGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){

                        orderInfo_guestGridForm.clear();
                        dhxOrderInfo_guestGridWindow1.header.data.update("title", {value: "新增入住人視窗"});
                        orderInfo_guestGridForm.setValue({"AF001":"0","AF002":hotel,"AF003":orderForm.getItem("AA001").getValue()});
                        axios.get(apiServer+"/orders/loadCountiesList",headers)
                            .then((response) =>{
                                orderInfo_guestGridForm.getItem("AF004").setOptions(response.data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        dhxOrderInfo_guestGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });

        orderInfo_guestGridToolbar.data.parse(JSON.parse(JSON.stringify(orderInfo_guestGridToolbarConfig)));

        orderBasicSetTabLayout1.getCell("orderInfo_guestGridToolbar").attach(orderInfo_guestGridToolbar);

        //編輯訂單視窗_訂單資訊_入住人_新增/編輯入住人視窗
        const dhxOrderInfo_guestGridWindow1 = new DHXWindow({
            title: "新增入住人視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:700,
            height: 500,
            footer: true,
            css: "dhxOrderInfo_guestGridWindow1"
        });

        dhxOrderInfo_guestGridWindow1.footer.data.add({type:"spacer"});
        dhxOrderInfo_guestGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderInfo_guestGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderInfo_guestGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(orderInfo_guestGridForm.getItem("AF109").getValue() === ""){
                        DHXMessage({
                            text: "請填入姓名",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(orderInfo_guestGridForm.getItem("AF115").getValue() === ""){
                        DHXMessage({
                            text: "請填入電話",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    axios.post(apiServer+"/orders/updateGuestFormData",
                        orderInfo_guestGridForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/orders/loadGuestGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                orderInfo_guestGrid.data.removeAll();
                                orderInfo_guestGrid.data.parse(data.data);
                            });

                            orderInfo_guestGridForm.clear();
                            orderInfo_guestGridForm.setValue({"AF001": "0"});

                            dhxOrderInfo_guestGridWindow1.hide();
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderInfo_guestGridWindow1.hide();
                    orderInfo_guestGridForm.clear();
                    break;
                default:
                    break;
            }
        });

        const orderInfo_guestGridForm = new DHXForm(null,{
            rows: orderInfo_guestGridFormConfig
        });

        dhxOrderInfo_guestGridWindow1.attach(orderInfo_guestGridForm);

        //編輯訂單視窗_訂單資訊_入住人_刪除入住人視窗
        const dhxOrderInfo_guestGridWindow2 = new DHXWindow({
            title: "刪除入住人視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 600,
            footer: true,
            css: "dhxOrderInfo_guestGridWindow2"
        });

        dhxOrderInfo_guestGridWindow2.footer.data.add({type:"spacer"});
        dhxOrderInfo_guestGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderInfo_guestGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderInfo_guestGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(guestGrid_deleteForm.getItem("AF125").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    guestGrid_deleteFormParams = guestGrid_deleteForm.getValue();
                    axios.post(apiServer+"/orders/deleteGuest",guestGrid_deleteForm.getValue(),headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data[0].result > 0){
                                    DHXMessage({
                                        text: "更新成功",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    orderInfo_guestGrid.data.removeAll();

                                    axios.post(apiServer+"/orders/loadGuestGrid", {
                                        itemId1: orderForm.getItem("AA001").getValue()
                                    },headers).then((data) =>{
                                        orderInfo_guestGrid.data.removeAll();
                                        orderInfo_guestGrid.data.parse(data.data);
                                    });

                                    dhxOrderInfo_guestGridWindow2.hide();
                                    guestGrid_deleteForm.clear();
                                    guestGrid_deleteForm.setValue({"AF001": "0","AA001": "0"});
                                    guestGrid_deleteForm.clear("validation");
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--danger",
                                    expire:5000
                                });
                            }

                        }).catch((error) => {
                        console.log(error);
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderInfo_guestGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let guestGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"guestGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "guestGrid_deleteForm", css:"guestGrid_deleteForm"},
            ]
        });

        const guestGrid_deleteForm = new DHXForm(null,{
            rows: orderInfo_guestGridDeleteFormConfig
        });

        let guestGrid_deleteFormParams = guestGrid_deleteForm.getValue();
        dhxOrderInfo_guestGridWindow2.attach(guestGrid_deleteFormLayout);
        guestGrid_deleteFormLayout.getCell("guestGrid_deleteForm").attach(guestGrid_deleteForm);

        dhxTabbar.getCell("orderInfo").attach(orderBasicSetTabLayout1);
        orderBasicSetTabLayout1.getCell("orderInfo_purchaserGrid").attach(orderInfo_purchaserGrid);
        orderBasicSetTabLayout1.getCell("orderInfo_guestGrid").attach(orderInfo_guestGrid);

        //編輯訂單視窗_產品資訊
        let orderBasicSetTabLayout2 = new DHXLayout(null,{
            rows:[
                {id:"productInfo_roomGridToolbar", height: "50px"},
                {id:"productInfo_roomGrid", height: "300px"},
                {id:"productInfo_otherPrdGridToolbar", height: "50px"},
                {id:"productInfo_otherPrdGrid",height: "250px"},
                {id:"productInfo_payLogGridToolbar", height: "50px"},
                {id:"productInfo_payLogGrid", height: "300px"},
                {id:"productInfo_invoiceGridToolbar", height: "50px"},
                {id:"productInfo_invoiceGrid", height: "250px"},
            ]
        });

        //編輯訂單視窗_產品資訊_房間
        let productInfo_roomGrid = new DHXGrid(null,{
            columns: productInfo_roomGridColumn,
            autoWidth: true,
            resizable: true,
            rowCss: function (row) {
                if(row.roomMatchStatus == "0"){
                    return "room_noRoom"
                }
            },
            eventHandlers: {
                onclick: {
                    "productInfo_roomGrid_edit": function (e, data) {
                        isNeedFirstChangeEventRest = false;
                        isNeedFirstChangeEvent = false;
                        TMPId = "0";
                        if(canEdit){
                            DHXMessage({
                                text: "讀取資料中，請稍後",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            dhxProgressWindow1.show();
                            productInfo_roomGridForm.clear();
                            dhxProductInfo_roomGridWindow1.header.data.update("title", {value: "更新房間視窗"});

                            axios.post(apiServer+"/orders/loadRoomFormData", {
                                itemId1: data.row.id,itemId2:data.row.bookingType
                            },headers).then((response) =>{
                                if(response.status == 200){
                                    if(response.data){
                                        switch (data.row.bookingType){
                                            case 1:
                                                productInfo_roomGridForm.setValue(response.data[0]);
                                                productInfo_roomGridForm.getItem("AG001").setValue(response.data[0].AG001);
                                                productInfo_roomGridForm.getItem("AG002").setValue(response.data[0].AG002);
                                                productInfo_roomGridForm.getItem("AG003").setValue(response.data[0].AG003);
                                                productInfo_roomGridForm.getItem("AG119").setValue(response.data[0].AG119);
                                                productInfo_roomGridForm.getItem("AG120").setValue(response.data[0].AG120);

                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                ).then((response2) =>{
                                                    if(response2.status === 200){
                                                        if(response2.data){
                                                            let productInfo_roomGridForm_AG007 = productInfo_roomGridForm.getItem("AG007").getWidget();
                                                            if(response2.data.length > 1){
                                                                productInfo_roomGridForm_AG007.data.removeAll();
                                                                productInfo_roomGridForm_AG007.data.parse(response2.data);
                                                            }else{
                                                                productInfo_roomGridForm_AG007.data.removeAll();
                                                                productInfo_roomGridForm_AG007.data.parse([{
                                                                    "id": "0",
                                                                    "value": "---請選擇---",
                                                                }]);
                                                            }
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                    .catch((error) => {
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });

                                                axios.post(apiServer+"/orders/EditLoadRoomTypeList", {
                                                    itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120
                                                },headers).then((response2) =>{
                                                    if(response2.status == 200){
                                                        if(response2.data){
                                                            productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getWidget();
                                                            productInfo_roomGridForm_AG004.data.removeAll();
                                                            productInfo_roomGridForm_AG004.data.parse(response2.data);
                                                            productInfo_roomGridForm.getItem("AG004").setValue([response.data[0].AG004]);
                                                            productInfo_roomGridForm.getItem("AG004").enable();

                                                            axios.post(apiServer+"/orders/EditLoadRoomList", {
                                                                itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120,itemId5: response.data[0].AG004
                                                            },headers).then((response3) =>{
                                                                if(response3.status == 200){
                                                                    if(response3.data){
                                                                        productInfo_roomGridForm_AG005 = productInfo_roomGridForm.getItem("AG005").getWidget();
                                                                        productInfo_roomGridForm_AG005.data.removeAll();
                                                                        productInfo_roomGridForm_AG005.data.parse(response3.data);
                                                                        productInfo_roomGridForm.getItem("AG005").setValue([response.data[0].AG005]);
                                                                        productInfo_roomGridForm.getItem("AG005").enable();

                                                                        setTimeout(()=>{
                                                                            productInfo_roomGridForm.getItem("AG127").setValue(response.data[0].AG127);
                                                                            productInfo_roomGridForm.getItem("AG116").setValue(response.data[0].AG116);
                                                                            productInfo_roomGridForm.getItem("AG127").enable();
                                                                            productInfo_roomGridForm.getItem("AG116").enable();
                                                                            if(new Date(productInfo_roomGridForm.getItem("AG120").getValue()) == new Date(productInfo_roomGridForm.getItem("AG119").getValue())){
                                                                                dayString = 1;
                                                                            }else{
                                                                                dayString = DateDiff(getDate(productInfo_roomGridForm.getItem("AG119").getValue()),getDate(productInfo_roomGridForm.getItem("AG120").getValue()));
                                                                            }
                                                                            productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚，"+(Number(productInfo_roomGridForm.getItem("AG127").getValue())+Number(productInfo_roomGridForm.getItem("AG116").getValue()))+"元");
                                                                            productInfo_roomGridForm.getItem("AG007").setValue([response.data[0].AG007]);
                                                                            dhxProductInfo_roomGridWindow1.attach(productInfo_roomGridForm);
                                                                            dhxProgressWindow1.hide();
                                                                            dhxProductInfo_roomGridWindow1.show();
                                                                            isNeedFirstChangeEvent = true;
                                                                        },1000)
                                                                    }
                                                                }
                                                            })
                                                                .catch((error) => {
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                });
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                                break;
                                            case 2:
                                                break;
                                            case 3:
                                                productInfo_restRoomGridForm.setValue(response.data[0]);
                                                productInfo_restRoomGridForm.getItem("AG001").setValue(response.data[0].AG001);
                                                productInfo_restRoomGridForm.getItem("AG002").setValue(response.data[0].AG002);
                                                productInfo_restRoomGridForm.getItem("AG003").setValue(response.data[0].AG003);
                                                productInfo_restRoomGridForm.getItem("AG119").setValue(response.data[0].AG119);

                                                if(response.data[0].AG132 === 3 || (response.data[0].AG132 === 2 && (response.data[0].AG133 === 1 || response.data[0].AG133 === 2))){
                                                    if(response.data[0].AG133 === 1){
                                                        productInfo_restRoomGridForm.getItem("AA103_1").setValue("1");
                                                        productInfo_restRoomGridForm.getItem("AA104_1").setValue(`${response.data[0].AG134}`);
                                                        productInfo_restRoomGridForm.getItem("AA104_1").enable();
                                                        productInfo_restRoomGridForm.getItem("AA104_2").disable();
                                                    }else{
                                                        productInfo_restRoomGridForm.getItem("AA103_2").setValue("1");
                                                        productInfo_restRoomGridForm.getItem("AA104_2").setValue(`${response.data[0].AG134}`);
                                                        productInfo_restRoomGridForm.getItem("AA104_2").enable();
                                                        productInfo_restRoomGridForm.getItem("AA104_1").disable();
                                                    }
                                                }

                                                axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                                    itemId1: hotel,itemId2: response.data[0].AG001
                                                },headers).then((response2) =>{
                                                    if(response2.status == 200){
                                                        if(response2.data){
                                                            productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                                            productInfo_restRoomGridForm_AG004.data.removeAll();
                                                            productInfo_restRoomGridForm_AG004.data.parse(response2.data);
                                                            productInfo_restRoomGridForm.getItem("AG004").setValue([response.data[0].AG004]);
                                                            productInfo_restRoomGridForm.getItem("AG004").enable();

                                                            axios.post(apiServer+"/orders/EditLoadRestRoomList", {
                                                                itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: AA103,itemId5:AA104,itemId6:response.data[0].AG004
                                                            },headers).then((response3) =>{
                                                                if(response3){

                                                                    productInfo_restRoomGridForm_AG005 = productInfo_restRoomGridForm.getItem("AG005").getWidget();
                                                                    productInfo_restRoomGridForm_AG005.data.removeAll();
                                                                    productInfo_restRoomGridForm_AG005.data.parse(response3.data);
                                                                    productInfo_restRoomGridForm.getItem("AG005").setValue([response.data[0].AG005]);
                                                                    productInfo_restRoomGridForm.getItem("AG005").enable();

                                                                    // setTimeout(()=>{
                                                                    productInfo_restRoomGridForm.getItem("AG127").setValue(response.data[0].AG127);
                                                                    productInfo_restRoomGridForm.getItem("AG127").enable();

                                                                    productInfo_restRoomGridForm.getItem("total").setValue(response.data[0].price);
                                                                    productInfo_restRoomGridForm.getItem("AG007").setValue([response.data[0].AG007]);

                                                                    dhxProductInfo_roomGridWindow1.attach(productInfo_restRoomGridForm);
                                                                    dhxProgressWindow1.hide();
                                                                    dhxProductInfo_roomGridWindow1.show();
                                                                    isNeedFirstChangeEventRest = true;
                                                                    // },1000)
                                                                }
                                                            })
                                                                .catch((error) => {
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                });
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }

                                                })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                                break;
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            productInfo_roomGridForm.getItem("AG001").setValue(data.row.id)
                            productInfo_roomGridForm.setValue({"AG001":data.row.id,"AG002":orderForm.getItem("AA001").getValue()});

                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "productInfo_roomGrid_delete": function (e, data) {
                        if(canDelete){

                            roomGrid_deleteForm.clear();

                            axios.post(apiServer+"/orders/loadRoomFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{

                                roomGrid_deleteForm.setValue(response.data[0]);
                                roomGrid_deleteForm.getItem("roomGrid_deleteFormTitle").setValue("確定要刪除嗎?");
                                dhxProductInfo_roomGridWindow2.show();

                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        const productInfo_roomGridToolbar = new DHXToolbar(null, {
        });
        productInfo_roomGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        switch (orderForm.getItem("AA138").getValue()){
                            case "1":
                                TMPId = "0";
                                isNeedFirstChangeEvent = false;
                                dhxProductInfo_roomGridWindow1.header.data.update("title", {value: "新增房間視窗"});
                                productInfo_roomGridForm.clear();
                                productInfo_roomGridForm.getItem("AG001").setValue("0");
                                productInfo_roomGridForm.getItem("AG002").setValue(orderForm.getItem("AA001").getValue());
                                productInfo_roomGridForm.getItem("AG003").setValue(hotel);

                                productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getWidget();
                                productInfo_roomGridForm_AG004.data.removeAll();
                                productInfo_roomGridForm_AG004.data.parse([{
                                    "id": "0",
                                    "value": "---請選擇---",
                                }]);
                                productInfo_roomGridForm.getItem("AG004").setValue(["0"]);
                                productInfo_roomGridForm.getItem("AG004").disable();
                                productInfo_roomGridForm.getItem("AG007").setValue(["0"]);

                                productInfo_roomGridForm_AG005 = productInfo_roomGridForm.getItem("AG005").getWidget();
                                productInfo_roomGridForm_AG005.data.removeAll();
                                productInfo_roomGridForm_AG005.data.parse([{
                                    "id": "0",
                                    "value": "---請選擇---",
                                }]);

                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                ).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            let productInfo_roomGridForm_AG007 = productInfo_roomGridForm.getItem("AG007").getWidget();
                                            if(response.data.length > 1){
                                                productInfo_roomGridForm_AG007.data.removeAll();
                                                productInfo_roomGridForm_AG007.data.parse(response.data);
                                                productInfo_roomGridForm.getItem("AG007").setValue(["0"]);
                                            }else{
                                                productInfo_roomGridForm_AG007.data.removeAll();
                                                productInfo_roomGridForm_AG007.data.parse([{
                                                    "id": "0",
                                                    "value": "---請選擇---",
                                                }]);
                                                productInfo_roomGridForm.getItem("AG007").setValue(["0"]);
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });

                                productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                                productInfo_roomGridForm.getItem("AG005").disable();

                                productInfo_roomGridForm.getItem("AG119").setValue(`${getDate(new Date())} 15:00`);
                                productInfo_roomGridForm.getItem("AG120").setValue(`${getDate(dateTime)} 11:00`);
                                productInfo_roomGridForm.getItem("AG119").clear();
                                productInfo_roomGridForm.getItem("AG120").clear();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG131").setValue(orderForm.getItem("AA137").getValue());
                                productInfo_roomGridForm.getItem("AG133").setValue("0");
                                productInfo_roomGridForm.getItem("AG134").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                                productInfo_roomGridForm.getItem("total").setValue("");
                                dhxProductInfo_roomGridWindow1.attach(productInfo_roomGridForm);
                                dhxProductInfo_roomGridWindow1.show();

                                isNeedFirstChangeEvent = true;
                                break;
                            case "3":
                                TMPId = "0";
                                isNeedFirstChangeEventRest = false;
                                restTypeText = '';
                                price = 0;

                                dhxProductInfo_roomGridWindow1.header.data.update("title", {value: "新增非住宿房間視窗"});
                                productInfo_restRoomGridForm.clear();

                                productInfo_restRoomGridForm.setValue({"AG001":"0","AG002":orderForm.getItem("AA001").getValue(),"AG003":hotel,"AG006":TMPId,"AG007":"0","AA103_2":"1","AA104_1":30,"AA104_2":1,"AA104_text1":"分鐘","AA104_text2":"小時","AG127":"0","AG131":orderForm.getItem("AA137").getValue(),"total":""});
                                AA103 = 2;
                                AA104 = 1;

                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse([{
                                    "id": "0",
                                    "value": "---請選擇---",
                                }]);
                                productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                                productInfo_restRoomGridForm.getItem("AG007").setValue(["0"]);

                                productInfo_restRoomGridForm_AG005 = productInfo_restRoomGridForm.getItem("AG005").getWidget();
                                productInfo_restRoomGridForm_AG005.data.removeAll();
                                productInfo_restRoomGridForm_AG005.data.parse([{
                                    "id": "0",
                                    "value": "---請選擇---",
                                }]);
                                productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);

                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                ).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            let productInfo_restRoomGridForm_AG007 = productInfo_restRoomGridForm.getItem("AG007").getWidget();
                                            if(response.data.length > 1){
                                                productInfo_restRoomGridForm_AG007.data.removeAll();
                                                productInfo_restRoomGridForm_AG007.data.parse(response.data);
                                                productInfo_restRoomGridForm.getItem("AG007").setValue(["0"]);
                                            }else{
                                                productInfo_restRoomGridForm_AG007.data.removeAll();
                                                productInfo_restRoomGridForm_AG007.data.parse([{
                                                    "id": "0",
                                                    "value": "---請選擇---",
                                                }]);
                                                productInfo_restRoomGridForm.getItem("AG007").setValue(["0"]);
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });

                                dhxProductInfo_roomGridWindow1.attach(productInfo_restRoomGridForm);
                                dhxProductInfo_roomGridWindow1.show();


                                isNeedFirstChangeEventRest = true;
                                break;
                        }

                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });

        productInfo_roomGridToolbar.data.parse(JSON.parse(JSON.stringify(productInfo_roomGridToolbarConfig)));

        orderBasicSetTabLayout2.getCell("productInfo_roomGridToolbar").attach(productInfo_roomGridToolbar);

        //編輯訂單視窗_產品資訊_房間_新增/編輯房間視窗
        const dhxProductInfo_roomGridWindow1 = new DHXWindow({
            title: "新增房間視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 460,
            footer: true,
            css:"dhxProductInfo_roomGridWindow1"
        });

        dhxProductInfo_roomGridWindow1.footer.data.add({type:"spacer"});
        dhxProductInfo_roomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_roomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_roomGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    switch (orderForm.getItem("AA138").getValue()) {
                        case "1":
                            productInfo_roomGridForm.getItem("AG132").setValue("1");
                            if(productInfo_roomGridForm.getItem("AG119").getValue() === ""){
                                DHXMessage({
                                    text: "請選擇入住日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_roomGridForm.getItem("AG120").getValue() === ""){
                                DHXMessage({
                                    text: "請選擇退房日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(Date.parse(productInfo_roomGridForm.getItem("AG119").getValue()).valueOf() > Date.parse(productInfo_roomGridForm.getItem("AG120").getValue()).valueOf()){
                                DHXMessage({
                                    text: "入住日期必須早於退房日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_roomGridForm.getItem("AG004").getValue() === "0"){
                                DHXMessage({
                                    text: "請選擇房型",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_roomGridForm.getItem("AG005").getValue() === "0"){
                                DHXMessage({
                                    text: "請選擇房間名稱",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_roomGridForm.getItem("AG127").getValue() === ""){
                                DHXMessage({
                                    text: "請填入金額",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--",
                                    expire:5000
                                });
                                break;
                            }

                            dhxProgressWindow1.show();

                            axios.post(apiServer+"/orders/updateRoomFormData",
                                productInfo_roomGridForm.getValue(),headers
                            ).then((response) =>{
                                if(response.status === 200){
                                    if(response.data[0].result == 1){
                                        console.log("result1")
                                        DHXMessage({
                                            text: response.data[0].message,
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--success",
                                            expire:5000
                                        });
                                        axios.post(apiServer+"/orders/loadRoomGrid", {
                                            itemId1: productInfo_roomGridForm.getItem("AG002").getValue()
                                        },headers).then((data1) =>{
                                            if(data1.status === 200){
                                                if(data1.data){
                                                    productInfo_roomGrid.data.parse(data1.data);

                                                    toolbar1StartTimeValue = toolbar1.getState("toolbar1_startTime");
                                                    toolbar1EndTimeValue = toolbar1.getState("toolbar1_endTime");

                                                    toolbar1KeywordValue = "";
                                                    if(toolbar1.getState("toolbar1_keyword") !== undefined && toolbar1.getState("toolbar1_keyword").trim() !== ""){
                                                        toolbar1KeywordValue = toolbar1.getState("toolbar1_keyword").trim();
                                                    };

                                                    dhxOrderGrid.data.removeAll();
                                                    axios.post(apiServer+"/orders/searchOrderGrid", {
                                                        itemId1: toolbar1.getState("toolbar1_startTime"), itemId2: toolbar1.getState("toolbar1_endTime"), itemId3:hotel, itemId4:toolbar1KeywordValue
                                                    },headers).then((data2) =>{
                                                        dhxOrderGrid.data.parse(data2.data);
                                                    });


                                                    orderExportExcelGrid.data.removeAll();
                                                    axios.post(apiServer+"/orders/searchOrderExportExcelGrid", {
                                                        itemId1: toolbar1.getState("toolbar1_startTime"), itemId2: toolbar1.getState("toolbar1_endTime"), itemId3:hotel, itemId4:toolbar1KeywordValue
                                                    },headers).then((data) =>{
                                                        orderExportExcelGrid.data.parse(data.data);
                                                    });

                                                    axios.post(apiServer+"/orders/loadOrderFormData", {
                                                        itemId1: orderForm.getItem("AA001").getValue()
                                                    },headers).then((response2) =>{
                                                        if(response2.status == 200){
                                                            if(response2.data){
                                                                orderForm.setValue(response2.data[0]);
                                                                productInfo_roomGridForm.clear();
                                                                dhxProductInfo_roomGridWindow1.hide();
                                                                dhxProgressWindow1.hide();

                                                                if(response2.data[0].unpaid > 0){
                                                                    productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                                                }else{
                                                                    productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                                                }
                                                                // if(response2.data[0].AA137 == "1"){
                                                                //     orderForm.getItem("AA101_1").hide();
                                                                //     orderForm.getItem("AA101_2").show();
                                                                //     orderForm.getItem("AA101_2").setOptions(response2.data[0].orderSourceItem);
                                                                //     orderForm.getItem("AA101_2").setValue(response2.data[0].AA006)
                                                                // }else if(response2.data[0].AA137 == "2"){
                                                                //     orderForm.getItem("AA101_1").show(response2.data[0].AA101);
                                                                //     orderForm.getItem("AA101_2").setValue()
                                                                //     orderForm.getItem("AA101_2").hide();
                                                                // }
                                                            }else{
                                                                dhxProgressWindow1.hide();
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            }
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }

                                                    })
                                                        .catch((error) => {
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        });
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }

                                        });
                                    }else if(response.data[0].result == 9){
                                        console.log("result9")
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: response.data[0].message,
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }

                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            break;
                        case "3":
                            productInfo_restRoomGridForm.getItem("AG132").setValue("3");
                            if(productInfo_restRoomGridForm.getItem("AG119").getValue() === ""){
                                DHXMessage({
                                    text: "請選擇入住日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() === "" && productInfo_restRoomGridForm.getItem("AA103_2").getValue() === "" ){
                                DHXMessage({
                                    text: "請設定分鐘或小時",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            let productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getValue();

                            if(productInfo_restRoomGridForm_AG004 === "0" || productInfo_restRoomGridForm_AG004 === ""){
                                DHXMessage({
                                    text: "請選擇房型",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_restRoomGridForm.getItem("AG005").getValue() === "0" || productInfo_restRoomGridForm.getItem("AG005").getValue() === ""){
                                DHXMessage({
                                    text: "請選擇房間名稱",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                                break;
                            }

                            if(productInfo_restRoomGridForm.getItem("AG127").getValue() === ""){
                                DHXMessage({
                                    text: "請填入金額",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--",
                                    expire:5000
                                });
                                break;
                            }

                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/updateRoomFormData",
                                productInfo_restRoomGridForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result == 1){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });
                                    axios.post(apiServer+"/orders/loadRoomGrid", {
                                        itemId1: productInfo_restRoomGridForm.getItem("AG002").getValue()
                                    },headers).then((data) =>{
                                        productInfo_roomGrid.data.parse(data.data);

                                        toolbar1StartTimeValue = toolbar1.getState("toolbar1_startTime");
                                        toolbar1EndTimeValue = toolbar1.getState("toolbar1_endTime");

                                        toolbar1KeywordValue = "";
                                        if(toolbar1.getState("toolbar1_keyword") !== undefined && toolbar1.getState("toolbar1_keyword").trim() !== ""){
                                            toolbar1KeywordValue = toolbar1.getState("toolbar1_keyword").trim();
                                        };

                                        dhxOrderGrid.data.removeAll();
                                        axios.post(apiServer+"/orders/searchOrderGrid", {
                                            itemId1: toolbar1.getState("toolbar1_startTime"), itemId2: toolbar1.getState("toolbar1_endTime"), itemId3:hotel, itemId4:toolbar1KeywordValue
                                        },headers).then((data) =>{
                                            dhxOrderGrid.data.parse(data.data);
                                        });

                                        orderExportExcelGrid.data.removeAll();
                                        axios.post(apiServer+"/orders/searchOrderExportExcelGrid", {
                                            itemId1: toolbar1.getState("toolbar1_startTime"), itemId2: toolbar1.getState("toolbar1_endTime"), itemId3:hotel, itemId4:toolbar1KeywordValue
                                        },headers).then((data) =>{;
                                            orderExportExcelGrid.data.parse(data.data);
                                        });

                                        axios.post(apiServer+"/orders/loadOrderFormData", {
                                            itemId1: orderForm.getItem("AA001").getValue()
                                        },headers).then((response) =>{
                                            if(response.status === 200){
                                                if(response.data){
                                                    orderForm.setValue(response.data[0]);
                                                    if(response.data[0].unpaid > 0){
                                                        productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                                    }else{
                                                        productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                                    }
                                                    // if(response.data[0].AA137 == "1"){
                                                    //     orderForm.getItem("AA101_1").hide();
                                                    //     orderForm.getItem("AA101_2").show();
                                                    //     orderForm.getItem("AA101_2").setOptions(response.data[0].orderSourceItem);
                                                    //     orderForm.getItem("AA101_2").setValue(response.data[0].AA006)
                                                    // }else if(response.data[0].AA137 == "2"){
                                                    //     orderForm.getItem("AA101_1").show(response.data[0].AA101);
                                                    //     orderForm.getItem("AA101_2").setValue()
                                                    //     orderForm.getItem("AA101_2").hide();
                                                    // }
                                                }else{
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }

                                        })
                                            .catch((error) => {
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });
                                    });

                                    productInfo_roomGridForm.clear();
                                    dhxProductInfo_roomGridWindow1.hide();
                                }else if(response.data[0].result == 9){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            break;
                    }

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_roomGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const productInfo_restRoomGridForm = new DHXForm(null,{
            rows: productInfo_restRoomGridFormConfig
        });

        productInfo_restRoomGridForm.events.on("change", function(name, value) {
            if(TMPId !== "0"){
                //新增
                switch (name){
                    case "AG119":
                        dhxProgressWindow1.show();
                        productInfo_restRoomGridForm.getItem("AG004").enable();
                        productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");

                        axios.post(apiServer+"/orders/loadRestRoomTypeList", {
                            itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104,itemId6:TMPId
                        },headers).then((response) =>{
                            console.log('1')
                            productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                            productInfo_restRoomGridForm_AG004.data.removeAll();
                            productInfo_restRoomGridForm_AG004.data.parse(response.data);
                            productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            // productInfo_restRoomGridForm.getItem("AG004").setOptions(response.data);
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                        break;
                    case "AA103_1":
                        AA103 = 1;
                        AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                        if(value == ""){
                            restTypeText = '';
                            productInfo_restRoomGridForm.getItem("AA104_1").disable();
                        }else{
                            restTypeText = '分鐘';
                            productInfo_restRoomGridForm.getItem("AA104_1").enable();
                            productInfo_restRoomGridForm.getItem("AA104_2").disable();
                            productInfo_restRoomGridForm.getItem("AA103_2").clear();
                        }

                        if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                            productInfo_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                        }
                        productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA103_2":
                        AA103 = 2;
                        AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                        if(value == ""){
                            restTypeText = '';
                            productInfo_restRoomGridForm.getItem("AA104_2").disable();
                        }else{
                            restTypeText = '小時';
                            productInfo_restRoomGridForm.getItem("AA104_1").disable();
                            productInfo_restRoomGridForm.getItem("AA104_2").enable();
                            productInfo_restRoomGridForm.getItem("AA103_1").clear();
                        }

                        if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                            productInfo_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                        }
                        productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA104_1":
                        AA104 = value;
                        if(value == ""){
                        }else{

                        }

                        if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                            productInfo_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                        }
                        productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA104_2":
                        AA104 = value;
                        if(value == ""){
                            productInfo_restRoomGridForm.getItem("AA104_2").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AA104_1").disable();
                            productInfo_restRoomGridForm.getItem("AA104_2").enable();
                            productInfo_restRoomGridForm.getItem("AA103_1").clear();
                        }

                        if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                            productInfo_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                        }
                        productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG005").disable();
                        productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        productInfo_restRoomGridForm.getItem("AG127").disable();
                        productInfo_restRoomGridForm.getItem("AG127").setValue("");
                        productInfo_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AG004":
                        if(value == "0" || value == ""){
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");
                        }else{
                            productInfo_restRoomGridForm.getItem("AG005").enable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            axios.post(apiServer+"/orders/loadRestRoomList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: productInfo_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:value,itemId7:TMPId
                            },headers).then((response) =>{
                                newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                                newOrder_restRoomGridForm_AG005.data.removeAll();
                                newOrder_restRoomGridForm_AG005.data.parse(response.data);
                                newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                        break;
                    case "AG005":
                        if(value == "0" || value == ""){

                        }else{

                            let productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getValue();

                            axios.post(apiServer+"/orders/loadRestRoomPrices", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: productInfo_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:productInfo_restRoomGridForm_AG004,itemId7:value
                            },headers).then((response) =>{
                                if(response.status == 200){
                                    if(response.data){
                                        productInfo_restRoomGridForm.getItem("AG127").setValue(response.data);

                                        productInfo_restRoomGridForm.getItem("AG127").enable();

                                        productInfo_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${response.data} 元`);
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                        break;
                    case "AG127":
                        if(productInfo_restRoomGridForm.getItem("AG005").getValue() == "0"){
                            price = Number(newOrder_roomGridForm.getItem("AG127").getValue());

                            productInfo_restRoomGridForm.getItem("AG127").setValue("0");
                            // productInfo_restRoomGridForm.getItem("AG116").setValue("0");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            productInfo_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${value} 元`);
                        }else{


                        }
                        break;
                }
            }else{
                //修改
                switch (name){
                    case "AG119":
                        if(isNeedFirstChangeEventRest){
                            productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG004").enable();
                            productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }
                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                console.log("123")
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                        }
                        break;
                    case "AA103_1":
                        if(isNeedFirstChangeEventRest){
                            if(value == ""){
                                productInfo_restRoomGridForm.getItem("AA104_1").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AA104_1").enable();
                                productInfo_restRoomGridForm.getItem("AA104_2").disable();
                                productInfo_restRoomGridForm.getItem("AA103_2").clear();
                            }

                            if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                                productInfo_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            }
                            productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }

                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{

                        }

                        break;
                    case "AA103_2":
                        if(isNeedFirstChangeEventRest){
                            if(value == ""){
                                productInfo_restRoomGridForm.getItem("AA104_2").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AA104_1").disable();
                                productInfo_restRoomGridForm.getItem("AA104_2").enable();
                                productInfo_restRoomGridForm.getItem("AA103_1").clear();
                            }

                            if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                                productInfo_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            }
                            productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }
                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{

                        }

                        break;
                    case "AA104_1":
                        if(isNeedFirstChangeEventRest){
                            if(value == ""){
                            }else{

                            }

                            if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                                productInfo_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            }
                            productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }
                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{

                        }

                        break;
                    case "AA104_2":
                        if(isNeedFirstChangeEventRest){
                            if(value == ""){
                                productInfo_restRoomGridForm.getItem("AA104_2").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AA104_1").disable();
                                productInfo_restRoomGridForm.getItem("AA104_2").enable();
                                productInfo_restRoomGridForm.getItem("AA103_1").clear();
                            }

                            if (productInfo_restRoomGridForm.getItem("AG119").getValue() == ""){
                                productInfo_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            }
                            productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG005").disable();
                            productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_restRoomGridForm.getItem("AG127").disable();
                            productInfo_restRoomGridForm.getItem("AG127").setValue("");
                            productInfo_restRoomGridForm.getItem("total").setValue("");

                            if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }
                            axios.post(apiServer+"/orders/EditLoadRestRoomTypeList", {
                                itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: value,itemId4:AA103,itemId5:AA104
                            },headers).then((response) =>{
                                productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getWidget();
                                productInfo_restRoomGridForm_AG004.data.removeAll();
                                productInfo_restRoomGridForm_AG004.data.parse(response.data);
                                productInfo_restRoomGridForm.getItem("AG004").setValue(["0"]);
                                productInfo_restRoomGridForm.getItem("AG004").enable();
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{

                        }

                        break;
                    case "AG004":
                        if(isNeedFirstChangeEventRest){
                            if(value == "0" || value == ""){
                                productInfo_restRoomGridForm.getItem("AG005").disable();
                                productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                                productInfo_restRoomGridForm.getItem("AG127").disable();
                                productInfo_restRoomGridForm.getItem("AG127").setValue("");
                                productInfo_restRoomGridForm.getItem("total").setValue("");
                            }else{
                                productInfo_restRoomGridForm.getItem("AG005").enable();
                                productInfo_restRoomGridForm.getItem("AG005").setValue(["0"]);
                                productInfo_restRoomGridForm.getItem("AG127").disable();
                                productInfo_restRoomGridForm.getItem("AG127").setValue("");
                                productInfo_restRoomGridForm.getItem("total").setValue("");

                                if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                    AA103 = 1;
                                    AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                    restTypeText = '分鐘';
                                }else{
                                    AA103 = 2;
                                    AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                    restTypeText = '小時';
                                }

                                axios.post(apiServer+"/orders/EditLoadRestRoomList", {
                                    itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: productInfo_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:productInfo_restRoomGridForm.getItem("AG004").getValue()
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            productInfo_restRoomGridForm.getItem("AG005").getWidget().data.parse(response.data);
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }

                                })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{

                        }

                        break;
                    case "AG005":
                        if(isNeedFirstChangeEventRest){
                            if(value == "0" || value == ""){

                            }else{

                                if(productInfo_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                    AA103 = 1;
                                    AA104 = productInfo_restRoomGridForm.getItem("AA104_1").getValue();
                                    restTypeText = '分鐘';
                                }else{
                                    AA103 = 2;
                                    AA104 = productInfo_restRoomGridForm.getItem("AA104_2").getValue();
                                    restTypeText = '小時';
                                }

                                let productInfo_restRoomGridForm_AG004 = productInfo_restRoomGridForm.getItem("AG004").getValue();

                                axios.post(apiServer+"/orders/loadRestRoomPrices", {
                                    itemId1: hotel,itemId2: productInfo_restRoomGridForm.getItem("AG001").getValue(),itemId3: productInfo_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:productInfo_restRoomGridForm_AG004,itemId7:value
                                },headers).then((response) =>{
                                    if(response.status == 200){
                                        if(response.data){
                                            productInfo_restRoomGridForm.getItem("AG127").setValue(response.data);

                                            productInfo_restRoomGridForm.getItem("AG127").enable();

                                            productInfo_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${response.data} 元`);
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{

                        }

                        break;
                    case "AG127":
                        if(isNeedFirstChangeEventRest){
                            if(productInfo_restRoomGridForm.getItem("AG005").getValue() == "0"){
                                price = Number(newOrder_roomGridForm.getItem("AG127").getValue());

                                productInfo_restRoomGridForm.getItem("AG127").setValue("0");
                                // productInfo_restRoomGridForm.getItem("AG116").setValue("0");
                                productInfo_restRoomGridForm.getItem("total").setValue("");

                                productInfo_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${value} 元`);
                            }else{


                            }
                        }else{

                        }

                        break;
                }
            }

        });

        const productInfo_roomGridForm = new DHXForm(null,{
            rows: productInfo_roomGridFormConfig
        });

        let dayString = '',totalString = '';
        productInfo_roomGridForm.events.on("change", function(name, value) {
            if(TMPId !== "0"){
                //新增
                switch (name){
                    case "AG119":
                    case "AG120":
                        if(productInfo_roomGridForm.getItem("AG119").getValue() != '' && productInfo_roomGridForm.getItem("AG120").getValue() != ''){
                            if(getDate(productInfo_roomGridForm.getItem("AG119").getValue()) == getDate(productInfo_roomGridForm.getItem("AG120").getValue())){
                                DHXMessage({
                                    text: "入住與退房日期必須不同天",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error dhx_message",
                                    expire:5000
                                });
                            }else{
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/loadRoomTypeList", {
                                    itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5:TMPId
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getWidget();
                                            productInfo_roomGridForm_AG004.data.removeAll();
                                            productInfo_roomGridForm_AG004.data.parse(response.data);
                                            productInfo_roomGridForm.getItem("AG004").setValue(["0"]);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--danger",
                                                expire:5000
                                            });
                                            dhxProgressWindow1.hide();
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                        dhxProgressWindow1.hide();
                                    }
                                })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                        dhxProgressWindow1.hide();
                                    });

                                if(new Date(productInfo_roomGridForm.getItem("AG120").getValue()) == new Date(productInfo_roomGridForm.getItem("AG119").getValue())){
                                    dayString = 1;
                                }else{
                                    dayString = DateDiff(getDate(productInfo_roomGridForm.getItem("AG119").getValue()),getDate(productInfo_roomGridForm.getItem("AG120").getValue()));
                                }

                                productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚");

                                productInfo_roomGridForm.getItem("AG004").enable();
                                productInfo_roomGridForm.getItem("AG004").setValue(["0"]);
                                productInfo_roomGridForm.getItem("AG005").disable();
                                productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                                productInfo_roomGridForm.getItem("AG127").disable();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").disable();
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{
                            productInfo_roomGridForm.getItem("total").setValue("");
                            productInfo_roomGridForm.getItem("AG004").disable();
                            productInfo_roomGridForm.getItem("AG004").setValue(["0"]);
                            productInfo_roomGridForm.getItem("AG005").disable();
                            productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_roomGridForm.getItem("AG127").disable();
                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                            productInfo_roomGridForm.getItem("AG116").disable();
                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;

                    case "AG004":

                        if(productInfo_roomGridForm.getItem("AG004").getValue() != '0' && productInfo_roomGridForm.getItem("AG004").getValue() != ''){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/loadRoomList", {
                                itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5: productInfo_roomGridForm.getItem("AG004").getValue(),itemId6: TMPId
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        productInfo_roomGridForm_AG005 = productInfo_roomGridForm.getItem("AG005").getWidget();
                                        productInfo_roomGridForm_AG005.data.removeAll();
                                        productInfo_roomGridForm_AG005.data.parse(response.data);
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                            productInfo_roomGridForm.getItem("AG005").enable();
                            productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_roomGridForm.getItem("AG127").disable();
                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                            productInfo_roomGridForm.getItem("AG116").disable();
                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                        }else{
                            productInfo_roomGridForm.getItem("AG005").disable();
                            productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                            productInfo_roomGridForm.getItem("AG127").disable();
                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                            productInfo_roomGridForm.getItem("AG116").disable();
                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;
                    case "AG005":
                        if(productInfo_roomGridForm.getItem("AG005").getValue() != '0' && productInfo_roomGridForm.getItem("AG005").getValue() != ''){
                            dhxProgressWindow1.show();

                            let productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getValue();

                            axios.post(apiServer+"/orders/loadRoomPrices", {
                                itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5: productInfo_roomGridForm_AG004,itemId6: productInfo_roomGridForm.getItem("AG005").getValue()
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        productInfo_roomGridForm.getItem("AG127").setValue(response.data);
                                        productInfo_roomGridForm.getItem("AG127").enable();
                                        productInfo_roomGridForm.getItem("AG116").enable();
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--danger",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--danger",
                                        expire:5000
                                    });
                                });
                        }else{
                            productInfo_roomGridForm.getItem("AG127").disable();
                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                            productInfo_roomGridForm.getItem("AG116").disable();
                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;
                    case "AG127":
                    case "AG116":
                        productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚，"+(Number(productInfo_roomGridForm.getItem("AG127").getValue())+Number(productInfo_roomGridForm.getItem("AG116").getValue()))+"元");
                        break;
                }
            }else{
                //修改
                switch (name){
                    case "AG119":
                    case "AG120":
                        if(isNeedFirstChangeEvent){
                            if(productInfo_roomGridForm.getItem("AG119").getValue() != '' && productInfo_roomGridForm.getItem("AG120").getValue() != ''){
                                if(getDate(productInfo_roomGridForm.getItem("AG119").getValue()) == getDate(productInfo_roomGridForm.getItem("AG120").getValue())){
                                    DHXMessage({
                                        text: "入住與退房日期必須不同天",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    dhxProgressWindow1.show();
                                    axios.post(apiServer+"/orders/EditLoadRoomTypeList", {
                                        itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue()
                                    },headers).then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getWidget();
                                                productInfo_roomGridForm_AG004.data.removeAll();
                                                productInfo_roomGridForm_AG004.data.parse(response2.data);
                                                productInfo_roomGridForm.getItem("AG004").setValue(["0"]);
                                                dhxProgressWindow1.hide();
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });

                                    if(new Date(productInfo_roomGridForm.getItem("AG120").getValue()) == new Date(productInfo_roomGridForm.getItem("AG119").getValue())){
                                        dayString = 1;
                                    }else{
                                        dayString = DateDiff(getDate(productInfo_roomGridForm.getItem("AG119").getValue()),getDate(productInfo_roomGridForm.getItem("AG120").getValue()));
                                    }

                                    productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚");

                                    productInfo_roomGridForm.getItem("AG004").enable();
                                    productInfo_roomGridForm.getItem("AG004").setValue(["0"]);
                                    productInfo_roomGridForm.getItem("AG005").disable();
                                    productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                                    productInfo_roomGridForm.getItem("AG127").disable();
                                    productInfo_roomGridForm.getItem("AG127").setValue("0");
                                    productInfo_roomGridForm.getItem("AG116").disable();
                                    productInfo_roomGridForm.getItem("AG116").setValue("0");
                                }
                            }else{
                                productInfo_roomGridForm.getItem("total").setValue("");
                                productInfo_roomGridForm.getItem("AG004").disable();
                                productInfo_roomGridForm.getItem("AG004").setValue(["0"]);
                                productInfo_roomGridForm.getItem("AG005").disable();
                                productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                                productInfo_roomGridForm.getItem("AG127").disable();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").disable();
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{
                            if(productInfo_roomGridForm.getItem("AG119").getValue() != '' && productInfo_roomGridForm.getItem("AG120").getValue() != ''){
                                if(getDate(productInfo_roomGridForm.getItem("AG119").getValue()) == getDate(productInfo_roomGridForm.getItem("AG120").getValue())){
                                    DHXMessage({
                                        text: "入住與退房日期必須不同天",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }
                        }
                        break;
                    case "AG004":
                        if(isNeedFirstChangeEvent){
                            if(productInfo_roomGridForm.getItem("AG004").getValue() != '0' && productInfo_roomGridForm.getItem("AG004").getValue() != ''){
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/EditLoadRoomList", {
                                    itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5: productInfo_roomGridForm.getItem("AG004").getValue()
                                },headers).then((response3) =>{
                                    if(response3.status === 200){
                                        if(response3.data){
                                            productInfo_roomGridForm_AG005 = productInfo_roomGridForm.getItem("AG005").getWidget();
                                            productInfo_roomGridForm_AG005.data.removeAll();
                                            productInfo_roomGridForm_AG005.data.parse(response3.data);
                                            productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                                            productInfo_roomGridForm.getItem("AG005").enable();
                                            productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                                            productInfo_roomGridForm.getItem("AG127").disable();
                                            productInfo_roomGridForm.getItem("AG127").setValue("0");
                                            productInfo_roomGridForm.getItem("AG116").disable();
                                            productInfo_roomGridForm.getItem("AG116").setValue("0");
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else{
                                productInfo_roomGridForm.getItem("AG005").disable();
                                productInfo_roomGridForm.getItem("AG005").setValue(["0"]);
                                productInfo_roomGridForm.getItem("AG127").disable();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").disable();
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{

                        }
                        break;
                    case "AG005":
                        if(isNeedFirstChangeEvent){

                            if(productInfo_roomGridForm.getItem("AG005").getValue() != '0' && productInfo_roomGridForm.getItem("AG005").getValue() != ''){
                                dhxProgressWindow1.show();
                                let productInfo_roomGridForm_AG004 = productInfo_roomGridForm.getItem("AG004").getValue();
                                axios.post(apiServer+"/orders/loadRoomPrices", {
                                    itemId1: hotel,itemId2: productInfo_roomGridForm.getItem("AG001").getValue(),itemId3: productInfo_roomGridForm.getItem("AG119").getValue(),itemId4: productInfo_roomGridForm.getItem("AG120").getValue(),itemId5: productInfo_roomGridForm_AG004,itemId6: productInfo_roomGridForm.getItem("AG005").getValue()
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            productInfo_roomGridForm.getItem("AG127").setValue(response.data);
                                            productInfo_roomGridForm.getItem("AG127").enable();
                                            productInfo_roomGridForm.getItem("AG116").enable();
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else{
                                productInfo_roomGridForm.getItem("AG127").disable();
                                productInfo_roomGridForm.getItem("AG127").setValue("0");
                                productInfo_roomGridForm.getItem("AG116").disable();
                                productInfo_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{

                        }
                        break;
                    case "AG116":
                    case "AG127":
                        if(isNeedFirstChangeEvent){
                            productInfo_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚，"+(Number(productInfo_roomGridForm.getItem("AG127").getValue())+Number(productInfo_roomGridForm.getItem("AG116").getValue()))+"元");
                        }else{

                        }
                        break;
                }
            }
        });

        let productInfo_roomGridFormCheckInDate = productInfo_roomGridForm.getItem("AG119").getWidget();
        let productInfo_roomGridFormCheckOutDate = productInfo_roomGridForm.getItem("AG120").getWidget();

        productInfo_roomGridFormCheckInDate.link(productInfo_roomGridFormCheckOutDate)

        dhxProductInfo_roomGridWindow1.attach(productInfo_roomGridForm);

        //編輯訂單視窗_產品資訊_房間_刪除房間視窗
        const dhxProductInfo_roomGridWindow2 = new DHXWindow({
            title: "刪除房間視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 690,
            footer: true,
            css:"dhxProductInfo_roomGridWindow2"
        });

        dhxProductInfo_roomGridWindow2.footer.data.add({type:"spacer"});
        dhxProductInfo_roomGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_roomGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_roomGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(roomGrid_deleteForm.getItem("AG129").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/deleteRoom",
                        roomGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            productInfo_roomGrid.data.removeAll();
                            axios.post(apiServer+"/orders/loadRoomGrid", {
                                itemId1: roomGrid_deleteForm.getItem("AG002").getValue()
                            },headers).then((data) =>{
                                productInfo_roomGrid.data.parse(data.data);
                            });

                            productInfo_otherPrdGrid.data.removeAll();
                            axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                                itemId1: roomGrid_deleteForm.getItem("AG002").getValue()
                            },headers).then((data) =>{
                                productInfo_otherPrdGrid.data.parse(data.data);
                            });

                            toolbar1StartTimeValue = toolbar1.getState("toolbar1_startTime");
                            toolbar1EndTimeValue = toolbar1.getState("toolbar1_endTime");

                            toolbar1KeywordValue = "";
                            if(toolbar1.getState("toolbar1_keyword") !== undefined && toolbar1.getState("toolbar1_keyword").trim() !== ""){
                                toolbar1KeywordValue = toolbar1.getState("toolbar1_keyword").trim();
                            };

                            dhxOrderGrid.data.removeAll();
                            axios.post(apiServer+"/orders/searchOrderGrid", {
                                itemId1: toolbar1StartTimeValue, itemId2: toolbar1EndTimeValue, itemId3:hotel, itemId4:toolbar1KeywordValue
                            },headers).then((data) =>{
                                dhxOrderGrid.data.parse(data.data);
                            });

                            orderExportExcelGrid.data.removeAll();
                            axios.post(apiServer+"/orders/searchOrderExportExcelGrid", {
                                itemId1: toolbar1StartTimeValue, itemId2: toolbar1EndTimeValue, itemId3:hotel, itemId4:toolbar1KeywordValue
                            },headers).then((data) =>{;
                                orderExportExcelGrid.data.parse(data.data);
                            });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: roomGrid_deleteForm.getItem("AG002").getValue()
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        orderForm.setValue(response.data[0]);
                                        if(response.data[0].unpaid > 0){
                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                        }else{
                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                        }
                                        // if(response.data[0].AA137 == "1"){
                                        //     orderForm.getItem("AA101_1").hide();
                                        //     orderForm.getItem("AA101_2").show();
                                        //     orderForm.getItem("AA101_2").setOptions(response.data[0].orderSourceItem);
                                        //     orderForm.getItem("AA101_2").setValue(response.data[0].AA006)
                                        // }else if(response.data[0].AA137 == "2"){
                                        //     orderForm.getItem("AA101_1").show(response.data[0].AA101);
                                        //     orderForm.getItem("AA101_2").setValue()
                                        //     orderForm.getItem("AA101_2").hide();
                                        // }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }

                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            dhxProductInfo_roomGridWindow2.hide();
                            roomGrid_deleteForm.clear();
                            roomGrid_deleteForm.setValue({"AG001": "0"});
                            roomGrid_deleteForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    dhxProductInfo_roomGridWindow2.hide();
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_roomGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let roomGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"roomGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "roomGrid_deleteForm"},
            ]
        });

        const roomGrid_deleteForm = new DHXForm(null,{
            rows: productInfo_roomGridDeleteFormConfig
        });

        dhxProductInfo_roomGridWindow2.attach(roomGrid_deleteFormLayout);
        roomGrid_deleteFormLayout.getCell("roomGrid_deleteForm").attach(roomGrid_deleteForm);

        //編輯訂單視窗_產品資訊_加價產品消費
        let productInfo_otherPrdGrid = new DHXGrid(null,{
            columns: productInfo_otherPrdGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "productInfo_otherPrdGrid_edit": function (e, data) {
                        if(canEdit){
                            dhxProgressWindow1.show();
                            dhxProductInfo_otherPrdGridWindow1.header.data.update("title", {value: "編輯加價產品消費視窗"});
                            axios.post(apiServer+"/orders/loadProductInfo_otherPrdGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response1) =>{
                                if(response1.status === 200) {
                                    if(response1.data){
                                        productInfo_otherPrdGridForm.getItem("AC001").setValue(response1.data[0].AC001);
                                        productInfo_otherPrdGridForm.getItem("AC002").setValue(response1.data[0].AC002);
                                        productInfo_otherPrdGridForm.getItem("AC003").setValue(response1.data[0].AC003);

                                        axios.post(apiServer+"/orders/otherPrdGrid_otherProductName", {
                                            itemId1: productInfo_otherPrdGridForm.getItem("AC003").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC001").getValue()
                                        },headers).then((response2) =>{

                                            if(response2.status === 200) {
                                                if(response2.data){
                                                    productInfo_otherPrdGridForm.getItem("AC004").setOptions(response2.data);
                                                    productInfo_otherPrdGridForm.getItem("AC004").setValue(response2.data[0].AC004);
                                                    productInfo_otherPrdGridForm.getItem("AC004").enable();
                                                    productInfo_otherPrdGridForm.getItem("AC103").enable();

                                                    axios.post(apiServer+"/orders/otherPrdGrid_loadRoomNameList", {
                                                        itemId1: productInfo_otherPrdGridForm.getItem("AC003").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC001").getValue(),itemId3: productInfo_otherPrdGridForm.getItem("AC002").getValue()
                                                    },headers).then((response3) =>{
                                                        if(response3.status === 200){
                                                            if(response3.data){
                                                                productInfo_otherPrdGridForm.getItem("AC005").setOptions(response3.data);
                                                                productInfo_otherPrdGridForm.getItem("AC005").setValue(response3.data[0].AC005);
                                                                productInfo_otherPrdGridForm.getItem("AC005").enable();

                                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                                ).then((response4) =>{
                                                                    if(response4.status === 200){
                                                                        if(response4.data){
                                                                            let productInfo_otherPrdGridForm_AC007 = productInfo_otherPrdGridForm.getItem("AC007").getWidget();
                                                                            if(response4.data.length > 1){
                                                                                productInfo_otherPrdGridForm_AC007.data.removeAll();
                                                                                productInfo_otherPrdGridForm_AC007.data.parse(response4.data);
                                                                                productInfo_otherPrdGridForm.getItem("AC007").setValue(response1.data[0].AC007);
                                                                                productInfo_otherPrdGridForm.getItem("AC103").setValue(response1.data[0].AC103);
                                                                                productInfo_otherPrdGridForm.getItem("total").setValue(response1.data[0].total);

                                                                                dhxProductInfo_otherPrdGridWindow1.show();
                                                                                dhxProgressWindow1.hide();
                                                                            }else{
                                                                                productInfo_otherPrdGridForm_AC007.data.removeAll();
                                                                                productInfo_otherPrdGridForm_AC007.data.parse([{
                                                                                    "id": "0",
                                                                                    "value": "---請選擇---",
                                                                                }]);
                                                                                productInfo_otherPrdGridForm.getItem("AC007").setValue("0");
                                                                                productInfo_otherPrdGridForm.getItem("AC103").setValue(response1.data[0].AC103);
                                                                                productInfo_otherPrdGridForm.getItem("total").setValue(response1.data[0].total);

                                                                                dhxProductInfo_otherPrdGridWindow1.show();
                                                                                dhxProgressWindow1.hide();
                                                                            }
                                                                        }else{
                                                                            dhxProgressWindow1.hide();
                                                                            DHXMessage({
                                                                                text: "連線異常，請重新整理",
                                                                                icon:"dxi dxi-close",
                                                                                css: "dhx_message--error",
                                                                                expire:5000
                                                                            });
                                                                        }
                                                                    }else{
                                                                        dhxProgressWindow1.hide();
                                                                        DHXMessage({
                                                                            text: "連線異常，請重新整理",
                                                                            icon:"dxi dxi-close",
                                                                            css: "dhx_message--error",
                                                                            expire:5000
                                                                        });
                                                                    }
                                                                })
                                                                    .catch((error) => {
                                                                        dhxProgressWindow1.hide();
                                                                        DHXMessage({
                                                                            text: "連線異常，請重新整理",
                                                                            icon:"dxi dxi-close",
                                                                            css: "dhx_message--error",
                                                                            expire:5000
                                                                        });
                                                                    });
                                                            }else{
                                                                dhxProgressWindow1.hide();
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            }
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    })
                                                        .catch((error) => {
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        });
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                            .catch((error) => {
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "productInfo_otherPrdGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/orders/loadProductInfo_otherPrdGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) => {
                                if (response.status === 200) {
                                    otherPrdGrid_deleteForm.setValue(response.data[0])
                                }
                            });
                            otherPrdGrid_deleteForm.getItem("AC105").setValue("");
                            dhxProductInfo_otherPrdGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    }
                }
            }
        });

        const productInfo_otherPrdGridToolbar = new DHXToolbar(null, {
        });

        productInfo_otherPrdGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        dhxProductInfo_otherPrdGridWindow1.header.data.update("title", {value: "新增加價產品消費視窗"});
                        productInfo_otherPrdGridForm.getItem("AC001").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC002").setValue(orderForm.getItem("AA001").getValue());
                        productInfo_otherPrdGridForm.getItem("AC003").setValue(orderForm.getItem("AA002").getValue());
                        productInfo_otherPrdGridForm.getItem("AC004").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC004").disable();
                        productInfo_otherPrdGridForm.getItem("AC005").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC005").disable();
                        productInfo_otherPrdGridForm.getItem("AC103").disable();
                        productInfo_otherPrdGridForm.getItem("AC102").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC103").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC007").setValue("0");
                        productInfo_otherPrdGridForm.getItem("AC107").setValue("");
                        productInfo_otherPrdGridForm.getItem("remained").setValue("0");
                        productInfo_otherPrdGridForm.getItem("total").setValue("0");

                        dhxProgressWindow1.show();

                        axios.post(apiServer+"/orders/otherPrdGrid_otherProductName", {
                            itemId1: productInfo_otherPrdGridForm.getItem("AC003").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC001").getValue()
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    productInfo_otherPrdGridForm.getItem("AC004").setOptions(response.data);
                                    productInfo_otherPrdGridForm.getItem("AC004").enable();

                                    axios.post(apiServer+"/orders/otherPrdGrid_loadRoomNameList", {
                                        itemId1: productInfo_otherPrdGridForm.getItem("AC003").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC001").getValue(),itemId3: productInfo_otherPrdGridForm.getItem("AC002").getValue()
                                    },headers).then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                productInfo_otherPrdGridForm.getItem("AC005").setOptions(response2.data);
                                                productInfo_otherPrdGridForm.getItem("AC005").enable();

                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                ).then((response3) =>{
                                                    if(response3.status === 200){
                                                        if(response3.data){
                                                            let productInfo_otherPrdGridForm_AC007 = productInfo_otherPrdGridForm.getItem("AC007").getWidget();
                                                            if(response3.data.length > 1){
                                                                productInfo_otherPrdGridForm_AC007.data.removeAll();
                                                                productInfo_otherPrdGridForm_AC007.data.parse(response3.data);
                                                                productInfo_otherPrdGridForm.getItem("AC007").setValue("0");
                                                                dhxProgressWindow1.hide();

                                                                dhxProductInfo_otherPrdGridWindow1.show();
                                                            }else{
                                                                productInfo_otherPrdGridForm_AC007.data.removeAll();
                                                                productInfo_otherPrdGridForm_AC007.data.parse([{
                                                                    "id": "0",
                                                                    "value": "---請選擇---",
                                                                }]);
                                                                productInfo_otherPrdGridForm.getItem("AC007").setValue("0");
                                                                dhxProgressWindow1.hide();

                                                                dhxProductInfo_otherPrdGridWindow1.show();
                                                            }
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                    .catch((error) => {
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }else{
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    break;
                default:
                    break;
            }
        });

        productInfo_otherPrdGridToolbar.data.parse(JSON.parse(JSON.stringify(productInfo_otherPrdGridToolbarConfig)));

        orderBasicSetTabLayout2.getCell("productInfo_otherPrdGridToolbar").attach(productInfo_otherPrdGridToolbar);

        //編輯訂單視窗_產品資訊_加價產品消費_新增/編輯加價產品消費視窗
        const dhxProductInfo_otherPrdGridWindow1 = new DHXWindow({
            title: "新增加價產品消費視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 500,
            footer: true,
            css:"dhxProductInfo_otherPrdGridWindow1"
        });

        dhxProductInfo_otherPrdGridWindow1.footer.data.add({type:"spacer"});
        dhxProductInfo_otherPrdGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_otherPrdGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_otherPrdGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(productInfo_otherPrdGridForm.getItem("AC004").getValue() === "" || productInfo_otherPrdGridForm.getItem("AC004").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇加價產品",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_otherPrdGridForm.getItem("AC005").getValue() === "" || productInfo_otherPrdGridForm.getItem("AC005").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇房間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_otherPrdGridForm.getItem("AC103").getValue() === "" || productInfo_otherPrdGridForm.getItem("AC103").getValue() === 0){
                        DHXMessage({
                            text: "請填入數量",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/updateProductInfo_checkRemained", {
                        itemId1: productInfo_otherPrdGridForm.getItem("AC004").getValue(),itemId2: productInfo_otherPrdGridForm.getItem("AC103").getValue(),itemId3: productInfo_otherPrdGridForm.getItem("AC001").getValue()
                    },headers).then((response1) =>{

                        if(response1.status === 200){
                            if(response1.data[0].result === 0){
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: response1.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{
                                axios.post(apiServer+"/orders/updateProductInfo_otherPrdGridFormData",
                                    productInfo_otherPrdGridForm.getValue(),headers
                                ).then((response2) =>{
                                    if(response2.data[0].result){
                                        DHXMessage({
                                            text: "更新成功",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--success",
                                            expire:5000
                                        });

                                        axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                                            itemId1: productInfo_otherPrdGridForm.getItem("AC002").getValue()
                                        },headers).then((data) =>{
                                            productInfo_otherPrdGrid.data.parse(data.data);

                                            dhxOrderGrid.data.removeAll();

                                            if(orderGridSpanTmp != ""){
                                                orderGridSpanTmp.map((item)=>{
                                                    dhxOrderGrid.removeSpan(item.row,item.column);
                                                })
                                            }

                                            axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                                                itemId1:hotel
                                            },headers)
                                                .then((data) =>{
                                                    if (data.status === 200){
                                                        if(data.data){

                                                            dhxOrderGrid.data.removeAll();
                                                            dhxOrderGrid.data.parse(data.data.order_json);

                                                            orderGridSpanTmp = data.data.spanArray;

                                                            setTimeout(()=>{
                                                                if(data.data.spanArray.length != 0){
                                                                    data.data.spanArray.map((item)=>{
                                                                        dhxOrderGrid.addSpan(item);
                                                                        dhxProgressWindow1.hide();
                                                                    })
                                                                }else{
                                                                    dhxProgressWindow1.hide();
                                                                };
                                                            },1000)

                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });

                                            orderExportExcelGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                                                itemId1:hotel
                                            },headers)
                                                .then((data) =>{
                                                    if(data.status === 200){
                                                        if(data.data){
                                                            orderExportExcelGrid.data.parse(data.data);
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });

                                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                                itemId1: orderForm.getItem("AA001").getValue()
                                            },headers).then((response3) =>{
                                                if(response3.status === 200){
                                                    if(response3.data){
                                                        orderForm.setValue(response3.data[0]);
                                                        if(response3.data[0].unpaid > 0){
                                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                                        }else{
                                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                                        }

                                                        orderForm.getItem("AA006").setOptions(response3.data[0].orderSourceItem);
                                                        orderForm.getItem("AA006").setValue(response3.data[0].AA006)
                                                        orderForm.getItem("AA008").setOptions(response3.data[0].saleSourceItem);
                                                        orderForm.getItem("AA008").setValue(response3.data[0].AA008)
                                                        dhxProgressWindow1.hide();
                                                        isSaleSourceNeedChange = true;
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            })
                                                .catch((error) => {
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                        });

                                        dhxProductInfo_otherPrdGridWindow1.hide();
                                        productInfo_otherPrdGridForm.clear();
                                        productInfo_otherPrdGridForm.clear("validation");
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_otherPrdGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const productInfo_otherPrdGridForm = new DHXForm(null,{
            rows: productInfo_otherPrdGridFormConfig
        });

        dhxProductInfo_otherPrdGridWindow1.attach(productInfo_otherPrdGridForm);

        productInfo_otherPrdGridForm.getItem("AC004").events.on("change", function(value) {
            if(productInfo_otherPrdGridForm.getItem("AC004").getValue() !== '0' && productInfo_otherPrdGridForm.getItem("AC004").getValue() !== ''){
                axios.post(apiServer+"/orders/otherPrdGrid_loadOtherPrdPrice", {
                    itemId1: productInfo_otherPrdGridForm.getItem("AC004").getValue()
                },headers).then((response) =>{
                    productInfo_otherPrdGridForm.getItem("AC102").setValue(response.data[0].price);
                    productInfo_otherPrdGridForm.getItem("remained").setValue(response.data[0].remained);
                    productInfo_otherPrdGridForm.getItem("total").setValue(response.data[0].price * productInfo_otherPrdGridForm.getItem("AC103").getValue());
                    productInfo_otherPrdGridForm.getItem("AC103").clearValidate();
                })
                    .catch((error) => {
                        console.log(error);
                    });
                productInfo_otherPrdGridForm.getItem("AC103").enable();
            }else{
                productInfo_otherPrdGridForm.getItem("AC103").disable();
                productInfo_otherPrdGridForm.getItem("AC102").setValue("0");
                productInfo_otherPrdGridForm.getItem("AC103").setValue("0");
                productInfo_otherPrdGridForm.getItem("total").setValue("0");
            }
        });

        productInfo_otherPrdGridForm.getItem("AC103").events.on("keydown", function(value) {
            if(productInfo_otherPrdGridForm.getItem("AC103").getValue() == "0" || productInfo_otherPrdGridForm.getItem("AC103").getValue() == ""){

            }else{
                productInfo_otherPrdGridForm.getItem("total").setValue(productInfo_otherPrdGridForm.getItem("AC103").getValue() * productInfo_otherPrdGridForm.getItem("AC102").getValue());
            }
        });

        productInfo_otherPrdGridForm.getItem("AC103").events.on("change", function(value) {
            if(productInfo_otherPrdGridForm.getItem("AC103").getValue() == "0" || productInfo_otherPrdGridForm.getItem("AC103").getValue() == ""){

            }else{
                productInfo_otherPrdGridForm.getItem("total").setValue(productInfo_otherPrdGridForm.getItem("AC103").getValue() * productInfo_otherPrdGridForm.getItem("AC102").getValue());
            }
        });

        //編輯訂單視窗_產品資訊_加價產品消費_刪除加價產品消費視窗
        const dhxProductInfo_otherPrdGridWindow2 = new DHXWindow({
            title: "刪除加價產品消費視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 680,
            footer: true,
            css:"dhxProductInfo_otherPrdGridWindow2"
        });

        dhxProductInfo_otherPrdGridWindow2.footer.data.add({type:"spacer"});
        dhxProductInfo_otherPrdGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_otherPrdGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_otherPrdGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(otherPrdGrid_deleteForm.getItem("AC105").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/deleteOtherPrd",
                        otherPrdGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/orders/loadOtherPrdGrid", {
                                itemId1: productInfo_otherPrdGridForm.getItem("AC002").getValue()
                            },headers).then((data) =>{
                                productInfo_otherPrdGrid.data.parse(data.data);
                                otherPrdGrid_deleteForm.clear();
                                otherPrdGrid_deleteForm.setValue({"AC001": "0"});
                                otherPrdGrid_deleteForm.clear("validation");
                            });

                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        orderForm.setValue(response.data[0]);
                                        if(response.data[0].unpaid > 0){
                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                        }else{
                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                        }
                                        // if(response.data[0].AA137 == "1"){
                                        //     orderForm.getItem("AA101_1").hide();
                                        //     orderForm.getItem("AA101_2").show();
                                        //     orderForm.getItem("AA101_2").setOptions(response.data[0].orderSourceItem);
                                        //     orderForm.getItem("AA101_2").setValue(response.data[0].AA006)
                                        // }else if(response.data[0].AA137 == "2"){
                                        //     orderForm.getItem("AA101_1").show(response.data[0].AA101);
                                        //     orderForm.getItem("AA101_2").setValue()
                                        //     orderForm.getItem("AA101_2").hide();
                                        // }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }

                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            dhxOrderGrid.data.removeAll();

                            if(orderGridSpanTmp != ""){
                                orderGridSpanTmp.map((item)=>{
                                    dhxOrderGrid.removeSpan(item.row,item.column);
                                })
                            }

                            axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                                itemId1:hotel
                            },headers)
                                .then((data) =>{
                                    if (data.status === 200){
                                        if(data.data){

                                            dhxOrderGrid.data.removeAll();
                                            dhxOrderGrid.data.parse(data.data.order_json);

                                            orderGridSpanTmp = data.data.spanArray;

                                            setTimeout(()=>{
                                                if(data.data.spanArray.length != 0){
                                                    data.data.spanArray.map((item)=>{
                                                        dhxOrderGrid.addSpan(item);
                                                        dhxProgressWindow1.hide();
                                                    })
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                };
                                            },1000)

                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            orderExportExcelGrid.data.removeAll();
                            axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                                itemId1:hotel
                            },headers)
                                .then((data) =>{
                                    if(data.status === 200){
                                        if(data.data){
                                            orderExportExcelGrid.data.parse(data.data);
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            dhxProductInfo_otherPrdGridWindow2.hide();
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_otherPrdGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let otherPrdGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"otherPrdGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "otherPrdGrid_deleteForm"},
            ]
        });

        const otherPrdGrid_deleteForm = new DHXForm(null,{
            rows: productInfo_otherPrdGridDeleteFormConfig
        });

        dhxProductInfo_otherPrdGridWindow2.attach(otherPrdGrid_deleteFormLayout);
        otherPrdGrid_deleteFormLayout.getCell("otherPrdGrid_deleteForm").attach(otherPrdGrid_deleteForm);

        dhxTabbar.getCell("productInfo").attach(orderBasicSetTabLayout2);
        orderBasicSetTabLayout2.getCell("productInfo_roomGrid").attach(productInfo_roomGrid);
        orderBasicSetTabLayout2.getCell("productInfo_otherPrdGrid").attach(productInfo_otherPrdGrid);

        //編輯訂單視窗_產品資訊_付款紀錄
        let productInfo_payLogGrid = new DHXGrid(null,{
            columns: productInfo_payLogGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "productInfo_payLogGrid_edit": function (e, data) {
                        if(canEdit){

                            productInfo_payLogGridForm.clear();

                            productInfo_payLogGridForm.setValue({"TMPId":"0"});
                            axios.get(apiServer+"/orders/loadPaymentMethodList", headers)
                                .then((response) =>{
                                    if(response.status == 200){
                                        productInfo_payLogGridForm.getItem("AH004").setOptions(response.data);
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            dhxProductInfo_payLogGridWindow1.header.data.update("title", {value: "編輯付款紀錄視窗"});
                            axios.post(apiServer+"/orders/loadProductInfo_payLogGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response1) =>{
                                if(response1.status === 200) {
                                    productInfo_payLogGridForm.setValue(response1.data[0]);
                                    dhxProductInfo_payLogGridWindow1.show();
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "productInfo_payLogGrid_delete": function (e, data) {

                        payLogGrid_deleteForm.clear();
                        if(canDelete){
                            axios.post(apiServer+"/orders/loadProductInfo_payLogGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) => {
                                if (response.status === 200) {
                                    payLogGrid_deleteForm.setValue(response.data[0])
                                }
                            });
                            dhxProductInfo_payLogGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    }
                }
            }
        });

        const productInfo_payLogGridToolbar = new DHXToolbar(null, {
        });

        productInfo_payLogGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        productInfo_payLogGridForm.clear();
                        dhxProductInfo_payLogGridWindow1.header.data.update("title", {value: "新增付款紀錄視窗"});
                        productInfo_payLogGridForm.setValue({"AH001":"0","AH002":orderForm.getItem("AA001").getValue(),"AH003":hotel,"TMPId":"0"});

                        axios.get(apiServer+"/orders/loadPaymentMethodList", headers)
                            .then((response) =>{
                                if(response.status === 200){
                                    productInfo_payLogGridForm.getItem("AH004").setOptions(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });

                        dhxProductInfo_payLogGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    break;
                case "sendNewPayLink":
                    productInfo_sendNewPayLinkForm.setValue({"AI002":orderForm.getItem("AA001").getValue(),"AI003":orderForm.getItem("AA002").getValue(),"AI106":orderForm.getItem("AA137").getValue()});

                    axios.post(apiServer+"/orders/loadItemDesc", {
                        itemId1: orderForm.getItem("AA001").getValue()
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if (response.data){
                                productInfo_sendNewPayLinkForm.getItem("AI100").setValue(response.data[0].result);
                                productInfo_sendNewPayLinkForm.getItem("AI101").setValue("請在期限內付款");
                                productInfo_sendNewPayLinkForm.getItem("AI104").setValue(response.data[0].price);
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    dhxProductInfo_sendNewPayLinkFormWindow1.show();
                    break;
                default:
                    break;
            }
        });

        productInfo_payLogGridToolbar.data.parse(JSON.parse(JSON.stringify(productInfo_payLogGridToolbarConfig)));

        orderBasicSetTabLayout2.getCell("productInfo_payLogGridToolbar").attach(productInfo_payLogGridToolbar);
        orderBasicSetTabLayout2.getCell("productInfo_payLogGrid").attach(productInfo_payLogGrid);

        //編輯訂單視窗_產品資訊_付款紀錄_新增/編輯視窗
        const dhxProductInfo_payLogGridWindow1 = new DHXWindow({
            title: "新增付款紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 430,
            footer: true,
            css:"dhxProductInfo_payLogGridWindow1"
        });

        dhxProductInfo_payLogGridWindow1.footer.data.add({type:"spacer"});
        dhxProductInfo_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_payLogGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(productInfo_payLogGridForm.getItem("AH004").getValue() === "" || productInfo_payLogGridForm.getItem("AH004").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇付款方式",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_payLogGridForm.getItem("AH100").getValue() === ""){
                        DHXMessage({
                            text: "請填入付款金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/orders/updateProductInfo_payLogGridFormData",
                        productInfo_payLogGridForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                axios.post(apiServer+"/orders/loadPayLogGrid", {
                                    itemId1: orderForm.getItem("AA001").getValue()
                                },headers).then((data) =>{
                                    productInfo_payLogGrid.data.removeAll();
                                    productInfo_payLogGrid.data.parse(data.data);

                                    dhxProductInfo_payLogGridWindow1.hide();
                                    productInfo_payLogGridForm.clear();
                                    productInfo_payLogGridForm.clear("validation");

                                    dhxOrderGrid.data.removeAll();

                                    if(orderGridSpanTmp != ""){
                                        orderGridSpanTmp.map((item)=>{
                                            dhxOrderGrid.removeSpan(item.row,item.column);
                                        })
                                    }

                                    axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                                        itemId1:hotel
                                    },headers)
                                        .then((data) =>{
                                            if (data.status === 200){
                                                if(data.data){

                                                    dhxOrderGrid.data.removeAll();
                                                    dhxOrderGrid.data.parse(data.data.order_json);

                                                    orderGridSpanTmp = data.data.spanArray;

                                                    setTimeout(()=>{
                                                        if(data.data.spanArray.length != 0){
                                                            data.data.spanArray.map((item)=>{
                                                                dhxOrderGrid.addSpan(item);
                                                                dhxProgressWindow1.hide();
                                                            })
                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                        };
                                                    },1000)

                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });

                                    orderExportExcelGrid.data.removeAll();
                                    axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                                        itemId1:hotel
                                    },headers)
                                        .then((data) =>{
                                            if(data.status === 200){
                                                if(data.data){
                                                    orderExportExcelGrid.data.parse(data.data);
                                                }else{
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });

                                    axios.post(apiServer+"/orders/loadOrderFormData", {
                                        itemId1: orderForm.getItem("AA001").getValue()
                                    },headers).then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                orderForm.setValue(response2.data[0]);
                                                if(response2.data[0].unpaid > 0){
                                                    productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                                }else{
                                                    productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                                }

                                                orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                                orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                                orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                                orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                                isSaleSourceNeedChange = true;
                                                dhxProgressWindow1.hide();

                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }

                                    })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_payLogGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const productInfo_payLogGridForm = new DHXForm(null,{
            rows: productInfo_payLogGridFormConfig
        });

        dhxProductInfo_payLogGridWindow1.attach(productInfo_payLogGridForm);

        //編輯訂單視窗_產品資訊_加價產品消費_刪除付款紀錄視窗
        const dhxProductInfo_payLogGridWindow2 = new DHXWindow({
            title: "刪除付款紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 680,
            footer: true,
            css:"dhxProductInfo_payLogGridWindow2"
        });

        dhxProductInfo_payLogGridWindow2.footer.data.add({type:"spacer"});
        dhxProductInfo_payLogGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_payLogGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_payLogGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(payLogGrid_deleteForm.getItem("AH103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/deletepayLog",
                        payLogGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){

                        }
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/orders/loadPayLogGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                productInfo_payLogGrid.data.removeAll();
                                productInfo_payLogGrid.data.parse(data.data);

                                dhxProductInfo_payLogGridWindow2.hide();
                                productInfo_payLogGridForm.clear();
                                productInfo_payLogGridForm.clear("validation");

                                dhxOrderGrid.data.removeAll();

                                if(orderGridSpanTmp != ""){
                                    orderGridSpanTmp.map((item)=>{
                                        dhxOrderGrid.removeSpan(item.row,item.column);
                                    })
                                }

                                axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                                    itemId1:hotel
                                },headers)
                                    .then((data) =>{
                                        if (data.status === 200){
                                            if(data.data){

                                                dhxOrderGrid.data.removeAll();
                                                dhxOrderGrid.data.parse(data.data.order_json);

                                                orderGridSpanTmp = data.data.spanArray;

                                                setTimeout(()=>{
                                                    if(data.data.spanArray.length != 0){
                                                        data.data.spanArray.map((item)=>{
                                                            dhxOrderGrid.addSpan(item);
                                                            dhxProgressWindow1.hide();
                                                        })
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                    };
                                                },1000)

                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });

                                orderExportExcelGrid.data.removeAll();
                                axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                                    itemId1:hotel
                                },headers)
                                    .then((data) =>{
                                        if(data.status === 200){
                                            if(data.data){
                                                orderExportExcelGrid.data.parse(data.data);
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });

                                axios.post(apiServer+"/orders/loadOrderFormData", {
                                    itemId1: orderForm.getItem("AA001").getValue()
                                },headers).then((response2) =>{
                                    if(response2.status === 200){
                                        if(response2.data){
                                            orderForm.setValue(response2.data[0]);
                                            if(response2.data[0].unpaid > 0){
                                                productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                            }else{
                                                productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                            }

                                            orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                            orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                            orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                            orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                            isSaleSourceNeedChange = true;

                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }

                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_otherPrdGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let payLogGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"payLogGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "payLogGrid_deleteForm"},
            ]
        });

        const payLogGrid_deleteForm = new DHXForm(null,{
            rows: productInfo_payLogGridDeleteFormConfig
        });

        dhxProductInfo_payLogGridWindow2.attach(payLogGrid_deleteFormLayout);
        payLogGrid_deleteFormLayout.getCell("payLogGrid_deleteForm").attach(payLogGrid_deleteForm);

        const dhxProductInfo_sendNewPayLinkFormWindow1 = new DHXWindow({
            title: "發送金流鏈結視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 500,
            footer: true,
            css:"dhxProductInfo_sendNewPayLinkFormWindow1"
        });

        dhxProductInfo_sendNewPayLinkFormWindow1.footer.data.add({type:"spacer"});
        dhxProductInfo_sendNewPayLinkFormWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_sendNewPayLinkFormWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootSend)));

        dhxProductInfo_sendNewPayLinkFormWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_SEND":
                    if(productInfo_sendNewPayLinkForm.getItem("AI100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫商品敘述",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_sendNewPayLinkForm.getItem("AI104").getValue() === ""){
                        DHXMessage({
                            text: "請填寫金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/sendPayLink",
                        productInfo_sendNewPayLinkForm.getValue()
                        ,headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: "發送成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                productInfo_sendNewPayLinkForm.getItem("AI105").setValue(response.data[0].result);

                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_sendNewPayLinkFormWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const productInfo_sendNewPayLinkForm = new DHXForm(null,{
            rows: productInfo_sendNewPayLinkFormConfig
        });

        dhxProductInfo_sendNewPayLinkFormWindow1.attach(productInfo_sendNewPayLinkForm);

        //編輯訂單視窗_產品資訊_發票紀錄
        let productInfo_invoiceGrid = new DHXGrid(null,{
            columns: productInfo_invoiceGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "productInfo_invoiceGrid_edit": function (e, data) {
                        if(canEdit){

                            productInfo_invoiceGridForm.clear();
                            productInfo_invoiceGridForm.setValue({"TMPId":"0"});

                            dhxProductInfo_invoiceGridWindow1.header.data.update("title", {value: "編輯發票紀錄視窗"});

                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/loadProductInfo_invoiceGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response1) =>{
                                if(response1.status === 200) {
                                    if(response1.data){
                                        productInfo_invoiceGridForm.setValue(response1.data[0]);
                                        dhxProductInfo_invoiceGridWindow1.show();
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "productInfo_invoiceGrid_delete": function (e, data) {

                        invoiceGrid_deleteForm.clear();
                        invoiceGrid_deleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎？");
                        if(canDelete){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/loadProductInfo_invoiceGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) => {
                                if (response.status === 200) {
                                    if(response.data){
                                        invoiceGrid_deleteForm.setValue(response.data[0])
                                        dhxProductInfo_invoiceGridWindow2.show();
                                        dhxProgressWindow1.hide();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            });
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        const productInfo_invoiceGridToolbar = new DHXToolbar(null, {
        });

        productInfo_invoiceGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        productInfo_invoiceGridForm.clear();
                        dhxProductInfo_invoiceGridWindow1.header.data.update("title", {value: "新增發票紀錄視窗"});
                        productInfo_invoiceGridForm.setValue({"AL001":"0","AL002":hotel,"AL003":orderForm.getItem("AA001").getValue(),"TMPId":"0"});

                        axios.get(apiServer+"/orders/loadPaymentMethodList", headers)
                            .then((response) =>{
                                if(response.status === 200){
                                    productInfo_payLogGridForm.getItem("AH004").setOptions(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });

                        dhxProductInfo_invoiceGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    break;
                default:
                    break;
            }
        });

        productInfo_invoiceGridToolbar.data.parse(JSON.parse(JSON.stringify(productInfo_invoiceGridToolbarConfig)));

        orderBasicSetTabLayout2.getCell("productInfo_invoiceGridToolbar").attach(productInfo_invoiceGridToolbar);
        orderBasicSetTabLayout2.getCell("productInfo_invoiceGrid").attach(productInfo_invoiceGrid);

        //編輯訂單視窗_產品資訊_發票紀錄_新增/編輯視窗
        const dhxProductInfo_invoiceGridWindow1 = new DHXWindow({
            title: "新增發票紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 400,
            footer: true,
            css:"dhxProductInfo_invoiceGridWindow1"
        });

        dhxProductInfo_invoiceGridWindow1.footer.data.add({type:"spacer"});
        dhxProductInfo_invoiceGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_invoiceGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_invoiceGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(productInfo_invoiceGridForm.getItem("AL103").getValue() === ""){
                        DHXMessage({
                            text: "請填入開立日期時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_invoiceGridForm.getItem("AL101").getValue() === ""){
                        DHXMessage({
                            text: "請填入發票號碼",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(productInfo_invoiceGridForm.getItem("AL102").getValue() === ""){
                        DHXMessage({
                            text: "請填入發票金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/orders/checkInvoiceNumber",
                        productInfo_invoiceGridForm.getValue(),headers)
                        .then((data) =>{
                            if(data.status === 200){
                                if(data.data){
                                    if(data.data[0].result === 0){
                                        DHXMessage({
                                            text: "發票號碼重複",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:10000
                                        });
                                        dhxProgressWindow1.hide();
                                    }else{
                                        axios.post(apiServer+"/orders/updateProductInfo_invoiceGridFormData",
                                            productInfo_invoiceGridForm.getValue(),headers
                                        ).then((response) =>{
                                            if(response.status === 200){
                                                if(response.data){
                                                    DHXMessage({
                                                        text: response.data[0].message,
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--success",
                                                        expire:10000
                                                    });

                                                    axios.post(apiServer+"/orders/loadInvoiceGrid", {
                                                        itemId1: orderForm.getItem("AA001").getValue()
                                                    },headers).then((data) =>{
                                                        productInfo_invoiceGrid.data.removeAll();
                                                        productInfo_invoiceGrid.data.parse(data.data);

                                                        dhxProductInfo_invoiceGridWindow1.hide();
                                                        productInfo_invoiceGridForm.clear();
                                                        productInfo_invoiceGridForm.clear("validation");

                                                        dhxOrderGrid.data.removeAll();

                                                        if(orderGridSpanTmp != ""){
                                                            orderGridSpanTmp.map((item)=>{
                                                                dhxOrderGrid.removeSpan(item.row,item.column);
                                                            })
                                                        }

                                                        dhxOrderGrid.data.removeAll();

                                                        if(orderGridSpanTmp != ""){
                                                            orderGridSpanTmp.map((item)=>{
                                                                dhxOrderGrid.removeSpan(item.row,item.column);
                                                            })
                                                        }

                                                        axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                                                            itemId1:hotel
                                                        },headers)
                                                            .then((data) =>{
                                                                if (data.status === 200){
                                                                    if(data.data){

                                                                        dhxOrderGrid.data.removeAll();
                                                                        dhxOrderGrid.data.parse(data.data.order_json);

                                                                        orderGridSpanTmp = data.data.spanArray;

                                                                        setTimeout(()=>{
                                                                            if(data.data.spanArray.length != 0){
                                                                                data.data.spanArray.map((item)=>{
                                                                                    dhxOrderGrid.addSpan(item);
                                                                                    dhxProgressWindow1.hide();
                                                                                })
                                                                            }else{
                                                                                dhxProgressWindow1.hide();
                                                                            };
                                                                        },1000)

                                                                    }else{
                                                                        dhxProgressWindow1.hide();
                                                                        DHXMessage({
                                                                            text: "連線異常，請重新整理",
                                                                            icon:"dxi dxi-close",
                                                                            css: "dhx_message--error",
                                                                            expire:5000
                                                                        });
                                                                    }
                                                                }else{
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                dhxProgressWindow1.hide();
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            });

                                                        orderExportExcelGrid.data.removeAll();
                                                        axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                                                            itemId1:hotel
                                                        },headers)
                                                            .then((data) =>{
                                                                if(data.status === 200){
                                                                    if(data.data){
                                                                        orderExportExcelGrid.data.parse(data.data);
                                                                    }else{
                                                                        DHXMessage({
                                                                            text: "連線異常，請重新整理",
                                                                            icon:"dxi dxi-close",
                                                                            css: "dhx_message--error",
                                                                            expire:5000
                                                                        });
                                                                    }
                                                                }else{
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                }
                                                            })
                                                            .catch((error) => {
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            });

                                                        axios.post(apiServer+"/orders/loadOrderFormData", {
                                                            itemId1: orderForm.getItem("AA001").getValue()
                                                        },headers).then((response2) =>{
                                                            if(response2.status === 200){
                                                                if(response2.data){
                                                                    orderForm.setValue(response2.data[0]);
                                                                    if(response2.data[0].unpaid > 0){
                                                                        productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                                                    }else{
                                                                        productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                                                    }

                                                                    orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                                                    orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                                                    orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                                                    orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                                                    isSaleSourceNeedChange = true;

                                                                    dhxProgressWindow1.hide();
                                                                }else{
                                                                    dhxProgressWindow1.hide();
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                }
                                                            }else{
                                                                dhxProgressWindow1.hide();
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            }

                                                        })
                                                            .catch((error) => {
                                                                dhxProgressWindow1.hide();
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            });
                                                    });
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                            .catch((error) => {
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });



                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_invoiceGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const productInfo_invoiceGridForm = new DHXForm(null,{
            rows: productInfo_invoiceGridFormConfig
        });

        dhxProductInfo_invoiceGridWindow1.attach(productInfo_invoiceGridForm);

        //編輯訂單視窗_產品資訊_發票紀錄_刪除付款紀錄視窗
        const dhxProductInfo_invoiceGridWindow2 = new DHXWindow({
            title: "刪除發票紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 630,
            footer: true,
            css:"dhxProductInfo_invoiceGridWindow2"
        });

        dhxProductInfo_invoiceGridWindow2.footer.data.add({type:"spacer"});
        dhxProductInfo_invoiceGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxProductInfo_invoiceGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxProductInfo_invoiceGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(invoiceGrid_deleteForm.getItem("AL110").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/orders/deleteInvoice",
                        invoiceGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                axios.post(apiServer+"/orders/loadInvoiceGrid", {
                                    itemId1: orderForm.getItem("AA001").getValue()
                                },headers).then((response2) =>{
                                    if(response2.status === 200){
                                        if(response2.data){
                                            productInfo_invoiceGrid.data.removeAll();
                                            productInfo_invoiceGrid.data.parse(response2.data);

                                            dhxProductInfo_invoiceGridWindow2.hide();
                                            productInfo_invoiceGridForm.clear();
                                            productInfo_invoiceGridForm.clear("validation");

                                            dhxOrderGrid.data.removeAll();

                                            if(orderGridSpanTmp != ""){
                                                orderGridSpanTmp.map((item)=>{
                                                    dhxOrderGrid.removeSpan(item.row,item.column);
                                                })
                                            }

                                            dhxOrderGrid.data.removeAll();

                                            if(orderGridSpanTmp != ""){
                                                orderGridSpanTmp.map((item)=>{
                                                    dhxOrderGrid.removeSpan(item.row,item.column);
                                                })
                                            }

                                            axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                                                itemId1:hotel
                                            },headers)
                                                .then((data) =>{
                                                    if (data.status === 200){
                                                        if(data.data){

                                                            dhxOrderGrid.data.removeAll();
                                                            dhxOrderGrid.data.parse(data.data.order_json);

                                                            orderGridSpanTmp = data.data.spanArray;

                                                            setTimeout(()=>{
                                                                if(data.data.spanArray.length != 0){
                                                                    data.data.spanArray.map((item)=>{
                                                                        dhxOrderGrid.addSpan(item);
                                                                        dhxProgressWindow1.hide();
                                                                    })
                                                                }else{
                                                                    dhxProgressWindow1.hide();
                                                                };
                                                            },1000)

                                                        }else{
                                                            dhxProgressWindow1.hide();
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });

                                            orderExportExcelGrid.data.removeAll();
                                            axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                                                itemId1:hotel
                                            },headers)
                                                .then((data) =>{
                                                    if(data.status === 200){
                                                        if(data.data){
                                                            orderExportExcelGrid.data.parse(data.data);
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });

                                            axios.post(apiServer+"/orders/loadOrderFormData", {
                                                itemId1: orderForm.getItem("AA001").getValue()
                                            },headers).then((response2) =>{
                                                if(response2.status === 200){
                                                    if(response2.data){
                                                        orderForm.setValue(response2.data[0]);
                                                        if(response2.data[0].unpaid > 0){
                                                            productInfo_payLogGridToolbar.enable("sendNewPayLink");
                                                        }else{
                                                            productInfo_payLogGridToolbar.disable("sendNewPayLink");
                                                        }

                                                        orderForm.getItem("AA006").setOptions(response2.data[0].orderSourceItem);
                                                        orderForm.getItem("AA006").setValue(response2.data[0].AA006)
                                                        orderForm.getItem("AA008").setOptions(response2.data[0].saleSourceItem);
                                                        orderForm.getItem("AA008").setValue(response2.data[0].AA008)

                                                        isSaleSourceNeedChange = true;

                                                        dhxProgressWindow1.hide();
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }

                                            })
                                                .catch((error) => {
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }).catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxProductInfo_invoiceGridWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let invoiceGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"invoiceGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "invoiceGrid_deleteForm"},
            ]
        });

        const invoiceGrid_deleteForm = new DHXForm(null,{
            rows: productInfo_invoiceGridDeleteFormConfig
        });

        dhxProductInfo_invoiceGridWindow2.attach(invoiceGrid_deleteFormLayout);
        invoiceGrid_deleteFormLayout.getCell("invoiceGrid_deleteForm").attach(invoiceGrid_deleteForm);

        //編輯訂單視窗_提醒設定
        let orderBasicSetTabLayout3 = new DHXLayout(null,{
            rows:[
                {id:"alertSetToolbar", height: "content", gravity: false},
                {id: "alertSetGrid"},
            ]
        });

        let alertSetGrid = new DHXGrid(null,{
            columns: alertSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers:{
                onclick:{
                    "alertSetGrid_edit":function(event,data){
                        if(canEdit){
                            dhxAlertSetGridWindow1.header.data.update("title", {value: "編輯提醒設定視窗"});

                            axios.post(apiServer+"/orders/loadAlertSetGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                alertSetGridForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });

                            dhxAlertSetGridWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "alertSetGrid_delete":function(event,data){
                        if(canDelete){
                            axios.post(apiServer+"/orders/loadAlertSetGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                alertSetGrid_deleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxAlertSetGridWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                }
            }
        });

        const alertSetToolbar = new DHXToolbar(null, {
        });

        alertSetToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        dhxAlertSetGridWindow1.header.data.update("title", {value: "新增提醒設定視窗"});
                        alertSetGridForm.setValue({"AE001":"0","AE002":hotel,"AE003":orderForm.getItem("AA001").getValue()})
                        dhxAlertSetGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });
        alertSetToolbar.data.parse(JSON.parse(JSON.stringify(alertSetGridToolbarConfig)));

        dhxTabbar.getCell("alertSet").attach(orderBasicSetTabLayout3);
        orderBasicSetTabLayout3.getCell("alertSetToolbar").attach(alertSetToolbar);
        orderBasicSetTabLayout3.getCell("alertSetGrid").attach(alertSetGrid);

        //編輯訂單視窗_提醒設定_新增/編輯提醒設定視窗
        const dhxAlertSetGridWindow1 = new DHXWindow({
            title: "新增提醒設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 360,
            footer: true,
            css:"dhxAlertSetGridWindow1"
        });

        dhxAlertSetGridWindow1.footer.data.add({type:"spacer"});
        dhxAlertSetGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxAlertSetGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxAlertSetGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(alertSetGridForm.getItem("AE100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫提醒時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(alertSetGridForm.getItem("AE101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫提醒內容",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/updateAlertSetGridFormData",
                        alertSetGridForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            alertSetGrid.data.removeAll();

                            axios.post(apiServer+"/orders/loadAlertSetGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                alertSetGrid.data.removeAll();
                                alertSetGrid.data.parse(data.data);
                            });

                            dhxAlertSetGridWindow1.hide();
                            alertSetGridForm.clear();
                            alertSetGridForm.setValue({"AF001": "0","AA001": "0"});
                            alertSetGridForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxAlertSetGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const alertSetGridForm = new DHXForm(null,{
            rows: alertSetGridFormConfig
        });

        dhxAlertSetGridWindow1.attach(alertSetGridForm);

        //編輯訂單視窗_提醒設定_刪除提醒設定視窗
        const dhxAlertSetGridWindow2 = new DHXWindow({
            title: "刪除提醒設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 620,
            footer: true,
            css: "dhxAlertSetGridWindow2"
        });

        dhxAlertSetGridWindow2.footer.data.add({type:"spacer"});
        dhxAlertSetGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxAlertSetGridWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxAlertSetGridWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    alertSetGrid_deleteForm.clear();
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(alertSetGrid_deleteForm.getItem("AE104").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/deleteAlertSet",
                        alertSetGrid_deleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            alertSetGrid.data.removeAll();

                            axios.post(apiServer+"/orders/loadAlertSetGrid", {
                                itemId1: orderForm.getItem("AA001").getValue()
                            },headers).then((data) =>{
                                alertSetGrid.data.removeAll();
                                alertSetGrid.data.parse(data.data);
                            });

                            dhxAlertSetGridWindow2.hide();
                            alertSetGrid_deleteForm.clear();
                            alertSetGrid_deleteForm.setValue({"AE001": "0","AA001": "0"});
                            alertSetGrid_deleteForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxAlertSetGridWindow2.hide();
                    alertSetGrid_deleteForm.clear();
                    alertSetGrid_deleteForm.getItem("alertSetGrid_deleteFormTitle").setValue("確定要刪除嗎？");
                    break;
                default:
                    break;
            }
        });

        let alertSetGrid_deleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"alertSetGrid_deleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                },
                {id: "alertSetGrid_deleteForm",css: "alertSetGrid_deleteForm"},
            ]
        });

        const alertSetGrid_deleteForm = new DHXForm(null,{
            rows: alertSetGrid_deleteFormConfig
        });

        dhxAlertSetGridWindow2.attach(alertSetGrid_deleteFormLayout);
        alertSetGrid_deleteFormLayout.getCell("alertSetGrid_deleteForm").attach(alertSetGrid_deleteForm);


        const dhxOrderWindow2 = new DHXWindow({
            title: "取消訂單視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 550,
            footer: true,
            css: "dhxOrderWindow2"
        });

        dhxOrderWindow2.footer.data.add({type:"spacer"});
        dhxOrderWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderWindow2.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    orderDeleteForm.clear();
                    orderDeleteForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(orderDeleteForm.getItem("AA005").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇取消原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();

                    axios.post(apiServer+"/orders/deleteOrder",
                        orderDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                dhxOrderGrid.data.removeAll();

                                if(orderGridSpanTmp != ""){
                                    orderGridSpanTmp.map((item)=>{
                                        dhxOrderGrid.removeSpan(item.row,item.column);
                                    })
                                }

                                axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                                    itemId1:hotel
                                },headers)
                                    .then((data) =>{
                                        if (data.status === 200){
                                            if(data.data){

                                                dhxOrderGrid.data.removeAll();
                                                dhxOrderGrid.data.parse(data.data.order_json);

                                                orderGridSpanTmp = data.data.spanArray;

                                                setTimeout(()=>{
                                                    if(data.data.spanArray.length != 0){
                                                        data.data.spanArray.map((item)=>{
                                                            dhxOrderGrid.addSpan(item);
                                                            dhxProgressWindow1.hide();
                                                        })
                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                    };
                                                },1000)

                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });

                                orderExportExcelGrid.data.removeAll();
                                axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                                    itemId1:hotel
                                },headers)
                                    .then((data) =>{
                                        if(data.status === 200){
                                            if(data.data){
                                                orderExportExcelGrid.data.parse(data.data);
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });

                                orderDeleteForm.clear();
                                orderDeleteForm.setValue({"AA001": "0"});
                                orderDeleteForm.clear("validation");
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let orderDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"orderDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "orderDeleteForm"},
            ]
        });

        const orderDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxOrderWindow2.attach(orderDeleteFormLayout);
        orderDeleteFormLayout.getCell("orderDeleteForm").attach(orderDeleteForm);

        //新增訂單視窗
        const dhxNewOrderWindow1 = new DHXWindow({
            title: "新增訂單視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 700,
            width: 800,
            footer: true,
            css: "dhxNewOrderWindow1"
        });

        dhxNewOrderWindow1.footer.data.add({type:"spacer"});
        dhxNewOrderWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxNewOrderWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxNewOrderWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    // if(newOrder_purchaserInfoForm.getItem("AA109").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填入電話",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }

                    if(newOrder_purchaserInfoForm.getItem("AA138").getValue() === ""){
                        DHXMessage({
                            text: "請選取訂房類型",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(newOrder_purchaserInfoForm.getItem("AA106").getValue() === ""){
                        DHXMessage({
                            text: "請填入姓名",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(newOrder_roomGrid.data.getLength() === 0){
                        DHXMessage({
                            text: "請新增欲訂購的房間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    dhxProgressWindow1.show();

                    isSaleSourceNeedChange = false;

                    axios.post(apiServer+"/orders/checkRoomRemains", {
                        itemId1:TMPId
                    },headers).then((response) =>{

                        if(response.status === 200){
                            if(response.data[0].result == 1){

                                let newOrder_purchaserInfoFormValue = Object.assign({},newOrder_purchaserInfoForm.getValue(), {TMPId: TMPId});
                                newOrder_purchaserInfoFormValue = Object.assign({},newOrder_purchaserInfoFormValue, {AA002: hotel});

                                axios.post(apiServer+"/orders/updateOrderFormData",
                                    newOrder_purchaserInfoFormValue
                                    ,headers).then((response2) =>{

                                    if(response2.data[0].result){
                                        DHXMessage({
                                            html:"<div style='color:white;'>"+response2.data[0].message+"</div>",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--success",
                                            expire:5000
                                        });


                                        if(newOrder_payLogGrid.data.getLength() !== 0){
                                            newOrder_payLogGridForm.getItem("AH001").setValue("0");
                                            newOrder_payLogGridForm.getItem("AH002").setValue(response2.data[0].orderId);
                                            newOrder_payLogGridForm.getItem("AH003").setValue(hotel);
                                            let newOrder_payLogGridFormValue = Object.assign({},newOrder_payLogGridForm.getValue(), {TMPId: TMPId});
                                            axios.post(apiServer+"/orders/updateProductInfo_payLogGridFormData",
                                                newOrder_payLogGridFormValue,headers
                                            ).then((response3) =>{
                                                dhxProgressWindow1.hide();
                                            })
                                                .catch((error) => {
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                        }else{
                                            dhxProgressWindow1.hide();
                                        }

                                        TMPId = "0";

                                        dhxOrderGrid.data.removeAll();

                                        if(orderGridSpanTmp != ""){
                                            orderGridSpanTmp.map((item)=>{
                                                dhxOrderGrid.removeSpan(item.row,item.column);
                                            })
                                        }

                                        axios.post(apiServer+"/orders/loadUnpaidOrderGrid",{
                                            itemId1:hotel
                                        },headers)
                                            .then((data) =>{
                                                if (data.status === 200){
                                                    if(data.data){

                                                        dhxOrderGrid.data.removeAll();
                                                        dhxOrderGrid.data.parse(data.data.order_json);

                                                        orderGridSpanTmp = data.data.spanArray;

                                                        setTimeout(()=>{
                                                            if(data.data.spanArray.length != 0){
                                                                data.data.spanArray.map((item)=>{
                                                                    dhxOrderGrid.addSpan(item);
                                                                    dhxProgressWindow1.hide();
                                                                })
                                                            }else{
                                                                dhxProgressWindow1.hide();
                                                            };
                                                        },1000)

                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            })
                                            .catch((error) => {
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });

                                        orderExportExcelGrid.data.removeAll();
                                        axios.post(apiServer+"/orders/loadUnpaidOrderExportExcelGrid",{
                                            itemId1:hotel
                                        },headers)
                                            .then((data) =>{
                                                if(data.status === 200){
                                                    if(data.data){
                                                        orderExportExcelGrid.data.parse(data.data);
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                }else{
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            })
                                            .catch((error) => {
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }

                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else if(response.data[0].result == 0){
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    // text: response.data[0].message,
                                    html:response.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;

                case "WINDOW_FOOT_CLOSE":
                    dhxNewOrderWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        //編輯訂單視窗_產品資訊
        let newOrderWindow1Layout1 = new DHXLayout(null,{
            rows:[
                {id:"newOrder_purchaserInfoForm", height: "400px", css: "orderInfo_orderInfo"},
                {id:"newOrder_roomGridToolbar", height: "50px"},
                {id:"newOrder_roomGrid", height: "300px"},
                {id:"newOrder_payLogGridToolbar", height: "50px"},
                {id:"newOrder_payLogGrid", height: "300px"},
            ]
        });

        //編輯訂單視窗_訂單資訊
        const newOrder_purchaserInfoForm = new DHXForm(null,{
            rows: newOrder_purchaserGridFormConfig,
            css: "newOrder_purchaserInfoForm"
        });
        dhxNewOrderWindow1.attach(newOrderWindow1Layout1);
        newOrderWindow1Layout1.getCell("newOrder_purchaserInfoForm").attach(newOrder_purchaserInfoForm);

        const newOrder_roomGridToolbar = new DHXToolbar(null, {
        });
        newOrder_roomGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    isNeedFirstChangeEvent = false;
                    isNeedFirstChangeEventRest = false;
                    if(canEdit){
                        if(newOrder_purchaserInfoForm.getItem("AA138").getValue() == 1){
                            isNeedFirstChangeEvent = false;
                            dhxNewOrderWindow1.header.data.update("title", {value: "新增住宿房間視窗"});
                            newOrder_roomGridForm.clear();
                            newOrder_roomGridForm.setValue({"AG001":"0","AG002":orderForm.getItem("AA001").getValue(),"AG003":hotel,"AG006":TMPId,"AG007":"0","AG116":"0","AG127":"0","AG132":newOrder_purchaserInfoForm.getItem("AA138").getValue(),"AG133":"0","AG134":"0","total":""});

                            newOrder_roomGridForm_AG004 = newOrder_roomGridForm.getItem("AG004").getWidget();
                            newOrder_roomGridForm_AG004.data.removeAll();
                            newOrder_roomGridForm_AG004.data.parse([{
                                "id": "0",
                                "value": "---請選擇---",
                            }]);
                            newOrder_roomGridForm.getItem("AG004").setValue(["0"]);

                            newOrder_roomGridForm_AG005 = newOrder_roomGridForm.getItem("AG005").getWidget();
                            newOrder_roomGridForm_AG005.data.removeAll();
                            newOrder_roomGridForm_AG005.data.parse([{
                                "id": "0",
                                "value": "---請選擇---",
                            }]);
                            newOrder_roomGridForm.getItem("AG005").setValue(["0"]);

                            axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                            ).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        let newOrder_roomGridForm_AG007 = newOrder_roomGridForm.getItem("AG007").getWidget();
                                        if(response.data.length > 1){
                                            newOrder_roomGridForm_AG007.data.removeAll();
                                            newOrder_roomGridForm_AG007.data.parse(response.data);
                                            newOrder_roomGridForm.getItem("AG007").setValue(["0"]);
                                        }else{
                                            newOrder_roomGridForm_AG007.data.removeAll();
                                            newOrder_roomGridForm_AG007.data.parse([{
                                                "id": "0",
                                                "value": "---請選擇---",
                                            }]);
                                            newOrder_roomGridForm.getItem("AG007").setValue(["0"]);
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            newOrder_roomGridWindow1.attach(newOrder_roomGridForm);
                            newOrder_roomGridWindow1.show();
                            isNeedFirstChangeEvent = true;

                        }else if(newOrder_purchaserInfoForm.getItem("AA138").getValue() == 3){
                            isNeedFirstChangeEventRest = false;

                            dhxNewOrderWindow1.header.data.update("title", {value: "新增非住宿房間視窗"});
                            newOrder_restRoomGridForm.clear();

                            newOrder_restRoomGridForm.setValue({"AG001":"0","AG002":orderForm.getItem("AA001").getValue(),"AG003":hotel,"AG006":TMPId,"AG007":"0","AA103_2":"1","AA104_1":30,"AA104_2":1,"AA104_text1":"分鐘","AA104_text2":"小時","AG127":"0","AG132":newOrder_purchaserInfoForm.getItem("AA138").getValue(),"total":""});
                            AA103 = 2;
                            AA104 = 1;
                            restTypeText = '小時';
                            price = 0;

                            newOrder_restRoomGridForm_AG004 = newOrder_restRoomGridForm.getItem("AG004").getWidget();
                            newOrder_restRoomGridForm_AG004.data.removeAll();
                            newOrder_restRoomGridForm_AG004.data.parse([{
                                "id": "0",
                                "value": "---請選擇---",
                            }]);
                            newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);

                            newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                            newOrder_restRoomGridForm_AG005.data.removeAll();
                            newOrder_restRoomGridForm_AG005.data.parse([{
                                "id": "0",
                                "value": "---請選擇---",
                            }]);
                            newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);

                            axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                            ).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        let newOrder_restRoomGridForm_AG007 = newOrder_restRoomGridForm.getItem("AG007").getWidget();
                                        if(response.data.length > 1){
                                            newOrder_restRoomGridForm_AG007.data.removeAll();
                                            newOrder_restRoomGridForm_AG007.data.parse(response.data);
                                            newOrder_restRoomGridForm.getItem("AG007").setValue(["0"]);
                                        }else{
                                            newOrder_restRoomGridForm_AG007.data.removeAll();
                                            newOrder_restRoomGridForm_AG007.data.parse([{
                                                "id": "0",
                                                "value": "---請選擇---",
                                            }]);
                                            newOrder_restRoomGridForm.getItem("AG007").setValue(["0"]);
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            newOrder_roomGridWindow1.attach(newOrder_restRoomGridForm);
                            newOrder_roomGridWindow1.show();
                            isNeedFirstChangeEventRest = true;
                        }

                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;
                default:
                    break;
            }
        });

        newOrder_purchaserInfoForm.getItem("AA138").events.on("change", function(value) {
            axios.post(apiServer+"/orders/updateChangeBookingType", {
                    itemId1: TMPId
                },headers
            ).then((response) =>{
                // isNeedFirstChangeEvent = false;
                // isNeedFirstChangeEventRest = false;
                if(response.status === 200){
                    newOrder_roomGrid.data.removeAll();
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
                .catch((error) => {
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                });
        });

        newOrder_purchaserInfoForm.getItem("orderSource").events.on("change", function(value) {

            if(value != "0" && value != ""){
                axios.post(apiServer+"/orders/loadSaleSourceList", {
                        itemId1: value
                    },headers
                ).then((response) =>{
                    if(response.status === 200){
                        if(response.data){
                            if(response.data.length > 1){
                                newOrder_purchaserInfoForm.getItem("saleSource").setOptions(response.data);
                                newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                                newOrder_purchaserInfoForm.getItem("saleSource").enable();
                            }else{
                                newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                                newOrder_purchaserInfoForm.getItem("saleSource").disable();
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
            }else{
                newOrder_purchaserInfoForm.getItem("saleSource").setValue("0");
                newOrder_purchaserInfoForm.getItem("saleSource").disable();
            }
        });

        newOrder_roomGridToolbar.data.parse(JSON.parse(JSON.stringify(newOrder_roomGridToolbarConfig)));

        newOrderWindow1Layout1.getCell("newOrder_roomGridToolbar").attach(newOrder_roomGridToolbar);

        //編輯訂單視窗_產品資訊_房間
        let newOrder_roomGrid = new DHXGrid(null,{
            columns: newOrder_roomGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "newOrder_roomGrid_edit": function (e, data) {
                        isNeedFirstChangeEventRest = false;
                        isNeedFirstChangeEvent = false;
                        // TMPId = "0";
                        if(canEdit){
                            dhxProgressWindow1.show();
                            // DHXMessage({
                            //     text: "讀取資料中，請稍後",
                            //     icon:"dxi dxi-close",
                            //     css: "dhx_message--error",
                            //     expire:5000
                            // });
                            newOrder_roomGridForm.clear();
                            newOrder_roomGridWindow1.header.data.update("title", {value: "更新房間視窗"});

                            axios.post(apiServer+"/orders/loadTMPRoomFormData", {
                                itemId1: data.row.id,itemId2:data.row.bookingType
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        switch (data.row.bookingType){
                                            case 1:

                                                newOrder_roomGridForm.setValue(response.data[0]);
                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                ).then((response2) =>{
                                                    if(response2.status === 200){
                                                        if(response2.data){
                                                            let newOrder_roomGridForm_AG007 = newOrder_roomGridForm.getItem("AG007").getWidget();
                                                            if(response2.data.length > 1){
                                                                newOrder_roomGridForm_AG007.data.removeAll();
                                                                newOrder_roomGridForm_AG007.data.parse(response2.data);
                                                            }else{
                                                                newOrder_roomGridForm_AG007.data.removeAll();
                                                                newOrder_roomGridForm_AG007.data.parse([{
                                                                    "id": "0",
                                                                    "value": "---請選擇---",
                                                                }]);
                                                            }
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                    .catch((error) => {
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                                newOrder_roomGridForm.getItem("AG001").setValue(response.data[0].AG001);
                                                newOrder_roomGridForm.getItem("AG002").setValue(response.data[0].AG002);
                                                newOrder_roomGridForm.getItem("AG003").setValue(response.data[0].AG003);
                                                newOrder_roomGridForm.getItem("AG006").setValue(response.data[0].AG006);
                                                newOrder_roomGridForm.getItem("AG119").setValue(response.data[0].AG119);
                                                newOrder_roomGridForm.getItem("AG120").setValue(response.data[0].AG120);

                                                axios.post(apiServer+"/orders/EditLoadTMPRoomTypeList", {
                                                    itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120
                                                },headers).then((response2) =>{
                                                    if(response2.status === 200){
                                                        if(response2.data){
                                                            newOrder_roomGridForm_AG004 = newOrder_roomGridForm.getItem("AG004").getWidget();
                                                            newOrder_roomGridForm_AG004.data.removeAll();
                                                            newOrder_roomGridForm_AG004.data.parse(response2.data);
                                                            newOrder_roomGridForm.getItem("AG004").setValue([response.data[0].AG004]);
                                                            newOrder_roomGridForm.getItem("AG004").enable();

                                                            axios.post(apiServer+"/orders/EditLoadTMPRoomList", {
                                                                itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: response.data[0].AG120,itemId5: response.data[0].AG004
                                                            },headers).then((response3) =>{
                                                                if(response3.status === 200){
                                                                    if(response3.data){
                                                                        newOrder_roomGridForm_AG005 = newOrder_roomGridForm.getItem("AG005").getWidget();
                                                                        newOrder_roomGridForm_AG005.data.removeAll();
                                                                        newOrder_roomGridForm_AG005.data.parse(response3.data);
                                                                        newOrder_roomGridForm.getItem("AG005").setValue([response.data[0].AG005]);
                                                                        newOrder_roomGridForm.getItem("AG005").enable();

                                                                        setTimeout(()=>{
                                                                            newOrder_roomGridForm.getItem("AG127").setValue(response.data[0].AG127);
                                                                            newOrder_roomGridForm.getItem("AG116").setValue(response.data[0].AG116);
                                                                            newOrder_roomGridForm.getItem("AG127").enable();
                                                                            newOrder_roomGridForm.getItem("AG116").enable();
                                                                            if(new Date(newOrder_roomGridForm.getItem("AG120").getValue()) == new Date(newOrder_roomGridForm.getItem("AG119").getValue())){
                                                                                dayString = 1;
                                                                            }else{
                                                                                dayString = DateDiff(getDate(newOrder_roomGridForm.getItem("AG119").getValue()),getDate(newOrder_roomGridForm.getItem("AG120").getValue()));
                                                                            }
                                                                            newOrder_roomGridForm.getItem("total").setValue("共 "+dayString+" 晚，"+(Number(newOrder_roomGridForm.getItem("AG127").getValue())+Number(newOrder_roomGridForm.getItem("AG116").getValue()))+"元");
                                                                            newOrder_roomGridForm.getItem("AG007").setValue([response.data[0].AG008]);
                                                                            newOrder_roomGridWindow1.attach(newOrder_roomGridForm);
                                                                            newOrder_roomGridWindow1.show();
                                                                            dhxProgressWindow1.hide();
                                                                            isNeedFirstChangeEvent = true;
                                                                        },1000)
                                                                    }else{
                                                                        DHXMessage({
                                                                            text: "連線異常，請重新整理",
                                                                            icon:"dxi dxi-close",
                                                                            css: "dhx_message--error",
                                                                            expire:5000
                                                                        });
                                                                    }
                                                                }else{
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                }
                                                            })
                                                                .catch((error) => {
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                });
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                    .catch((error) => {
                                                        console.log(error);
                                                    });
                                                break;
                                            case 2:
                                                break;
                                            case 3:
                                                newOrder_restRoomGridForm.setValue(response.data[0]);
                                                axios.get(apiServer+"/orders/loadSaleSourceList2",headers
                                                ).then((response) =>{
                                                    if(response.status === 200){
                                                        if(response.data){
                                                            let newOrder_restRoomGridForm_AG007 = newOrder_restRoomGridForm.getItem("AG007").getWidget();
                                                            if(response.data.length > 1){
                                                                newOrder_restRoomGridForm_AG007.data.removeAll();
                                                                newOrder_restRoomGridForm_AG007.data.parse(response.data);
                                                            }else{
                                                                newOrder_restRoomGridForm_AG007.data.removeAll();
                                                                newOrder_restRoomGridForm_AG007.data.parse([{
                                                                    "id": "0",
                                                                    "value": "---請選擇---",
                                                                }]);
                                                            }
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                    .catch((error) => {
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                                newOrder_restRoomGridForm.getItem("AG001").setValue(response.data[0].AG001);
                                                newOrder_restRoomGridForm.getItem("AG002").setValue(response.data[0].AG002);
                                                newOrder_restRoomGridForm.getItem("AG003").setValue(response.data[0].AG003);
                                                newOrder_restRoomGridForm.getItem("AG119").setValue(response.data[0].AG119);

                                                if(response.data[0].AG132 === 3 || (response.data[0].AG132 === 2 && (response.data[0].AG133 === 1 || response.data[0].AG133 === 2))){
                                                    if(response.data[0].AG133 === 1){
                                                        newOrder_restRoomGridForm.getItem("AA103_1").setValue("1");
                                                        newOrder_restRoomGridForm.getItem("AA104_1").setValue(`${response.data[0].AG134}`);
                                                        newOrder_restRoomGridForm.getItem("AA104_1").enable();
                                                        newOrder_restRoomGridForm.getItem("AA104_2").disable();
                                                    }else{
                                                        newOrder_restRoomGridForm.getItem("AA103_2").setValue("1");
                                                        newOrder_restRoomGridForm.getItem("AA104_2").setValue(`${response.data[0].AG134}`);
                                                        newOrder_restRoomGridForm.getItem("AA104_2").enable();
                                                        newOrder_restRoomGridForm.getItem("AA104_1").disable();
                                                    }
                                                }

                                                axios.post(apiServer+"/orders/EditLoadTMPRestRoomTypeList", {
                                                    itemId1: hotel,itemId2: response.data[0].AG001
                                                },headers).then((response2) =>{
                                                    if(response2){
                                                        newOrder_restRoomGridForm_AG004 = newOrder_restRoomGridForm.getItem("AG004").getWidget();
                                                        newOrder_restRoomGridForm_AG004.data.removeAll();
                                                        newOrder_restRoomGridForm_AG004.data.parse(response2.data);
                                                        newOrder_restRoomGridForm.getItem("AG004").setValue([response.data[0].AG004]);
                                                        newOrder_restRoomGridForm.getItem("AG004").enable();

                                                        axios.post(apiServer+"/orders/EditLoadTMPRestRoomList", {
                                                            itemId1: hotel,itemId2: response.data[0].AG001,itemId3: response.data[0].AG119,itemId4: AA103,itemId5:AA104,itemId6:response.data[0].AG004
                                                        },headers).then((response3) =>{
                                                            if(response3.status === 200){
                                                                if(response3.data){
                                                                    newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                                                                    newOrder_restRoomGridForm_AG005.data.removeAll();
                                                                    newOrder_restRoomGridForm_AG005.data.parse(response3.data);
                                                                    newOrder_restRoomGridForm.getItem("AG005").setValue([response.data[0].AG005]);
                                                                    newOrder_restRoomGridForm.getItem("AG005").enable();

                                                                    // setTimeout(()=>{
                                                                    newOrder_restRoomGridForm.getItem("AG127").setValue(response.data[0].AG127);
                                                                    newOrder_restRoomGridForm.getItem("AG127").enable();

                                                                    newOrder_restRoomGridForm.getItem("total").setValue(response.data[0].price);
                                                                    newOrder_restRoomGridForm.getItem("AG007").setValue([response.data[0].AG008]);

                                                                    newOrder_roomGridWindow1.attach(newOrder_restRoomGridForm);
                                                                    newOrder_roomGridWindow1.show();
                                                                    dhxProgressWindow1.hide();
                                                                    isNeedFirstChangeEventRest = true;
                                                                    // },1000)
                                                                }else{
                                                                    DHXMessage({
                                                                        text: "連線異常，請重新整理",
                                                                        icon:"dxi dxi-close",
                                                                        css: "dhx_message--error",
                                                                        expire:5000
                                                                    });
                                                                }
                                                            }else{
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            }
                                                        })
                                                            .catch((error) => {
                                                                DHXMessage({
                                                                    text: "連線異常，請重新整理",
                                                                    icon:"dxi dxi-close",
                                                                    css: "dhx_message--error",
                                                                    expire:5000
                                                                });
                                                            });
                                                    }

                                                })
                                                    .catch((error) => {
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    });
                                                break;
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });

                            newOrder_roomGridForm.getItem("AG001").setValue(data.row.id)
                            newOrder_roomGridForm.setValue({"AG001":data.row.id,"AG002":orderForm.getItem("AA001").getValue()});

                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "newOrder_roomGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/orders/deleteTMPRoom", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        axios.post(apiServer+"/orders/loadTMPRoomGrid", {
                                            itemId1: TMPId
                                        },headers).then((data) =>{
                                            if(data.status === 200){
                                                if(data.data){
                                                    newOrder_roomGrid.data.removeAll();
                                                    newOrder_roomGrid.data.parse(data.data);

                                                    newOrder_roomGridForm.clear();
                                                    newOrder_roomGridWindow1.hide();
                                                }else{
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        });
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        newOrderWindow1Layout1.getCell("newOrder_roomGrid").attach(newOrder_roomGrid);

        //編輯訂單視窗_產品資訊_房間_新增/編輯房間視窗
        const newOrder_roomGridWindow1 = new DHXWindow({
            title: "新增房間視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 460,
            footer: true,
            css:"newOrder_roomGridWindow1"
        });

        newOrder_roomGridWindow1.footer.data.add({type:"spacer"});
        newOrder_roomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        newOrder_roomGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        newOrder_roomGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(newOrder_purchaserInfoForm.getItem("AA138").getValue() == 1){
                        if(newOrder_roomGridForm.getItem("AG119").getValue() === ""){
                            DHXMessage({
                                text: "請選擇入住日期",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_roomGridForm.getItem("AG120").getValue() === ""){
                            DHXMessage({
                                text: "請選擇退房日期",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(Date.parse(newOrder_roomGridForm.getItem("AG119").getValue()).valueOf() > Date.parse(newOrder_roomGridForm.getItem("AG120").getValue()).valueOf()){
                            DHXMessage({
                                text: "入住日期必須早於退房日期",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_roomGridForm.getItem("AG004").getValue() === "0" || newOrder_roomGridForm.getItem("AG004").getValue() === ""){
                            DHXMessage({
                                text: "請選擇房型",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_roomGridForm.getItem("AG005").getValue() === "0" || newOrder_roomGridForm.getItem("AG005").getValue() === ""){
                            DHXMessage({
                                text: "請選擇房間名稱",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_roomGridForm.getItem("AG127").getValue() === ""){
                            DHXMessage({
                                text: "請填入金額",
                                icon:"dxi dxi-close",
                                css: "dhx_message--",
                                expire:5000
                            });
                            break;
                        }
                        console.log(newOrder_roomGridForm.getItem("AG004").getValue(),newOrder_roomGridForm.getItem("AG004").getValue())

                        axios.post(apiServer+"/orders/updateTMPRoomFormData",
                            newOrder_roomGridForm.getValue(),headers
                        ).then((response) =>{
                            if(response.data[0].result){
                                axios.post(apiServer+"/orders/loadTMPRoomGrid", {
                                    itemId1: TMPId
                                },headers).then((data) =>{
                                    newOrder_roomGrid.data.parse(data.data);
                                });

                                newOrder_roomGridForm.clear();
                                newOrder_roomGridWindow1.hide();
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }else if(newOrder_purchaserInfoForm.getItem("AA138").getValue() == 3){
                        if(newOrder_restRoomGridForm.getItem("AG119").getValue() === ""){
                            DHXMessage({
                                text: "請選擇入住日期",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_restRoomGridForm.getItem("AA103_1").getValue() === "" && newOrder_restRoomGridForm.getItem("AA103_2").getValue() === "" ){
                            DHXMessage({
                                text: "請設定分鐘或小時",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_restRoomGridForm.getItem("AG004").getValue() === "0" || newOrder_restRoomGridForm.getItem("AG004").getValue() === ""){
                            DHXMessage({
                                text: "請選擇房型",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_restRoomGridForm.getItem("AG005").getValue() === "0" || newOrder_restRoomGridForm.getItem("AG005").getValue() === ""){
                            DHXMessage({
                                text: "請選擇房間名稱",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(newOrder_restRoomGridForm.getItem("AG127").getValue() === ""){
                            DHXMessage({
                                text: "請填入金額",
                                icon:"dxi dxi-close",
                                css: "dhx_message--",
                                expire:5000
                            });
                            break;
                        }

                        axios.post(apiServer+"/orders/updateTMPRoomFormData",
                            newOrder_restRoomGridForm.getValue(),headers
                        ).then((response) =>{
                            if(response.data[0].result){
                                axios.post(apiServer+"/orders/loadTMPRoomGrid", {
                                    itemId1: TMPId
                                },headers).then((data) =>{
                                    newOrder_roomGrid.data.parse(data.data);
                                });

                                newOrder_roomGridForm.clear();
                                newOrder_roomGridWindow1.hide();
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }
                    break;

                case "WINDOW_FOOT_CLOSE":
                    newOrder_roomGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const newOrder_roomGridForm = new DHXForm(null,{
            rows: newOrder_roomGridFormConfig
        });

        let dayString2 = '',totalString2 = '';
        newOrder_roomGridForm.events.on("change", function(name, value) {
            if(TMPId !== "0"){
                //新增
                switch (name){
                    case "AG119":
                    case "AG120":
                        let newOrder_roomGridForm_AG119 = newOrder_roomGridForm.getItem("AG119").getValue();
                        let newOrder_roomGridForm_AG120 = newOrder_roomGridForm.getItem("AG120").getValue();
                        if(newOrder_roomGridForm_AG119 != '' && newOrder_roomGridForm_AG120 != ''){
                            if(Date.parse(newOrder_roomGridForm_AG119).valueOf() > Date.parse(newOrder_roomGridForm_AG120).valueOf()){

                                newOrder_roomGridForm.getItem("AG004").disable();
                                newOrder_roomGridForm.getItem("AG004").setValue(["0"]);
                                newOrder_roomGridForm.getItem("AG005").disable();
                                newOrder_roomGridForm.getItem("AG005").setValue(["0"]);
                                newOrder_roomGridForm.getItem("AG127").disable();
                                newOrder_roomGridForm.getItem("AG127").setValue("0");
                                newOrder_roomGridForm.getItem("AG116").disable();
                                newOrder_roomGridForm.getItem("AG116").setValue("0");
                                DHXMessage({
                                    text: "入住日期必須早於退房日期",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{

                                if(isNeedFirstChangeEvent){
                                    dhxProgressWindow1.show();
                                    axios.post(apiServer+"/orders/loadRoomTypeList", {
                                        itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5:TMPId
                                    },headers).then((response) =>{
                                        if(response.status === 200){
                                            if(response.data){
                                                newOrder_roomGridForm_AG004 = newOrder_roomGridForm.getItem("AG004").getWidget();
                                                newOrder_roomGridForm_AG004.data.removeAll();
                                                newOrder_roomGridForm_AG004.data.parse(response.data);
                                                newOrder_roomGridForm.getItem("AG004").setValue(["0"]);
                                                dhxProgressWindow1.hide();
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                        .catch((error) => {
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }

                                if(new Date(newOrder_roomGridForm.getItem("AG120").getValue()) == new Date(newOrder_roomGridForm.getItem("AG119").getValue())){
                                    dayString2 = 1;
                                }else{
                                    dayString2 = DateDiff(getDate(newOrder_roomGridForm.getItem("AG119").getValue()),getDate(newOrder_roomGridForm.getItem("AG120").getValue()));
                                }

                                newOrder_roomGridForm.getItem("total").setValue("共 "+dayString2+" 晚");

                                newOrder_roomGridForm.getItem("AG004").enable();
                                newOrder_roomGridForm.getItem("AG004").setValue(["0"]);
                                newOrder_roomGridForm.getItem("AG005").disable();
                                newOrder_roomGridForm.getItem("AG005").setValue(["0"]);
                                newOrder_roomGridForm.getItem("AG127").disable();
                                newOrder_roomGridForm.getItem("AG127").setValue("0");
                                newOrder_roomGridForm.getItem("AG116").disable();
                                newOrder_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{

                            newOrder_roomGridForm.getItem("total").setValue("");
                            newOrder_roomGridForm.getItem("AG004").disable();
                            newOrder_roomGridForm.getItem("AG004").setValue(["0"]);
                            newOrder_roomGridForm.getItem("AG005").disable();
                            newOrder_roomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_roomGridForm.getItem("AG127").disable();
                            newOrder_roomGridForm.getItem("AG127").setValue("0");
                            newOrder_roomGridForm.getItem("AG116").disable();
                            newOrder_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;
                    case "AG004":
                        if(newOrder_roomGridForm.getItem("AG004").getValue() != '0' && newOrder_roomGridForm.getItem("AG004").getValue() != ''){

                            if(isNeedFirstChangeEvent){
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/loadRoomList", {
                                    itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5: newOrder_roomGridForm.getItem("AG004").getValue(),itemId6: TMPId
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_roomGridForm_AG005 = newOrder_roomGridForm.getItem("AG005").getWidget();
                                            newOrder_roomGridForm_AG005.data.removeAll();
                                            newOrder_roomGridForm_AG005.data.parse(response.data);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                            newOrder_roomGridForm.getItem("AG005").enable();
                            newOrder_roomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_roomGridForm.getItem("AG127").disable();
                            newOrder_roomGridForm.getItem("AG127").setValue("0");
                            newOrder_roomGridForm.getItem("AG116").disable();
                            newOrder_roomGridForm.getItem("AG116").setValue("0");
                        }else{
                            newOrder_roomGridForm.getItem("AG005").disable();
                            newOrder_roomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_roomGridForm.getItem("AG127").disable();
                            newOrder_roomGridForm.getItem("AG127").setValue("0");
                            newOrder_roomGridForm.getItem("AG116").disable();
                            newOrder_roomGridForm.getItem("AG116").setValue("0");
                        }
                        break;
                    case "AG005":
                        if(newOrder_roomGridForm.getItem("AG005").getValue() != '0' && newOrder_roomGridForm.getItem("AG005").getValue() != ''){
                            if(isNeedFirstChangeEvent){
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/loadRoomPrices", {
                                    itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5: newOrder_roomGridForm.getItem("AG004").getValue(),itemId6: newOrder_roomGridForm.getItem("AG005").getValue()
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_roomGridForm.getItem("AG127").setValue(response.data);
                                            newOrder_roomGridForm.getItem("AG127").enable();
                                            newOrder_roomGridForm.getItem("AG116").enable();
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{
                        }
                        break;
                    case "AG116":
                    case "AG127":
                        newOrder_roomGridForm.getItem("total").setValue("共 "+dayString2+" 晚，"+(Number(newOrder_roomGridForm.getItem("AG127").getValue())+Number(newOrder_roomGridForm.getItem("AG116").getValue()))+"元");
                        break;
                }
            }else{
                //修改
                switch (name){
                    case "AG119":
                    case "AG120":
                        if(isNeedFirstChangeEvent){
                            if(newOrder_roomGridForm.getItem("AG119").getValue() != '' && newOrder_roomGridForm.getItem("AG120").getValue() != ''){
                                if(newOrder_roomGridForm.getItem("AG119").getValue() == newOrder_roomGridForm.getItem("AG120").getValue()){
                                    DHXMessage({
                                        text: "入住與退房日期必須不同天",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    axios.post(apiServer+"/orders/loadRoomTypeList", {
                                        itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5:TMPId
                                    },headers).then((response) =>{
                                        newOrder_roomGridForm_AG004 = newOrder_roomGridForm.getItem("AG004").getWidget();
                                        newOrder_roomGridForm_AG004.data.removeAll();
                                        newOrder_roomGridForm_AG004.data.parse(response.data);
                                        newOrder_roomGridForm.getItem("AG004").setValue(["0"]);
                                    })
                                        .catch((error) => {
                                            console.log(error);
                                        });

                                    if(new Date(newOrder_roomGridForm.getItem("AG120").getValue()) == new Date(newOrder_roomGridForm.getItem("AG119").getValue())){
                                        dayString2 = 1;
                                    }else{
                                        dayString2 = DateDiff(getDate(newOrder_roomGridForm.getItem("AG119").getValue()),getDate(newOrder_roomGridForm.getItem("AG120").getValue()));
                                    }

                                    newOrder_roomGridForm.getItem("total").setValue("共 "+dayString2+" 晚");

                                    newOrder_roomGridForm.getItem("AG004").enable();
                                    newOrder_roomGridForm.getItem("AG004").setValue(["0"]);
                                    newOrder_roomGridForm.getItem("AG005").disable();
                                    newOrder_roomGridForm.getItem("AG005").setValue(["0"]);
                                    newOrder_roomGridForm.getItem("AG127").disable();
                                    newOrder_roomGridForm.getItem("AG127").setValue("0");
                                    newOrder_roomGridForm.getItem("AG116").disable();
                                    newOrder_roomGridForm.getItem("AG116").setValue("0");
                                }
                            }else{

                                newOrder_roomGridForm.getItem("total").setValue("");
                                newOrder_roomGridForm.getItem("AG004").disable();
                                newOrder_roomGridForm.getItem("AG004").setValue(["0"]);
                                newOrder_roomGridForm.getItem("AG005").disable();
                                newOrder_roomGridForm.getItem("AG005").setValue(["0"]);
                                newOrder_roomGridForm.getItem("AG127").disable();
                                newOrder_roomGridForm.getItem("AG127").setValue("0");
                                newOrder_roomGridForm.getItem("AG116").disable();
                                newOrder_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{

                        }
                        break;
                    case "AG004":
                        if(isNeedFirstChangeEvent){
                            if(newOrder_roomGridForm.getItem("AG004").getValue() != '0' && newOrder_roomGridForm.getItem("AG004").getValue() != ''){
                                axios.post(apiServer+"/orders/loadRoomList", {
                                    itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5: newOrder_roomGridForm.getItem("AG004").getValue(),itemId6: TMPId
                                },headers).then((response) =>{
                                    newOrder_roomGridForm_AG005 = newOrder_roomGridForm.getItem("AG005").getWidget();
                                    newOrder_roomGridForm_AG005.data.removeAll();
                                    newOrder_roomGridForm_AG005.data.parse(response.data);
                                })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                                newOrder_roomGridForm.getItem("AG005").enable();
                                newOrder_roomGridForm.getItem("AG005").setValue(["0"]);
                                newOrder_roomGridForm.getItem("AG127").disable();
                                newOrder_roomGridForm.getItem("AG127").setValue("0");
                                newOrder_roomGridForm.getItem("AG116").disable();
                                newOrder_roomGridForm.getItem("AG116").setValue("0");
                            }else{
                                newOrder_roomGridForm.getItem("AG005").disable();
                                newOrder_roomGridForm.getItem("AG005").setValue(["0"]);
                                newOrder_roomGridForm.getItem("AG127").disable();
                                newOrder_roomGridForm.getItem("AG127").setValue("0");
                                newOrder_roomGridForm.getItem("AG116").disable();
                                newOrder_roomGridForm.getItem("AG116").setValue("0");
                            }
                        }else{

                        }

                        break;
                    case "AG005":
                        if(isNeedFirstChangeEvent){
                            if(newOrder_roomGridForm.getItem("AG005").getValue() != '0' && newOrder_roomGridForm.getItem("AG005").getValue() != ''){
                                axios.post(apiServer+"/orders/loadRoomPrices", {
                                    itemId1: hotel,itemId2: newOrder_roomGridForm.getItem("AG001").getValue(),itemId3: newOrder_roomGridForm.getItem("AG119").getValue(),itemId4: newOrder_roomGridForm.getItem("AG120").getValue(),itemId5: newOrder_roomGridForm.getItem("AG004").getValue(),itemId6: newOrder_roomGridForm.getItem("AG005").getValue()
                                },headers).then((response) =>{
                                    newOrder_roomGridForm.getItem("AG127").setValue(response.data);
                                })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                                newOrder_roomGridForm.getItem("AG127").enable();
                                newOrder_roomGridForm.getItem("AG116").enable();
                            }else{
                            }
                        }else{

                        }

                        break;
                    case "AG116":
                    case "AG127":
                        if(isNeedFirstChangeEvent){
                            newOrder_roomGridForm.getItem("total").setValue("共 "+dayString2+" 晚，"+(Number(newOrder_roomGridForm.getItem("AG127").getValue())+Number(newOrder_roomGridForm.getItem("AG116").getValue()))+"元");
                        }else{

                        }
                        break;
                }
            }
        });


        let newOrder_roomGridFormCheckInDate = newOrder_roomGridForm.getItem("AG119").getWidget();
        let newOrder_roomGridFormCheckOutDate = newOrder_roomGridForm.getItem("AG120").getWidget();

        newOrder_roomGridFormCheckInDate.link(newOrder_roomGridFormCheckOutDate)

        newOrder_roomGridWindow1.attach(newOrder_roomGridForm);

        const newOrder_restRoomGridForm = new DHXForm(null,{
            rows: newOrder_restRoomGridFormConfig
        });

        newOrder_restRoomGridForm.events.on("change", function(name, value) {
            if(TMPId !== "0"){
                //新增
                switch (name){
                    case "AG119":
                        newOrder_restRoomGridForm.getItem("AG004").enable();
                        newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG007").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");

                        if(isNeedFirstChangeEventRest){
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/orders/loadRestRoomTypeList", {
                                itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:TMPId
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        dhxProgressWindow1.hide();
                                        newOrder_restRoomGridForm_AG004 = newOrder_restRoomGridForm.getItem("AG004").getWidget();
                                        newOrder_restRoomGridForm_AG004.data.removeAll();
                                        newOrder_restRoomGridForm_AG004.data.parse(response.data);
                                        newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                        break;
                    case "AA103_1":
                        if(value == ""){
                            restTypeText = '';
                            newOrder_restRoomGridForm.getItem("AA104_1").disable();
                        }else{
                            restTypeText = '分鐘';
                            newOrder_restRoomGridForm.getItem("AA104_1").enable();
                            newOrder_restRoomGridForm.getItem("AA104_2").disable();
                            newOrder_restRoomGridForm.getItem("AA103_2").clear();
                        }

                        if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                            newOrder_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                        }
                        newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA103_2":
                        if(value == ""){
                            restTypeText = '';
                            newOrder_restRoomGridForm.getItem("AA104_2").disable();
                        }else{
                            restTypeText = '小時';
                            newOrder_restRoomGridForm.getItem("AA104_1").disable();
                            newOrder_restRoomGridForm.getItem("AA104_2").enable();
                            newOrder_restRoomGridForm.getItem("AA103_1").clear();
                        }

                        if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                            newOrder_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                        }
                        newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA104_1":
                        if(value == ""){
                        }else{

                        }

                        if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                            newOrder_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                        }
                        newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AA104_2":
                        if(value == ""){
                            newOrder_restRoomGridForm.getItem("AA104_2").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AA104_1").disable();
                            newOrder_restRoomGridForm.getItem("AA104_2").enable();
                            newOrder_restRoomGridForm.getItem("AA103_1").clear();
                        }

                        if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                            newOrder_restRoomGridForm.getItem("AG004").disable();
                        }else{
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                        }
                        newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG005").disable();
                        newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                        newOrder_restRoomGridForm.getItem("AG127").disable();
                        newOrder_restRoomGridForm.getItem("AG127").setValue("");
                        newOrder_restRoomGridForm.getItem("total").setValue("");
                        break;
                    case "AG004":
                        if(value == "0" || value == ""){
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{
                            newOrder_restRoomGridForm.getItem("AG005").enable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");

                            if(newOrder_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }

                            if(isNeedFirstChangeEventRest){
                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/loadRestRoomList", {
                                    itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:value,itemId7:TMPId
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                                            newOrder_restRoomGridForm_AG005.data.removeAll();
                                            newOrder_restRoomGridForm_AG005.data.parse(response.data);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }
                        break;
                    case "AG005":
                        if(value == "0" || value == ""){

                        }else{

                            if(newOrder_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                AA103 = 1;
                                AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                                restTypeText = '分鐘';
                            }else{
                                AA103 = 2;
                                AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                                restTypeText = '小時';
                            }

                            if(isNeedFirstChangeEventRest){
                                dhxProgressWindow1.show();
                                let newOrder_restRoomGridForm_AG004 = newOrder_restRoomGridForm.getItem("AG004").getValue();
                                axios.post(apiServer+"/orders/loadRestRoomPrices", {
                                    itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:newOrder_restRoomGridForm_AG004,itemId7:value
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_restRoomGridForm.getItem("AG127").setValue(response.data);

                                            newOrder_restRoomGridForm.getItem("AG127").enable();

                                            newOrder_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${response.data} 元`);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }

                        }
                        break;
                    case "AG127":
                        if(newOrder_restRoomGridForm.getItem("AG005").getValue() == "0"){
                            price = Number(newOrder_roomGridForm.getItem("AG127").getValue());

                            newOrder_restRoomGridForm.getItem("AG127").setValue("0");
                            // newOrder_restRoomGridForm.getItem("AG116").setValue("0");
                            newOrder_restRoomGridForm.getItem("total").setValue("");

                            newOrder_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${value} 元`);
                        }else{


                        }
                        break;
                }
            }else{
                //修改
                switch (name){
                    case "AG119":
                        if(isNeedFirstChangeEventRest){
                            newOrder_restRoomGridForm.getItem("AG004").enable();
                            newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }
                        break;
                    case "AA103_1":
                        if(isNeedFirstChangeEventRest){
                            AA103 = 1;
                            AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                            if(value == ""){
                                restTypeText = '';
                                newOrder_restRoomGridForm.getItem("AA104_1").disable();
                            }else{
                                restTypeText = '分鐘';
                                newOrder_restRoomGridForm.getItem("AA104_1").enable();
                                newOrder_restRoomGridForm.getItem("AA104_2").disable();
                                newOrder_restRoomGridForm.getItem("AA103_2").clear();
                            }

                            if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                                newOrder_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AG004").enable();
                            }
                            newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }

                        break;
                    case "AA103_2":
                        if(isNeedFirstChangeEventRest){
                            AA103 = 2;
                            AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                            if(value == ""){
                                restTypeText = '';
                                newOrder_restRoomGridForm.getItem("AA104_2").disable();
                            }else{
                                restTypeText = '小時';
                                newOrder_restRoomGridForm.getItem("AA104_1").disable();
                                newOrder_restRoomGridForm.getItem("AA104_2").enable();
                                newOrder_restRoomGridForm.getItem("AA103_1").clear();
                            }

                            if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                                newOrder_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AG004").enable();
                            }
                            newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }

                        break;
                    case "AA104_1":
                        if(isNeedFirstChangeEventRest){
                            AA103 = 1;
                            AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                            if(value == ""){
                            }else{

                            }

                            if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                                newOrder_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AG004").enable();
                            }
                            newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }

                        break;
                    case "AA104_2":
                        if(isNeedFirstChangeEventRest){
                            AA103 = 2;
                            AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                            if(value == ""){
                                newOrder_restRoomGridForm.getItem("AA104_2").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AA104_1").disable();
                                newOrder_restRoomGridForm.getItem("AA104_2").enable();
                                newOrder_restRoomGridForm.getItem("AA103_1").clear();
                            }

                            if (newOrder_restRoomGridForm.getItem("AG119").getValue() == ""){
                                newOrder_restRoomGridForm.getItem("AG004").disable();
                            }else{
                                newOrder_restRoomGridForm.getItem("AG004").enable();
                            }
                            newOrder_restRoomGridForm.getItem("AG004").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG005").disable();
                            newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                            newOrder_restRoomGridForm.getItem("AG127").disable();
                            newOrder_restRoomGridForm.getItem("AG127").setValue("");
                            newOrder_restRoomGridForm.getItem("total").setValue("");
                        }else{

                        }

                        break;
                    case "AG004":
                        if(isNeedFirstChangeEventRest){
                            if(value == "0" || value == ""){
                                newOrder_restRoomGridForm.getItem("AG005").disable();
                                newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                                newOrder_restRoomGridForm.getItem("AG127").disable();
                                newOrder_restRoomGridForm.getItem("AG127").setValue("");
                                newOrder_restRoomGridForm.getItem("total").setValue("");
                            }else{
                                newOrder_restRoomGridForm.getItem("AG005").enable();
                                newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                                newOrder_restRoomGridForm.getItem("AG127").disable();
                                newOrder_restRoomGridForm.getItem("AG127").setValue("");
                                newOrder_restRoomGridForm.getItem("total").setValue("");

                                if(newOrder_restRoomGridForm.getItem("AA103_1").getValue() == 1){
                                    AA103 = 1;
                                    AA104 = newOrder_restRoomGridForm.getItem("AA104_1").getValue();
                                    restTypeText = '分鐘';
                                }else{
                                    AA103 = 2;
                                    AA104 = newOrder_restRoomGridForm.getItem("AA104_2").getValue();
                                    restTypeText = '小時';
                                }

                                dhxProgressWindow1.show();
                                axios.post(apiServer+"/orders/EditloadRestRoomList", {
                                    itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:value
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_restRoomGridForm_AG005 = newOrder_restRoomGridForm.getItem("AG005").getWidget();
                                            newOrder_restRoomGridForm_AG005.data.removeAll();
                                            newOrder_restRoomGridForm_AG005.data.parse(response.data);
                                            newOrder_restRoomGridForm.getItem("AG005").setValue(["0"]);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{

                        }

                        break;
                    case "AG005":
                        if(isNeedFirstChangeEventRest){
                            if(value == "0" || value == ""){

                            }else{

                                dhxProgressWindow1.show();
                                let newOrder_restRoomGridForm_AG004 = newOrder_restRoomGridForm.getItem("AG004").getValue();

                                axios.post(apiServer+"/orders/loadRestRoomPrices", {
                                    itemId1: hotel,itemId2: newOrder_restRoomGridForm.getItem("AG001").getValue(),itemId3: newOrder_restRoomGridForm.getItem("AG119").getValue(),itemId4:AA103,itemId5:AA104,itemId6:newOrder_restRoomGridForm_AG004,itemId7:value
                                },headers).then((response) =>{
                                    if(response.status === 200){
                                        if(response.data){
                                            newOrder_restRoomGridForm.getItem("AG127").setValue(response.data);

                                            newOrder_restRoomGridForm.getItem("AG127").enable();

                                            newOrder_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${response.data} 元`);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }else{

                        }

                        break;
                    case "AG127":
                        if(isNeedFirstChangeEventRest){
                            if(newOrder_restRoomGridForm.getItem("AG005").getValue() == "0"){
                                price = Number(newOrder_roomGridForm.getItem("AG127").getValue());

                                newOrder_restRoomGridForm.getItem("AG127").setValue("0");
                                // newOrder_restRoomGridForm.getItem("AG116").setValue("0");
                                newOrder_restRoomGridForm.getItem("total").setValue("");

                                newOrder_restRoomGridForm.getItem("total").setValue(`共 ${AA104} ${restTypeText}，${value} 元`);
                            }else{


                            }
                        }else{

                        }

                        break;
                }
            }

        });


        //新增訂單視窗_付款紀錄
        let newOrder_payLogGrid = new DHXGrid(null,{
            columns: newOrder_payLogGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "newOrder_payLogGrid_edit": function (e, data) {
                        if(canEdit){

                            dhxNewOrder_payLogGridWindow1.header.data.update("title", {value: "編輯付款紀錄視窗"});
                            axios.post(apiServer+"/orders/loadTMPpayLogGridFormData", {
                                itemId1: data.row.id
                            },headers).then((response1) =>{
                                if(response1.status === 200) {
                                    newOrder_payLogGridForm.setValue(response1.data[0]);
                                    dhxNewOrder_payLogGridWindow1.show();
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "newOrder_payLogGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/orders/deleteTMPPayLog", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status == 200){
                                    axios.post(apiServer+"/orders/loadTMPPayLogGrid", {
                                        itemId1: TMPId
                                    },headers).then((data) =>{
                                        if(data.status == 200){
                                            newOrder_payLogGrid.data.removeAll();
                                            newOrder_payLogGrid.data.parse(data.data);
                                        }
                                    });

                                    newOrder_payLogGridForm.clear();
                                    // newOrder_roomGridWindow1.hide();
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        const newOrder_payLogGridToolbar = new DHXToolbar(null, {
        });

        newOrder_payLogGridToolbar.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        newOrder_payLogGridForm.clear();
                        dhxNewOrder_payLogGridWindow1.header.data.update("title", {value: "新增付款紀錄視窗"});
                        newOrder_payLogGridForm.setValue({"AH001":"0","AH002":orderForm.getItem("AA001").getValue(),"AH003":hotel,"AH005":TMPId});

                        axios.get(apiServer+"/orders/loadPaymentMethodList", headers)
                            .then((response) =>{
                                if(response.status == 200){
                                    newOrder_payLogGridForm.getItem("AH004").setOptions(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        dhxNewOrder_payLogGridWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    break;
                default:
                    break;
            }
        });

        newOrder_payLogGridToolbar.data.parse(JSON.parse(JSON.stringify(newOrder_payLogGridToolbarConfig)));

        newOrderWindow1Layout1.getCell("newOrder_payLogGridToolbar").attach(newOrder_payLogGridToolbar);
        newOrderWindow1Layout1.getCell("newOrder_payLogGrid").attach(newOrder_payLogGrid);

        //新增訂單視窗_付款紀錄_新增/編輯視窗
        const dhxNewOrder_payLogGridWindow1 = new DHXWindow({
            title: "新增付款紀錄視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 430,
            footer: true,
            css:"dhxNewOrder_payLogGridWindow1"
        });

        dhxNewOrder_payLogGridWindow1.footer.data.add({type:"spacer"});
        dhxNewOrder_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxNewOrder_payLogGridWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxNewOrder_payLogGridWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(newOrder_payLogGridForm.getItem("AH004").getValue() === "" || newOrder_payLogGridForm.getItem("AH004").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇付款方式",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(newOrder_payLogGridForm.getItem("AH100").getValue() === ""){
                        DHXMessage({
                            text: "請填入付款金額",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/orders/updateTMPPayLogFormData",
                        newOrder_payLogGridForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            axios.post(apiServer+"/orders/loadTMPPayLogGrid", {
                                itemId1: TMPId
                            },headers).then((data) =>{
                                newOrder_payLogGrid.data.removeAll();
                                newOrder_payLogGrid.data.parse(data.data);

                                dhxNewOrder_payLogGridWindow1.hide();
                                newOrder_payLogGridForm.clear();
                                newOrder_payLogGridForm.clear("validation");
                            });
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxNewOrder_payLogGridWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const newOrder_payLogGridForm = new DHXForm(null,{
            rows: newOrder_payLogGridFormConfig
        });

        dhxNewOrder_payLogGridWindow1.attach(newOrder_payLogGridForm);


        const dhxOrderExportExcelWindow1 = new DHXWindow({

            title: "匯出Excel視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 520,
            width: 800,
            footer: true,
            css: "dhxOrderExportExcelWindow1",
        });

        dhxOrderExportExcelWindow1.footer.data.add({type:"spacer"});
        dhxOrderExportExcelWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClose)));
        dhxOrderExportExcelWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootClear)));
        dhxOrderExportExcelWindow1.footer.data.add( JSON.parse( JSON.stringify(windowFootAccept)));

        dhxOrderExportExcelWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":

                    break;
                case "WINDOW_FOOT_ACCEPT":

                    break;
                case "WINDOW_FOOT_CLOSE":

                    break;
                default:
                    break;
            }
        })

        const orderExportExcelGrid = new DHXGrid(null,{
            columns: orderExportExcelGridColumn,
            leftSplit:2,
            autoWidth: true,
            resizable: true,
            rowHeight: 120,
            eventHandlers: {
                onclick: {
                }
            }
        });

        const orderExportExcelLayout = new DHXLayout(null,{
            rows:[
                {id: "orderExportExcelGrid"},
            ]
        });

        dhxOrderExportExcelWindow1.attach(orderExportExcelLayout);
        orderExportExcelLayout.getCell("orderExportExcelGrid").attach(orderExportExcelGrid);

        return () =>{
            dhxOrderGridLayout && dhxOrderGridLayout.destructor();
        }

    },[canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
            <MDBox py={2}>
                <MDBox mb={2}>
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <h4 className={"gridTitle"}>未付款</h4>
                                <MDBox py={1} px={1}>
                                    <div ref={DHXOrderGridLayout}></div>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default UnpaidOrderBasicSetting;
