let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

let dateTime = new Date();
dateTime = dateTime.setDate(dateTime.getDate()+1);
dateTime = new Date(dateTime);

export const passwordGridFormConfig = [
    {type: "input", name: "AD001", hidden: true, value: "0"},
    {type: "input", name: "AD002", hidden: true, value: "0"},
    {
        type: "input",
        label: "密碼",
        placeholder: "自動產生6位數字密碼",
        readOnly: true,
        name: "AD100"
    },
    {
        type: "input",
        label: "*使用者：",
        name: "AD101"
    },
    {
        type: "datepicker",
        label: "*密碼有效開始時間：",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d %H:%i",
        valueFormat:"string",
        mark: function (date) {
            if (getDate(date) === getDate(new Date)) {
                return "highlight-date";
            }
        },
        name: "AD103",
    },
    {
        type: "datepicker",
        label: "*密碼有效結束時間：",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d %H:%i",
        valueFormat:"string",
        mark: function (date) {
            if (getDate(date) === getDate(new Date)) {
                return "highlight-date";
            }
        },
        name: "AD104",
    },
];