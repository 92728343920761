export const productInfo_invoiceGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:80,
        // template: function (e) {
        //     return "<a class='edit-button'><i title='edit' class='dxi dxi-pencil orderInfo_purchaserGrid_edit' style='color: #466fc4'>"+e+"</i></a>"
        // }
    },
    { width:50, id: "item_no", header: [{ text: "項次" }], htmlEnable: true },
    { width:120, id: "create_date", header: [{ text: "開立日期" }] },
    { width:120, id: "invoice_number", header: [{ text: "發票號碼" }] },
    { width:120, id: "price", header: [{ text: "發票金額" }] },
    { width:120, id: "invoice_taxNumber", header: [{ text: "買受人統編" }] },
    { width:120, id: "invoice_name", header: [{ text: "買受人名稱" }] },
    { width:100, id: "invoice_status", header: [{ text: "發票狀態" }] },
    { width:100, id: "invoice_updateStatus", header: [{ text: "上傳狀態" }] },
    { width:100, id: "isDiscount", header: [{ text: "是否折讓" }] },
    { width:250, id: "email", header: [{ text: "接收Email信箱" }] },
    { width:120, id: "created_by", header: [{ text: "建立人員" }] },
    { width:150, id: "created_at", header: [{ text: "建立時間" }] },
    { width:120, id: "updated_by", header: [{ text: "更新人員" }] },
    { width:150, id: "updated_at", header: [{ text: "更新時間" }] },
];