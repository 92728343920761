/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useState, useEffect, useRef} from "react";

// react-router components
import {useLocation, Link, useNavigate} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";
import NotificationItem from "examples/Items/NotificationItem";
import * as icon from '@fortawesome/free-solid-svg-icons';

// Material Dashboard 2 PRO React examples
// import Breadcrumbs from "examples/Breadcrumbs";
// import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "pages/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import {Breadcrumbs} from "@mui/material";
import {faHotel, faRightFromBracket} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";

import {
  Combobox as DHXCombobox
} from 'dhx-suite';

import "./style.css";

import api_url from "../../../context/UrlContext";

import {
  Window as DHXWindow,
} from "dhx-suite";

let dhxNoticeWindow1 ='';

function DashboardNavbar({ absolute, light, isMini, onHotelChange }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [notiContent, setNotiContent] = useState([]);
  const route = useLocation().pathname.split("/").slice(1);

  const apiServer =  api_url;
  const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
  const userNameRef = useRef();
  const navigate = useNavigate();
  const hotelSelectRef = useRef();


  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    axios.post(apiServer+"/userProfileSet/loadUserInfo", {
      itemId1:JSON.parse(localStorage.getItem("digital_token"))
    },headers)
        .then((response) =>{
          if(response.status == 200){
            userNameRef.current.innerHTML = response.data[0].userName;
            axios.post(apiServer+"/userProfileSet/loadHotelList", {
              itemId1:response.data[0].userId
            },headers)
                .then((response2) =>{
                    if(response2.status == 200){
                        hotelCombobox.data.removeAll();
                        hotelCombobox.data.parse(response2.data);
                        hotelCombobox.setValue(response.data[0].defaultHotel)
                    }
                })
                .catch((error) => {
                });
          }
        })
        .catch((error) => {
        });


    const hotelCombobox = new DHXCombobox(hotelSelectRef.current,{
      readOnly:true,
      css: "hotelCombobox"
    });

    hotelCombobox.events.on("change", function(id,e){
      onHotelChange(id)
      axios.post(apiServer+"/userProfileSet/updateHotelChange", {
        itemId1:id
      },headers)
          .then((response1) =>{

          })
          .catch((error) => {
          });

      axios.post(apiServer+"/accAndDev/loadDoorLockNotice", {
        itemId1:id
      },headers)
          .then((response1) =>{
              if(response1.status === 200){
                  if(response1.data){
                      setNotiContent(response1.data);
                      let stmlStr = '';
                      // dhxNoticeWindow1.attachHTML(`<div style="color: #000000B3;font-size: 1.2em;font-weight: bold;"><i class="fa-solid fa-battery-quarter"></i>&nbsp;${response1.data[0].context}</div>`);

                      response1.data.map((item)=>{
                          stmlStr += `<div style="color: #000000B3;font-size: 1.2em;font-weight: bold;"><i class="fa-solid fa-battery-quarter"></i>&nbsp;${item.context}</div>`;
                      })

                      dhxNoticeWindow1.attachHTML(stmlStr);

                  }
              }
          })
          .catch((error) => {
          });

        // axios.get(apiServer+"/accAndDev/type2",headers)
        //     .then((response) =>{
        //         console.log(response);
        //         // dhxDataview.data.removeAll();
        //         // dhxDataview.data.parse(response.data);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    });

    dhxNoticeWindow1 = new DHXWindow({
        title: "通知視窗",
        modal: true,
        resizable: true,
        movable: true,
        closable:true,
        viewportOverflow:true,
        height: 150,
        width:400,
        css: "dhxNoticeWindow1"
    });

    return () => {
      hotelCombobox.destructor();
      // clearInterval(timeoutID);
    };
  }, []);

  const logoutHandler = () => {

    console.log("logout");
    axios.post(apiServer+"/logout", {
      itemId1:JSON.parse(localStorage.getItem("digital_token"))
    },headers)
        .then((response) =>{
          navigate("/login");
        })
        .catch((error) => {
        });
  }

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleOpenMenu = (event) =>{
        dhxNoticeWindow1.show();
    };
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {/*NotificationItem x 3*/}
      {/*{*/}
      {/*  // notiContent.length == 0?(*/}
      {/*  //    */}
      {/*  // ):(*/}
      {/*      notiContent.map((item,index)=>(*/}
      {/*          <NotificationItem key={index} icon={<FontAwesomeIcon icon={icon[item.icon]} />} title={item.context} />*/}
      {/*      ))*/}
      {/*  // )*/}
      {/*}*/}
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          {/*<Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />*/}
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {
              notiContent.length != 0 && <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
              >
                <MDBadge badgeContent={notiContent.length} color="error" size="xs" circular>
                  {/*<Icon sx={iconsStyle}>notifications</Icon>*/}
                    <FontAwesomeIcon icon={icon["faBatteryQuarter"]} />
                </MDBadge>

                {/*{renderMenu()}*/}
              </IconButton>

            }
            &nbsp;
            飯店切換：
            <div style={{"width":"200px"}} ref={hotelSelectRef}></div>&nbsp;&nbsp;
            <span id="userName" ref={userNameRef}></span>，你好&nbsp;&nbsp;
            <button
                onClick={logoutHandler}
                id="logout"
                style={{width:"5em",fontSize:"1rem",borderRadius:"1em",border:"0.1rem solid red",backgroundColor:"#ffd1d1",cursor:"pointer"}}>
                  <FontAwesomeIcon icon={faRightFromBracket} /> 登出
            </button>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;