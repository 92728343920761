import api_url from "../../../../context/UrlContext";

let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

let dateTime = new Date();
dateTime = dateTime.setDate(dateTime.getDate()+1);
dateTime = new Date(dateTime);

export const faultReportFormConfig = [
    {type: "input", name: "AA001", hidden: true, value: "0"},
    {type: "input", name: "AA002", hidden: true, value: "0"},
    {type: "input", name: "AA004", hidden: true, value: "0"},
    {type: "input", name: "AA042", hidden: true, value: "0"},
    {
        type: "text",
        name: "note",
        value: "此功能會扣掉庫存，請謹慎選取",
        css: "faultReportForm_tip",
    },
    {
        cols:[
            {
                type: "datepicker",
                label: "*通報日期：",
                dateFormat: "%Y-%m-%d",
                width:"33%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin",
                name: "AA103",
                value:Date.now(),
            },
            {
                type: "datepicker",
                label: "*鎖定開始日期：",
                dateFormat: "%Y-%m-%d 00:00:00",
                width:"33%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin",
                name: "AA104",
            },
            {
                type: "datepicker",
                label: "*鎖定結束日期：",
                dateFormat: "%Y-%m-%d 23:59:59",
                width:"33%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin",
                name: "AA105",
            },
        ],
    },
    {
        type: "radioGroup",
        label: "*保留對象：",
        options: {
            cols: [
                {
                    type: "radioButton",
                    text: "主要產品",
                    width: "49.7%",
                    value: 1,
                },
                {
                    type: "radioButton",
                    text: "加價產品",
                    width: "49.7%",
                    value: 2
                },
            ]
        },
        disabled: true,
        name: "AA100",
    },
    {
        cols:[
            {
                width: "49.7%",
                rows:[
                    {
                        type: "input",
                        css:"rightMargin",
                        disabled:true,
                        placeholder: "請點我選擇",
                        readOnly: true,
                        name: "mainPrd",
                    },
                    {
                        type: "text",
                        value: "(點擊輸入框進入選擇畫面)",
                        // width:"280px",
                        css: "faultReportForm_tip",
                        name: "tip",
                    },
                ],
            },
            {
                width: "49.7%",
                rows:[
                    {
                        type: "select",
                        disabled:true,
                        options: [
                            {
                                value: "0",
                                content: "---請選擇---",
                            },
                        ],
                        name: "AA005",
                    },
                    {
                        cols:[
                            {
                                type: "text",
                                label: "庫存：",
                                labelPosition: "left",
                                value: "(點擊輸入框進入選擇畫面)",
                                disabled:true,
                                width: "50%",
                                name: "remained",
                            },
                            {
                                type: "input",
                                // css:"rightMargin",
                                label: "數量：",
                                value: "0",
                                inputType:"number",
                                min:0,
                                labelPosition: "left",
                                disabled:true,
                                width: "50%",
                                name: "AA108",
                            },
                        ],
                    }
                ],
            },

        ],
    },
    {
        type: "select",
        label: "*故障原因：",
        options: [
            {
                value: "0",
                content: "---請選擇---",
                disabled: true
            },
        ],
        name: "AA006",
    },
    {
        type: "textarea",
        label: "備註：",
        value: "",
        height: 100,
        name: "AA106",
    },
    {
        type: "simplevault",
        label: "上傳照片",
        target: api_url+"/faultReport/uploadProof",
        updateFromResponse:false,
        name: "proofPhoto",
        id:"proof"
    },
    {
        cols:[
            {
                type: "datepicker",
                label: "*處理完成日期：",
                dateFormat: "%Y-%m-%d",
                width:"49.7%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin",
                name: "AA109",
                value:Date.now(),
                disabled:true
            },
            {
                type: "combo",
                multiselection: false,
                label: "*維修處理人員：",
                labelPosition: "top",
                css:"rightMargin",
                data: [
                    { value: "---請選擇---", id: "0" },
                ],
                value: "0",
                width:"49.7%",
                readOnly:true,
                name: "AA007",
                disabled:true
            },
        ]
    },
    {
        type: "textarea",
        label: "*處理完成備註：",
        value: "",
        height: 100,
        name: "AA110",
        disabled:true
    },
]