export const hotelRoomNumberSetFormConfig = [
    {type: "input", name: "BB001", hidden: true, value: "0"},
    {type: "input", name: "BB002", hidden: true, value: "0"},
    {
        cols:[
            {type: "text", label: "飯店名稱：", name: "hotelName",value:"",width:"49.7%", css:"rightMargin"},
            // {type: "text", label: "房型：", name: "roomType",value:"",width:"49.7%"},
            {
                type: "select",
                label: "*房型名稱：",
                value: "0",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                    },
                ],
                width: "49.7%",
                css:"rightMargin",
                name: "BB003",
            },
        ]
    },
    {
        cols:[

            {type: "input", label: "*房間名稱：", placeholder: "", name: "BB101",width:"49.7%", css:"rightMargin"},
            {type: "input", label: "排列序號：", placeholder: "", name: "BB104",width:"49.7%", css:"rightMargin", inputType: "number"},
        ]
    },
    {
        cols:[
            {
                type: "radioGroup",
                label: "*狀態：",
                value: "1",
                options: {
                    cols: [
                        {
                            type: "radioButton",
                            text: "啟用",
                            value: "1",
                            checked: true,
                        },
                        {
                            type: "radioButton",
                            text: "停用",
                            value: "0"
                        },
                    ]
                },
                name: "BB022",
                width:"66.7%",
            },
            {
                label: " ",
                width: "33%",
                type: "checkbox",
                text: "保留房",
                name: "BB107",
                css: "noLabel"
            }
        ]
    },

    { type: "textarea", label: "*停用原因：", value: "", placeholder: "", height: 100, disabled: true, name: "BB102" },

];

export const hotelRoomNumberStopFormConfig = [
    {type: "input", name: "BB001", hidden: true, value: "0"},
    {type: "input", name: "BB002", hidden: true, value: "0"},
    {type: "text", value: "確定要停用嗎?", name: "stopFormTitle", css:"stopFormTitle"},
    {type: "text",label: "房間名稱：",labelPosition: "left",name: "BB101",css:"stopFormName"},
    {type: "textarea",label: "停用原因：", value: "",height: 150,name: "BB102"},
];

export const hotelRoomNumberDeleteFormConfig = [
    {type: "input", name: "BB001", hidden: true, value: "0"},
    {type: "input", name: "BB002", hidden: true, value: "0"},
    {type: "text",value: "確定要刪除嗎?",name: "deleteFormTitle",css:"deleteFormTitle"},
    {type: "text",label: "房間名稱：",labelPosition: "left",name: "BB101",css:"deleteFormName"},
    {type: "textarea",label: "刪除原因：",value: "",height: 150,name: "BB103",},
];