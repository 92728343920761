export const orderExportExcelGridColumn = [
    { id: "item_no", width:70, header: [{ text: "項次" }] },
    { width: 120, id: "order_status", header: [{ text: "訂單狀態" }] },
    { width:150, id: "hotel_name", header: [{ text: "飯店名稱" }] },
    { width: 200, id: "order_no", header: [{ text: "訂單編號" }] },
    { width: 150, id: "purchaser_full_name", header: [{ text: "訂購人姓名" }] },
    { width: 260, id: "check_in_out_date", header: [{ text: "入住/退房日期" }] },
    { width: 150, id: "room_status", header: [{ text: "房間狀態" }] },
    { width: 150, id: "room_type", header: [{ text: "房型" }] },
    { width: 150, id: "room_name", header: [{ text: "房間名稱" }] },
    { width: 80, id: "total", header: [{ text: "總金額" }] },
    { width: 80, id: "discount", header: [{ text: "折扣" }] },
    { width: 80, id: "paid", header: [{ text: "已付" }] },
    { width: 80, id: "unpaid", header: [{ text: "未付" }] },
    { width: 150, id: "order_source", header: [{ text: "訂單來源" }] },
    { width: 200, id: "source_order_no", header: [{ text: "訂單來源編號" }] },
    { width: 150, id: "sale_source", header: [{ text: "銷售來源" }] },
    { width: 150, id: "purchaser_tel", header: [{ text: "訂購人電話" }] },
    { width: 150, id: "purchaser_country", header: [{ text: "訂購人國籍" }] },
    { width: 150, id: "country_code", header: [{ text: "國籍代碼" }] },
    { width: 150, id: "order_date", header: [{ text: "訂購日期" }] },
    { width: 150, id: "invoice", header: [{ text: "發票號碼" }] },
    { width: 80, id: "note", header: [{ text: "訂單備註" }] },
    { width: 200, id: "cancel_date", header: [{ text: "取消日期" }] },
    { width: 80, id: "cancel_reason", header: [{ text: "取消原因" }] },
    { width:120, id: "created_by", header: [{ text: "建立人員" }] },
    { width:150, id: "created_at", header: [{ text: "建立時間" }] },
    { width:120, id: "updated_by", header: [{ text: "更新人員" }] },
    { width:150, id: "updated_at", header: [{ text: "更新時間" }] },
];