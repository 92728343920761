export const invoiceReportExportExcelGridColumn = [
    { width:80, id: "item_no", header: [{ text: "項次" }],tooltip: false },
    { width: 130,id: "create_date", header: [{ text: "開立日期" }] },
    { width: 150,id: "invoice_number", header: [ { text: "發票號碼" }] },
    { width: 150,id: "invoice_taxNumber",header: [{ text: "買受人統編" }] },
    { width: 150,id: "invoice_name", header: [{ text: "買受人名稱" }] },
    { width: 200,id: "orderNo",header: [{ text: "訂單編號" }] },
    { width: 250,id: "invoice_status",header: [{ text: "發票狀態" }] },
    { width: 200,id: "invoice_updateStatus",header: [{ text: "上傳狀態" }] },
    { width: 200,id: "isDiscount",header: [{ text: "是否折讓" }] },
    { width: 200,id: "notice",header: [{ text: "發票通知" }] },
]