export const deleteFormConfig = [
    {type: "input", name: "AD001", hidden: true, value: "0"},
    {type: "input", name: "AD002", hidden: true, value: "0"},
    {
        type: "text",
        label: "門鎖密碼：",
        labelPosition: "left",
        value: "",
        name: "AD100",
        css: "deleteFormName"
    },
    {
        type: "text",
        label: "密碼生效日期時間：",
        labelPosition: "left",
        value: "",
        name: "AD103",
        css: "deleteFormName"
    },
    {
        type: "text",
        label: "密碼失效日期時間：",
        labelPosition: "left",
        value: "",
        name: "AD104",
        css: "deleteFormName"
    },
    {
        type: "text",
        label: "門鎖：",
        labelPosition: "left",
        value: "",
        name: "roomNameList",
        css: "deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        value: "",
        height: 150,
        name: "AD106",
    },
];