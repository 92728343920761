import React, {useEffect, useRef, useState} from 'react';
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {CardHeader} from "@mui/material";
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import {
    Tree as DHXTree,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Grid as DHXGrid,
    Window as DHXWindow,
    Form as DHXForm,
    message as DHXMessage,
    Tabbar as DHXTabbar,
} from 'dhx-suite';
import 'dhx-suite/codebase/suite.min.css'
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import axios from "axios";
import {mainProductGridColumn} from "./componentsConfig/mainProductGridColumn";
import {
    globalWindowFootAccept,
    globalWindowFootClear,
    globalWindowFootClose
} from "./componentsConfig/globalWindowConfig";
import {mainProductsSetFormConfig,mainProductsSetStopFormConfig,mainProductsSetDeleteFormConfig} from "./componentsConfig/mainProductForm";
import {roomDeviceSetGridColumn} from "./componentsConfig/roomDeviceSetGridColumn";
import {toolbar2Config} from "./componentsConfig/toolbar2Config";
import {roomDeviceSetFormConfig,roomDeviceStopFormConfig,roomDeviceDeleteFormConfig} from "./componentsConfig/roomDeviceSetFormConfig";
import './style.css';
import Image_error from '../../../assets/images/error.png'
import Image_warning from '../../../assets/images/warning.png'
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";
import Progress from "../../../assets/images/icons8-iphone-spinner.gif";


const MainProductsBasicSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState(false);
    const [canEdit ,setCanEdit] = useState(false);
    const [canDelete ,setCanDelete] = useState(false);
    const [canPrint ,setCanPrint] = useState(false);

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
        hotelsListId = hotel;
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXMainProductsList = useRef(null);
    const DHXHotelsList = useRef(null);
    const DHXMainProductsTab = useRef(null);

    let mainProductListId = "0";
    let hotelsListId = "0";
    let productTypeName = "";
    let hotelName = "";

    useEffect(() => {

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:3},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>資料載入中</span></div>",
            css: "dhxProgressWindow1"
        });

        const mainProductsList = new DHXTree(DHXMainProductsList.current,{
            selection: true,
        });

        mainProductsList.selection.events.on("afterSelect", function(id){
            mainProductListId = id;
            productTypeName = mainProductsList.selection.getItem().value;
            mainProductForm.setValue({"AA030": id});
        });

        axios.get(apiServer+"/products/loadMainProductsList",headers)
            .then((response) =>{
                if(response.status === 200){
                    mainProductsList.data.removeAll();
                    mainProductsList.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const hotelsList = new DHXTree(DHXHotelsList.current,{
            selection: true,
        });

        axios.get(apiServer+"/products/loadHotelList",headers)
            .then((response) =>{
                if(response.status === 200){
                    hotelsList.data.removeAll();
                    hotelsList.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        //飯店房間基本資料
        const mainProductsTab = new DHXTabbar(DHXMainProductsTab.current,{
            views: [
                {tab: "房型設定", id: "mainProductSet"},
                {tab: "房型提供設備設定", id: "roomDeviceSet"},
            ],
        });

        mainProductsTab.setActive("mainProductSet");

        const mainProductsLayout = new DHXLayout(null,{
            rows:[
                {id:"mainProductToolbar", height: "content"},
                {id: "mainProductGrid", height:"600px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {});
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){

                        mainProductForm.clear();

                        mainProductSetWindow.header.data.update("title", {value: "新增房型設定視窗"});
                        mainProductSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });

                        // if(hotelsListId === "0"){
                        //     DHXMessage({
                        //         text: "請先選擇所屬飯店",
                        //         icon:"dxi dxi-close",
                        //         css: "dhx_message--error",
                        //         expire:5000
                        //     });
                        //     break;
                        // }

                        if(mainProductListId === "0"){
                            DHXMessage({
                                text: "請先選擇產品主要分類",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        axios.post(apiServer+"/prices/loadHotelName", {
                            itemId1: hotel,
                        },headers).then((response) =>{
                            if(response.status == 200){
                                // hotelName = response.data[0].hotelName;
                                mainProductForm.getItem("hotelName").setValue(response.data[0].hotelName);
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        let device = mainProductForm.getItem("device").getWidget();

                        axios.get(apiServer+"/products/loadDeviceList",headers)
                            .then((response) =>{
                                if(response.status === 200){
                                    device.data.removeAll();
                                    device.data.parse(response.data);
                                }else{
                                    console.log("Server Error")
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        // mainProductForm.setValue({"AA001": "0", "AA002": hotelsListId, "AA022": "1", "AA030": mainProductListId, "productType": productTypeName, "hotelName": hotelName});
                        mainProductForm.setValue({"AA001": "0", "AA002": hotel, "AA022": "1", "AA030": mainProductListId, "productType": productTypeName, "AA110":999});
                        mainProductSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });

                        mainProductSetWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.post(apiServer+"/products/loadMainProductGrid",{itemId1:hotel},headers)
                        .then((response) =>{
                            if(response.status === 200){
                                mainProductGrid.data.removeAll();
                                mainProductGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    let hotelComboboxValue = document.getElementsByClassName("hotelCombobox")
                    console.log(hotelComboboxValue.value);
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const mainProductGrid = new DHXGrid(null,{
            columns: mainProductGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "mainProductGrid_edit": function (e, data) {
                        if(canEdit){
                            mainProductSetWindow.header.data.update("title", {value: "編輯房型設定視窗"});
                            mainProductSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.post(apiServer+"/products/loadMainProductFormData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                mainProductSetWindow.show();
                                mainProductForm.setValue( response.data[0]);

                                axios.get(apiServer+"/products/loadDeviceList",headers).then((data) =>{
                                    let device = mainProductForm.getItem("device").getWidget();
                                    device.data.removeAll();
                                    device.data.parse(data.data);

                                    mainProductForm.getItem("device").setValue(response.data[0].device);
                                })
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "mainProductGrid_stop": function (e, data) {
                        if(canEdit){
                            mainProductStopForm.clear();
                            mainProductStopForm.getItem("stopFormTitle").setValue("確定要停用嗎?");

                            axios.post(apiServer+"/products/loadMainProductFormData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                mainProductStopForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            mainProductSetWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "mainProductGrid_delete": function (e, data) {
                        if(canDelete){
                            mainProductDeleteForm.clear();
                            mainProductDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/products/loadMainProductFormData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                mainProductDeleteForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            mainProductSetWindow3.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.post(apiServer+"/products/loadMainProductGrid",{itemId1:hotel},headers)
            .then((response) =>{
                if(response.status === 200){
                    mainProductGrid.data.removeAll();
                    mainProductGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        mainProductsLayout.getCell("mainProductToolbar").attach(toolbar1);
        mainProductsLayout.getCell("mainProductGrid").attach(mainProductGrid);
        mainProductsTab.getCell("mainProductSet").attach(mainProductsLayout);

        const mainProductSetWindow = new DHXWindow({
            title: "房型設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 690,
            width: 650,
            footer: true,
            css: "mainProductSetWindow"
        });

        mainProductSetWindow.footer.data.add({type:"spacer"});
        mainProductSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        mainProductSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        mainProductSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        mainProductSetWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    mainProductForm.clear();
                    mainProductForm.setValue({"AA001": "0", "AA002": hotelsListId, "AA022": "1", "AA030": mainProductListId, "productType": productTypeName, "hotelName": hotelName});

                    let device = mainProductForm.getItem("device").getWidget();

                    axios.get(apiServer+"/products/loadDeviceList",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                device.data.removeAll();
                                device.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(mainProductForm.getItem("AA101").getValue() === ""){
                        DHXMessage({
                            text: "請填入房型名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(mainProductForm.getItem("AA109").getValue() === ""){
                        DHXMessage({
                            text: "請填入HotelNabe房型編號",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(mainProductForm.getItem("AA104").getValue() === ""){
                        DHXMessage({
                            text: "請填入人數限制",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(mainProductForm.getItem("AA108").getValue() === ""){
                        DHXMessage({
                            text: "請填入定價",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(mainProductForm.getItem("device").getValue().length === 0){
                        DHXMessage({
                            text: "請選擇設備",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(mainProductForm.getItem("AA022").getValue() === "0"){
                        if(mainProductForm.getItem("AA106").getValue() === ""){
                            DHXMessage({
                                text: "請輸入停用原因",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }
                    };

                    axios.post(apiServer+"/products/checkRoomTypeName",
                        mainProductForm.getValue(),headers
                    ).then((response) =>{
                        console.log(response);
                        if(response.data[0].result){
                            DHXMessage({
                                text: "房型名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            axios.post(apiServer+"/products/checkHotelNabeNo",
                                mainProductForm.getValue(),headers
                            ).then((response) =>{
                                console.log(response);
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: "HotelNabe房型編號重複",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    axios.post(apiServer+"/products/updateMainProduct",
                                        mainProductForm.getValue(),headers
                                    ).then((response) =>{
                                        // console.log(response.data[0]);
                                        // let r =  JSON.parse(response);
                                        if(response.data[0].result){
                                            DHXMessage({
                                                text: response.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--success",
                                                expire:5000
                                            });
                                            mainProductForm.clear();
                                            mainProductForm.setValue({"AA001": "0", "AA030": "0"});

                                            axios.post(apiServer+"/products/loadMainProductGrid",{itemId1:hotel},headers)
                                                .then((response) =>{
                                                    if(response.status === 200){
                                                        mainProductSetWindow.hide();
                                                        mainProductGrid.data.removeAll();
                                                        mainProductGrid.data.parse(response.data);
                                                    }else{
                                                        console.log("Server Error")
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    mainProductForm.clear();
                    mainProductForm.setValue({"AA001": "0", "AA030": "0"});
                    mainProductSetWindow.hide();
                    break;
                default:
                    break;
            }
        });

        const mainProductForm = new DHXForm(null,{
            rows: mainProductsSetFormConfig
        });

        mainProductForm.getItem("AA022").events.on("change", function(value) {
            if(value == "0"){
                mainProductForm.getItem("AA106").enable();
            }else{
                mainProductForm.getItem("AA106").clear();
                mainProductForm.getItem("AA106").disable();
            }
        });

        mainProductSetWindow.attach(mainProductForm);

        const mainProductSetWindow2 = new DHXWindow({
            title: "停用房型設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 650,
            width: 650,
            footer: true,
            css: "mainProductSetWindow2"
        });

        mainProductSetWindow2.footer.data.add({type:"spacer"});
        mainProductSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        mainProductSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        mainProductSetWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(mainProductStopForm.getItem("AA106").getValue() === ""){
                        DHXMessage({
                            text: "請填入停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    axios.post(apiServer+"/products/stopMainProduct",
                        mainProductStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0]){
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            mainProductStopForm.clear();
                            mainProductStopForm.setValue({"AA001": "0"});

                            axios.post(apiServer+"/products/loadMainProductGrid",{itemId1:hotel},headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        mainProductSetWindow2.hide();
                                        mainProductGrid.data.removeAll();
                                        mainProductGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    mainProductStopForm.clear();
                    mainProductStopForm.setValue({"AA001": "0"});
                    mainProductSetWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let mainProductStopFormLayout = new DHXLayout(null,{
            rows:[
                {id: "mainProductStopFormImg",
                    height: 200,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_warning+" /></div>",
                },
                {id: "mainProductStopForm"},
            ]
        });

        const mainProductStopForm = new DHXForm(null,{
            rows: mainProductsSetStopFormConfig
        });

        mainProductSetWindow2.attach(mainProductStopFormLayout);
        mainProductStopFormLayout.getCell("mainProductStopForm").attach(mainProductStopForm);

        const mainProductSetWindow3 = new DHXWindow({
            title: "刪除房型視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 650,
            width: 650,
            footer: true,
            css: "mainProductSetWindow3"
        });

        mainProductSetWindow3.footer.data.add({type:"spacer"});
        mainProductSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        mainProductSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        mainProductSetWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(canDelete){
                    }else{
                        DHXMessage({
                            text: "請先確認擁有刪除的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }

                    if(mainProductDeleteForm.getItem("AA107").getValue() === ""){
                        DHXMessage({
                            text: "請填入刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    axios.post(apiServer+"/products/deleteMainProduct",
                        mainProductDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                if(response.data[0].result === 1){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });
                                    mainProductDeleteForm.clear();
                                    mainProductDeleteForm.setValue({"AA001": "0"});

                                    axios.post(apiServer+"/products/loadMainProductGrid",{itemId1:hotel},headers)
                                        .then((response2) =>{
                                            if(response2.status === 200){
                                                if(response2.data){
                                                    mainProductSetWindow3.hide();
                                                    mainProductGrid.data.removeAll();
                                                    mainProductGrid.data.parse(response2.data);
                                                }else{
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }else if(response.data[0].result === 8){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else if(response.data[0].result === 9){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--warning",
                                        expire:5000
                                    });

                                    mainProductDeleteForm.clear();
                                    mainProductDeleteForm.setValue({"AA001": "0"});

                                    axios.post(apiServer+"/products/loadMainProductGrid",{itemId1:hotel},headers)
                                        .then((response2) =>{
                                            if(response2.status === 200){
                                                if(response2.data){
                                                    mainProductSetWindow3.hide();
                                                    mainProductGrid.data.removeAll();
                                                    mainProductGrid.data.parse(response2.data);
                                                }else{
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    mainProductDeleteForm.clear();
                    mainProductDeleteForm.setValue({"AA001": "0"});
                    mainProductSetWindow3.hide();
                    break;
                default:
                    break;
            }
        });

        let mainProductDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "mainProductDeleteFormImg",
                    height: 200,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                },
                {id: "mainProductDeleteForm"},
            ]
        });

        const mainProductDeleteForm = new DHXForm(null,{
            rows: mainProductsSetDeleteFormConfig
        });

        mainProductSetWindow3.attach(mainProductDeleteFormLayout);
        mainProductDeleteFormLayout.getCell("mainProductDeleteForm").attach(mainProductDeleteForm);

        //房型設備
        let roomDeviceLayout = new DHXLayout(null,{
            rows:[
                {id:"roomDeviceSetToolbar", height: "content"},
                {id: "roomDeviceSetGrid", height:"600px"},
            ]
        });

        let roomDeviceSetGrid = new DHXGrid(null,{
            columns: roomDeviceSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers:{
                onclick:{
                    "roomDeviceSetGrid_edit":function(event,data){
                        if(canEdit){
                            dhxRoomDeviceWindow1.header.data.update("title", {value: "編輯房間設備視窗"});
                            dhxRoomDeviceWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                            roomDeviceSetForm1.clear();
                            roomDeviceSetForm1.getItem("BE001").setValue("0");
                            roomDeviceSetForm1.getItem("BE022").setValue("1");

                            axios.post(apiServer+"/products/loadRoomDeviceSetStopFormData", {
                                itemId1: data.row.BE001
                            },headers).then((response) =>{
                                roomDeviceSetForm1.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });

                            dhxRoomDeviceWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "roomDeviceSetGrid_stop":function(event,data){
                        if(canEdit){
                            roomDeviceSetStopForm.clear();
                            roomDeviceSetStopForm.getItem("stopFormTitle").setValue("確定要停用嗎?");

                            axios.post(apiServer+"/products/loadRoomDeviceSetStopFormData", {
                                itemId1: data.row.BE001
                            },headers).then((response) =>{
                                roomDeviceSetStopForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxRoomDeviceWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "roomDeviceSetGrid_delete":function(event,data){
                        if(canDelete){
                            roomDeviceSetDeleteForm.clear();
                            roomDeviceSetDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/products/loadRoomDeviceSetStopFormData", {
                                itemId1: data.row.BE001
                            },headers).then((response) =>{
                                roomDeviceSetDeleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxRoomDeviceWindow3.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                }
            }
        });

        axios.get(apiServer+"/products/loadDeviceGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    roomDeviceSetGrid.data.removeAll();
                    roomDeviceSetGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const toolbar2 = new DHXToolbar(null, {
        });
        toolbar2.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canDelete){
                        dhxRoomDeviceWindow1.header.data.update("title", {value: "新增房間設備視窗"});
                        dhxRoomDeviceWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        roomDeviceSetForm1.clear();
                        roomDeviceSetForm1.getItem("BE001").setValue("0");
                        roomDeviceSetForm1.getItem("BE022").setValue("1");
                        dhxRoomDeviceWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    roomDeviceSetGrid.data.removeAll();
                    axios.get(apiServer+"/products/loadDeviceGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                roomDeviceSetGrid.data.removeAll();
                                roomDeviceSetGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar2.data.parse(JSON.parse(JSON.stringify(toolbar2Config)));

        mainProductsTab.getCell("roomDeviceSet").attach(roomDeviceLayout);
        roomDeviceLayout.getCell("roomDeviceSetToolbar").attach(toolbar2);
        roomDeviceLayout.getCell("roomDeviceSetGrid").attach(roomDeviceSetGrid);

        const dhxRoomDeviceWindow1 = new DHXWindow({
            title: "新增房間設備視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:400,
            height: 450,
            footer: true,
            css: "dhxRoomDeviceWindow1",
        });

        dhxRoomDeviceWindow1.footer.data.add({type:"spacer"});
        dhxRoomDeviceWindow1.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxRoomDeviceWindow1.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        dhxRoomDeviceWindow1.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxRoomDeviceWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    roomDeviceSetForm1.clear();
                    roomDeviceSetForm1.getItem("BE001").setValue("0");
                    roomDeviceSetForm1.getItem("BE022").setValue("1");
                    roomDeviceSetForm1.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(roomDeviceSetForm1.getItem("BE100").getValue() === ""){
                        DHXMessage({
                            text: "請填入設備名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    // if(roomDeviceSetForm1.getItem("BE101").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填入設備編號",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // };

                    if(roomDeviceSetForm1.getItem("BE022").getValue() === "0"){
                        if(roomDeviceSetForm1.getItem("BE102").getValue() === ""){
                            DHXMessage({
                                text: "請填入原因",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }
                    };

                    axios.post(apiServer+"/products/checkDeviceName",
                        roomDeviceSetForm1.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "設備名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });

                        }else{
                            axios.post(apiServer+"/products/checkDeviceNo",
                                roomDeviceSetForm1.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: "設備編號重複",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    axios.post(apiServer+"/products/updateDeviceFormData",
                                        roomDeviceSetForm1.getValue(),headers
                                    ).then((response) =>{
                                        if(response.data[0].result){
                                            DHXMessage({
                                                text: response.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--success",
                                                expire:5000
                                            });
                                            roomDeviceSetForm1.clear();
                                            roomDeviceSetForm1.getItem("BE001").setValue("0");
                                            roomDeviceSetForm1.getItem("BE022").setValue("1");
                                            roomDeviceSetForm1.clear("validation");

                                            dhxRoomDeviceWindow1.hide();
                                            axios.get(apiServer+"/products/loadDeviceGrid",headers)
                                                .then((response) =>{
                                                    if(response.status === 200){
                                                        roomDeviceSetGrid.data.removeAll();
                                                        roomDeviceSetGrid.data.parse(response.data);
                                                    }else{
                                                        console.log("Server Error")
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });

                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "WINDOW_FOOT_CLOSE":
                    dhxRoomDeviceWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const roomDeviceSetForm1 = new DHXForm(null,{
            rows: roomDeviceSetFormConfig
        });

        roomDeviceSetForm1.getItem("BE022").events.on("change", function(value) {
            if(value == 0){
                roomDeviceSetForm1.getItem("BE102").enable();
            }else{
                roomDeviceSetForm1.getItem("BE102").disable();
                roomDeviceSetForm1.getItem("BE102").setValue("");
            }
        });

        dhxRoomDeviceWindow1.attach(roomDeviceSetForm1);

        const dhxRoomDeviceWindow2 = new DHXWindow({
            title: "停用房間設備視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width: 550,
            height: 610,
            footer: true,
            css: "dhxRoomDeviceWindow2"
        });

        dhxRoomDeviceWindow2.footer.data.add({type:"spacer"});
        dhxRoomDeviceWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxRoomDeviceWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxRoomDeviceWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(roomDeviceSetStopForm.getItem("BE102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/products/stopDevice",
                        roomDeviceSetStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            roomDeviceSetStopForm.clear();
                            roomDeviceSetStopForm.setValue({"BE001": "0"});
                            roomDeviceSetStopForm.clear("validation");

                            axios.get(apiServer+"/products/loadDeviceGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        dhxRoomDeviceWindow2.hide();
                                        roomDeviceSetGrid.data.removeAll();
                                        roomDeviceSetGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxRoomDeviceWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let roomDeviceSetStopFormLayout = new DHXLayout(null,{
            rows:[
                {id:"roomDeviceSetStopFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_warning+" /></div>",
                    gravity: false
                },
                {id: "roomDeviceSetStopForm"},
            ]
        });

        const roomDeviceSetStopForm = new DHXForm(null,{
            rows: roomDeviceStopFormConfig
        });

        dhxRoomDeviceWindow2.attach(roomDeviceSetStopFormLayout);
        roomDeviceSetStopFormLayout.getCell("roomDeviceSetStopForm").attach(roomDeviceSetStopForm);

        const dhxRoomDeviceWindow3 = new DHXWindow({
            title: "刪除房間設備視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width: 550,
            height: 610,
            footer: true,
            css: "dhxRoomDeviceWindow3"
        });

        dhxRoomDeviceWindow3.footer.data.add({type:"spacer"});
        dhxRoomDeviceWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxRoomDeviceWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxRoomDeviceWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(roomDeviceSetDeleteForm.getItem("BE103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/products/deleteDevice",
                        roomDeviceSetDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.get(apiServer+"/products/loadDeviceGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        dhxRoomDeviceWindow3.hide();
                                        roomDeviceSetGrid.data.removeAll();
                                        roomDeviceSetGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            roomDeviceSetDeleteForm.clear();
                            roomDeviceSetDeleteForm.setValue({"BE001": "0"});
                            roomDeviceSetDeleteForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxRoomDeviceWindow3.hide();
                    break;
                default:
                    break;
            }
        });

        let roomDeviceSetDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"roomDeviceSetDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "roomDeviceSetDeleteForm"},
            ]
        });

        const roomDeviceSetDeleteForm = new DHXForm(null,{
            rows: roomDeviceDeleteFormConfig
        });

        dhxRoomDeviceWindow3.attach(roomDeviceSetDeleteFormLayout);
        roomDeviceSetDeleteFormLayout.getCell("roomDeviceSetDeleteForm").attach(roomDeviceSetDeleteForm);

        return () => {
            mainProductsList && mainProductsList.destructor();
            // hotelsList && hotelsList.destructor();
            mainProductsTab && mainProductsTab.destructor();
        };
    }, [canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={2}>
                            {/*<Card>*/}
                            {/*    <CardHeader title={"飯店清單"}/>*/}
                            {/*    <MDBox py={1} px={1}>*/}
                            {/*        <div ref={DHXHotelsList}></div>*/}
                            {/*    </MDBox>*/}
                            {/*</Card>*/}
                            {/*<br/>*/}
                            <Card>
                                <CardHeader title={"主要產品清單"}/>
                                <MDBox py={1} px={1}>
                                    <div ref={DHXMainProductsList}></div>
                                </MDBox>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={10}>
                            <Card>
                                <CardHeader title={"房型設定"}/>
                                <MDBox py={1} px={1}>
                                    <div ref={DHXMainProductsTab}></div>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>

                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

MainProductsBasicSetting.propTypes = {

};

export default MainProductsBasicSetting;
