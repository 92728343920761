let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

export const housekeepingReportFormConfig = [
    { type: "input", name: "AK001", hidden: true, value: "0" },
    {
        cols:[
            {
                type: "text",
                label: "日期：",
                width: "33.2%",
                value: "",
                name: "AK100",
            },
            {
                type: "text",
                label: "通知打掃時間：",
                width: "33.2%",
                value: "",
                name: "AK101",
            },
            {
                type: "text",
                label: "房號：",
                width: "33.2%",
                value: "",
                name: "AK201",
            },
        ],
    },

    {
        cols:[
            {
                type: "text",
                label: "打掃原因：",
                width: "33.2%",
                value: "",
                name: "AK106",
            },
            {
                type: "text",
                label: "清潔人員：",
                width: "33.2%",
                value: "",
                name: "AK202",
            },
            {
                type: "text",
                label: "清潔後回報時間：",
                width: "33.2%",
                value: "",
                name: "AK102",
            },
        ],
    },
    {
        type: "textarea",
        label: "遺失物通報：",
        value: "",
        height: 100,
        readOnly:true,
        name: "AK103",
    },
    {
        cols:[
            {
                type: "checkbox",
                labelWidth: "0px",
                text: "遺失物已歸還",
                // checked: true,
                name: "AK107",
                css: "faultReportDoneForm_checkBox"
            },
            {
                type: "datepicker",
                label: "*歸還時間：",
                labelPosition: "left",
                timePicker: true,
                timeFormat: 24,
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                disabled: "true",
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                name: "AK108",
                css: "faultReportDoneForm_cheanTime"
            },
        ]
    },
    {
        type: "textarea",
        label: "備註：",
        value: "",
        height: 100,
        name: "AK109",
    },
];