let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

let dateTime = new Date();
dateTime = dateTime.setDate(dateTime.getDate()+1);
dateTime = new Date(dateTime);

export const alertSetGridFormConfig = [
    { type: "input", name: "AE001", hidden: true, value: "0" },
    { type: "input", name: "AE002", hidden: true, value: "0" },
    { type: "input", name: "AE003", hidden: true, value: "0" },
    { type: "datepicker",
        label: "*提醒時間：",
        labelPosition: "top",
        timePicker: true,
        timeFormat: 24,
        dateFormat: "%Y-%m-%d %H:%i",
        valueFormat:"string",
        mark: function (date) {
            if (getDate(date) === getDate(new Date)) {
                return "highlight-date";
            }
        },
        name: "AE100",
    },
    {
        type: "textarea",
        label: "*提醒內容：",
        labelPosition: "top",
        height:150,
        name: "AE101"
    },
];