import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {CardHeader} from "@mui/material";
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import {
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Grid as DHXGrid,
    message as DHXMessage,
} from 'dhx-suite';
import 'dhx-suite/codebase/suite.min.css'
import axios from "axios";
import {dailySalesReportGridColumn} from "./componentsConfig/dailySalesReportGridColumn";
import {windowFootAccept,windowFootClear,windowFootClose} from "./componentsConfig/windowConfig";
import {toolbarConfig} from "./componentsConfig/toolbarConfig";
import './style.css';
import Image_error from '../../../assets/images/error.png'
import Image_warning from '../../../assets/images/warning.png'
import api_url from "../../../context/UrlContext.js";

let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}


const DailySalesReport = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState(false);
    const [canEdit ,setCanEdit] = useState(false);
    const [canDelete ,setCanDelete] = useState(false);
    const [canPrint ,setCanPrint] = useState(false);

    let hotelsListId = "0";

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
        hotelsListId = hotel;
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXSalesReportLayout = useRef(null);

    let currentDate = "";

    useEffect(() => {

        currentDate = getDate(Date.now());

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:17},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });



        const dhxSalesReportLayout = new DHXLayout(DHXSalesReportLayout.current,{
            rows:[
                {id:"salesReportToolbar", height: "content"},
                {id: "salesReportGrid", height:"600px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {});
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    currentDate = getDate(toolbar1.getState("searchTime"));
                    axios.post(apiServer+"/reports/loadSalesReportGrid",{itemId1:hotel,itemId2:currentDate},headers)
                        .then((response) =>{
                            if(response.status === 200){
                                salesReportGrid.data.removeAll();
                                salesReportGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                case "exportExcel":
                    currentDate = getDate(toolbar1.getState("searchTime"));
                    axios.post(apiServer+"/reports/loadSalesReportGrid",{itemId1:hotel,itemId2:currentDate},headers)
                        .then((response) =>{
                            if(response.status === 200){
                                salesReportGrid.data.removeAll();
                                salesReportGrid.data.parse(response.data);
                                salesReportGrid.export.xlsx({
                                    name:"匯出銷售報表"+Date.now(),
                                    url: "//export.dhtmlx.com/excel"
                                });
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbarConfig)));

        toolbar1.events.on("inputChange", function(id, newValue){
            if(id == "searchTime"){
                currentDate = getDate(toolbar1.getState("searchTime"));
                axios.post(apiServer+"/reports/loadSalesReportGrid",{itemId1:hotel,itemId2:currentDate},headers)
                    .then((response) =>{
                        if(response.status === 200){
                            salesReportGrid.data.removeAll();
                            salesReportGrid.data.parse(response.data);
                        }else{
                            console.log("Server Error")
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });

        const salesReportGrid = new DHXGrid(null,{
            columns: dailySalesReportGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                }
            }
        });

        axios.post(apiServer+"/reports/loadSalesReportGrid",{itemId1:hotel,itemId2:currentDate},headers)
            .then((response) =>{
                if(response.status === 200){
                    salesReportGrid.data.removeAll();
                    salesReportGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        dhxSalesReportLayout.getCell("salesReportToolbar").attach(toolbar1);
        dhxSalesReportLayout.getCell("salesReportGrid").attach(salesReportGrid);



        return () => {
            dhxSalesReportLayout && dhxSalesReportLayout.destructor();
        };
    }, [canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <CardHeader title={"銷售報表"}/>
                                <MDBox py={1} px={1}>
                                    <div ref={DHXSalesReportLayout}></div>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>

                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default DailySalesReport;
