export const invoiceReportGridDeleteFormConfig = [
    { type: "input", name: "AL001", hidden: true, value: "0" },
    { type: "input", name: "AL002", hidden: true, value: "0" },
    { type: "input", name: "AL003", hidden: true, value: "0" },
    {
        type: "text",
        value: "確定要刪除嗎?",
        name: "deleteFormTitle",
        css:"deleteFormTitle"
    },
    {
        type: "text",
        label: "發票號碼：",
        labelPosition:"left",
        name: "AL101",
        css:"deleteFormName"
    },
    {
        type: "text",
        label: "金額：",
        labelPosition:"left",
        name: "AL102",
        css:"deleteFormName"
    },
    {
        type: "textarea",
        label: "*刪除原因：",
        // required: true,
        height: 150,
        name: "AL110",
    },
];