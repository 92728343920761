import React, {useEffect, useRef, useState} from 'react';
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    Grid as DHXGrid,
    DataCollection,
    Tree as DHXTree,
    TreeCollection,
    Window as DHXWindow,
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Form as DHXForm,
    message as DHXMessage,
} from "dhx-suite";
import "dhx-suite/codebase/suite.min.css";
import {CardHeader} from "@mui/material";
import {faultReportGridColumn} from "./componentsConfig/faultReportGridColumn";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import {faultReportFormConfig} from "./componentsConfig/faultReportFormConfig";
import {faultReportWindowFootAccept, faultReportWindowFootClear, faultReportWindowFootClose} from "./componentsConfig/faultReportWindowConfig";
import './style.css';
import {deleteFormConfig} from "./componentsConfig/deleteFormConfig";
import {faultReportDoneFormConfig} from "./componentsConfig/faultReportDoneFormConfig";
import Image_error from '../../../assets/images/error.png';
import axios from "axios";
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";
import Progress from "../../../assets/images/icons8-iphone-spinner.gif";

const FaultReportSetting = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXFaultReportGridLayout = useRef(null);

    let faultReportDoneForm_AA007 = "";
    let faultReportForm_AA007 = "";

    let mainPrdSelectedId = "0";
    let searchType = "week";

    useEffect(()=>{

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:8},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>資料載入中</span></div>",
            css: "dhxProgressWindow1"
        });

        const dhxFaultReportGridLayout = new DHXLayout(DHXFaultReportGridLayout.current,{
            rows:[
                {id:"faultReportGridToolbar", height: "content"},
                {id:"faultReportGridLayout", height: "500px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){

                        dhxFaultReportWindow1.header.data.update("title", {value: "新增故障通報視窗"});
                        dhxFaultReportWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        dhxFaultReportWindow1.footer.data.update("WINDOW_FOOT_ACCEPT", { disabled: false });
                        mainPrdSelectedId = "0";

                        faultReportForm.getItem("AA104").enable();
                        faultReportForm.getItem("AA105").enable();

                        faultReportForm.getItem("AA007").disable();
                        faultReportForm.getItem("AA109").disable();
                        faultReportForm.getItem("AA110").disable();

                        faultReportForm.getItem("AA007").setValue("");
                        faultReportForm.getItem("AA109").setValue("");
                        faultReportForm.getItem("AA110").setValue("");

                        faultReportForm.clear();

                        faultReportForm.setValue({"AA001":"0","AA002":hotel,"AA004":"0","AA108":"0","note":"此功能會扣掉庫存，請謹慎選取","AA103":Date.now()});

                        axios.get(apiServer+"/faultReport/loadFaultTermList",headers).then((response) =>{
                            faultReportForm.getItem("AA006").setOptions(response.data);
                            faultReportForm.clear("validation");
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        axios.post(apiServer+"/faultReport/loadOtherProductsList", {
                            itemId1: hotel,itemId2: faultReportForm.getItem("AA001").getValue(),itemId3:hotel
                        },headers).then((response) =>{
                            faultReportForm.getItem("AA005").setOptions(response.data);
                        })
                            .catch((error) => {
                                console.log(error);
                            });

                        faultReportForm.getItem("tip").setValue("(點擊輸入框進入選擇畫面)");
                        // faultReportForm.clear("validation");
                        dhxFaultReportWindow1.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    break;

                case "reload":
                    axios.post(apiServer+"/faultReport/searchFaultReportGrid", {
                        itemId1:hotel,itemId2:searchType,itemId3:toolbar1.getState("progressStatus"),itemId4:toolbar1.getState("startDate"),itemId5:toolbar1.getState("endDate")
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                dhxFaultReportGrid.data.removeAll();
                                dhxFaultReportGrid.data.parse(response.data);
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "day":
                    searchType = "day";
                    axios.post(apiServer+"/faultReport/searchFaultReportGrid", {
                        itemId1:hotel,itemId2:"day",itemId3:toolbar1.getState("progressStatus")
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                dhxFaultReportGrid.data.removeAll();
                                dhxFaultReportGrid.data.parse(response.data);
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "week":
                    searchType = "week";
                    axios.post(apiServer+"/faultReport/searchFaultReportGrid", {
                        itemId1:hotel,itemId2:"week",itemId3:toolbar1.getState("progressStatus")
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                dhxFaultReportGrid.data.removeAll();
                                dhxFaultReportGrid.data.parse(response.data);
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "month":
                    searchType = "month";
                    axios.post(apiServer+"/faultReport/searchFaultReportGrid", {
                        itemId1:hotel,itemId2:"month",itemId3:toolbar1.getState("progressStatus")
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                dhxFaultReportGrid.data.removeAll();
                                dhxFaultReportGrid.data.parse(response.data);
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "year":
                    searchType = "year";
                    axios.post(apiServer+"/faultReport/searchFaultReportGrid", {
                        itemId1:hotel,itemId2:"year",itemId3:toolbar1.getState("progressStatus")
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                dhxFaultReportGrid.data.removeAll();
                                dhxFaultReportGrid.data.parse(response.data);
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "search":
                    if((toolbar1.getState("startDate") === "" || toolbar1.getState("endDate") === "" || toolbar1.getState("startDate") === undefined || toolbar1.getState("endDate") === undefined)){
                        DHXMessage({
                            text: "請選擇日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(toolbar1.getState("startDate") !== "" && toolbar1.getState("endDate") !== "" && toolbar1.getState("startDate") !== undefined && toolbar1.getState("endDate") !== undefined && (toolbar1.getState("startDate") > toolbar1.getState("endDate"))){
                        DHXMessage({
                            text: "開始日期不得大於結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    searchType = "range";
                    axios.post(apiServer+"/faultReport/searchFaultReportGrid", {
                        itemId1:hotel,itemId2:"range",itemId3:toolbar1.getState("progressStatus"),itemId4:toolbar1.getState("startDate"),itemId5:toolbar1.getState("endDate")
                    },headers).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                dhxFaultReportGrid.data.removeAll();
                                dhxFaultReportGrid.data.parse(response.data);
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "exportExcel":
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        const dhxFaultReportGrid = new DHXGrid(null,{
            columns: faultReportGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "faultReportGrid_edit": function (e, data) {
                        if(canEdit){
                            faultReportForm.clear();
                            dhxFaultReportWindow1.header.data.update("title", {value: "編輯故障通報視窗"});
                            dhxFaultReportWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                            dhxFaultReportWindow1.footer.data.update("WINDOW_FOOT_ACCEPT", { disabled: false });

                            axios.post(apiServer+"/faultReport/loadFaultReportFormData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        faultReportForm.setValue(response.data[0]);
                                        console.log(response.data[0])
                                        mainPrdSelectedId = response.data[0].AA004;
                                        faultReportForm.getItem("AA004").setValue(response.data[0].AA004);
                                        faultReportForm.getItem("AA006").setOptions(response.data[0]["otherPrdArray"]);

                                        faultReportForm.getItem("AA006").setValue(response.data[0]["006"]);

                                        axios.post(apiServer+"/faultReport/loadOtherProductsList", {
                                            itemId1: hotel,itemId2: faultReportForm.getItem("AA001").getValue()
                                        },headers).then((response2) =>{
                                            if(response2.status === 200){
                                                if(response2.data){
                                                    faultReportForm.getItem("AA005").setOptions(response2.data);
                                                    faultReportForm.getItem("AA005").setValue(response.data[0].AA005)


                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            }else{
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        })
                                            .catch((error) => {
                                                dhxProgressWindow1.hide();
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            });

                                        if(response.data[0].AA042 === 1){

                                            faultReportForm.getItem("AA007").enable();
                                            faultReportForm.getItem("AA109").enable();
                                            faultReportForm.getItem("AA110").enable();

                                            axios.post(apiServer+"/faultReport/loadRepairmanList", {
                                                itemId1:hotel
                                            },headers).then((response3) =>{
                                                if(response3.status === 200){
                                                    if(response3.data){

                                                        faultReportForm_AA007 = faultReportForm.getItem("AA007").getWidget();
                                                        faultReportForm_AA007.data.removeAll();
                                                        faultReportForm_AA007.data.parse(response3.data);

                                                        faultReportForm.getItem("AA007").setValue([response.data[0].AA007])

                                                        faultReportForm.getItem("AA104").disable();
                                                        faultReportForm.getItem("AA105").disable();
                                                        faultReportForm.getItem("AA100").disable();
                                                        faultReportForm.getItem("mainPrd").disable();
                                                        faultReportForm.getItem("AA005").disable();
                                                        faultReportForm.getItem("AA108").disable();

                                                        dhxProgressWindow1.hide();
                                                        dhxFaultReportWindow1.show();

                                                    }else{
                                                        dhxProgressWindow1.hide();
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                }else{
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                }
                                            })
                                                .catch((error) => {
                                                    dhxProgressWindow1.hide();
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                        }else{
                                            faultReportForm.getItem("AA104").enable();
                                            faultReportForm.getItem("AA105").enable();


                                            faultReportForm.getItem("AA007").disable();
                                            faultReportForm.getItem("AA109").disable();
                                            faultReportForm.getItem("AA110").disable();

                                            faultReportForm.getItem("AA007").setValue("");
                                            faultReportForm.getItem("AA109").setValue("");
                                            faultReportForm.getItem("AA110").setValue("");

                                            dhxProgressWindow1.hide();
                                            dhxFaultReportWindow1.show();
                                        }

                                        axios.get(apiServer+"/faultReport/loadFaultTermList",headers).then((response3) =>{
                                            faultReportForm.getItem("AA006").setOptions(response3.data);
                                            faultReportForm.clear("validation");
                                            faultReportForm.getItem("AA006").setValue(response.data[0]["AA006"])
                                        })
                                            .catch((error) => {
                                                console.log(error);
                                            });
                                    }

                                }else{
                                    console.log("Server Error")
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            // dhxFaultReportWindow1.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    },
                    "faultReportGrid_delete": function (e, data) {
                        if(canDelete){
                            axios.post(apiServer+"/faultReport/loadFaultReportFormData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    faultReportDeleteForm.setValue(response.data[0]);
                                    dhxFaultReportWindow2.show();
                                }else{
                                    console.log("Server Error")
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }


                    },
                    // "faultReportGrid_view": function (e, data) {
                    //
                    //     faultReportForm.clear();
                    //     dhxFaultReportWindow1.header.data.update("title", {value: "瀏覽故障通報視窗"});
                    //     dhxFaultReportWindow1.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                    //     dhxFaultReportWindow1.footer.data.update("WINDOW_FOOT_ACCEPT", { disabled: true });
                    //
                    //     axios.post(apiServer+"/faultReport/loadFaultReportFormData", {
                    //         itemId1: data.row.AA001
                    //     },headers).then((response) =>{
                    //         if(response.status === 200){
                    //             faultReportForm.setValue(response.data[0]);
                    //             mainPrdSelectedId = response.data[0].AA004;
                    //             faultReportForm.getItem("AA004").setValue(response.data[0].AA004);
                    //             faultReportForm.getItem("AA006").setOptions(response.data[0]["otherPrdArray"]);
                    //
                    //             faultReportForm.getItem("AA006").setValue(response.data[0]["006"]);
                    //             axios.post(apiServer+"/faultReport/loadOtherProductsList", {
                    //                 itemId1: hotel,itemId2: faultReportForm.getItem("AA001").getValue()
                    //             },headers).then((response2) =>{
                    //                 faultReportForm.getItem("AA005").setOptions(response2.data);
                    //                 faultReportForm.getItem("AA005").setValue(response.data[0].AA005)
                    //             })
                    //                 .catch((error) => {
                    //                     console.log(error);
                    //                 });
                    //
                    //             axios.get(apiServer+"/faultReport/loadFaultTermList",headers).then((response3) =>{
                    //                 faultReportForm.getItem("AA006").setOptions(response3.data);
                    //                 faultReportForm.clear("validation");
                    //                 faultReportForm.getItem("AA006").setValue(response.data[0]["AA006"])
                    //             })
                    //                 .catch((error) => {
                    //                     console.log(error);
                    //                 });
                    //         }else{
                    //             console.log("Server Error")
                    //         }
                    //     })
                    //         .catch((error) => {
                    //             console.log(error);
                    //         });
                    //     dhxFaultReportWindow1.show();
                    //
                    // },
                    "faultReportGrid_done": function (e, data) {
                        dhxProgressWindow1.show();
                        faultReportDoneForm.clear();

                        axios.post(apiServer+"/faultReport/loadRepairmanList", {
                            itemId1:hotel
                        },headers).then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    faultReportDoneForm_AA007 = faultReportDoneForm.getItem("AA007").getWidget();
                                    faultReportDoneForm_AA007.data.removeAll();
                                    faultReportDoneForm_AA007.data.parse(response.data);
                                    faultReportDoneForm.setValue({"AA001":e.target.innerText,"AA002":hotel,"AA007":"0","AA109":Date.now()});
                                    dhxFaultReportWindow3.show();
                                    dhxProgressWindow1.hide();

                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }

                        })
                            .catch((error) => {
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });

                    },
                    "faultReportGrid_loadPhotoList": function (e, data) {
                        axios.post(apiServer+"/faultReport/loadPhotoList", {
                            itemId1: data.row.AA001
                        },headers).then((response) =>{
                            if(response.status === 200){
                                dhxFaultReportWindow4.attachHTML(response.data[0].result);
                                dhxFaultReportWindow4.show();
                            }else{
                                console.log("Server Error")
                            }
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                    },
                }
            }
        });

        axios.post(apiServer+"/faultReport/loadFaultReportGrid", {
            itemId1: hotel
        },headers).then((response) =>{
            if(response.status === 200){
                dhxFaultReportGrid.data.removeAll();
                dhxFaultReportGrid.data.parse(response.data);
            }else{
                console.log("Server Error")
            }

        })
            .catch((error) => {
                console.log(error);
            });

        dhxFaultReportGridLayout.getCell("faultReportGridToolbar").attach(toolbar1);
        dhxFaultReportGridLayout.getCell("faultReportGridLayout").attach(dhxFaultReportGrid);

        const dhxFaultReportWindow1 = new DHXWindow({
            title: "新增故障通報視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 650,
            width: 635,
            footer: true,
            css: "faultReportWindow1",
        });

        dhxFaultReportWindow1.footer.data.add({type:"spacer"});
        dhxFaultReportWindow1.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootClose)));
        dhxFaultReportWindow1.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootClear)));
        dhxFaultReportWindow1.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootAccept)));

        dhxFaultReportWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    faultReportForm.clear();
                    mainPrdSelectedId = "0";
                    faultReportForm.setValue({"AA001":"0","AA002":hotel,"AA004":"0","AA108":"0","note":"此功能會扣掉庫存，請謹慎選取"});
                    faultReportForm.getItem("tip").setValue("(點擊輸入框進入選擇畫面)");
                    faultReportForm.clear("validation");
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(faultReportForm.getItem("AA103").getValue() == ""){
                        DHXMessage({
                            text: "請填寫通報日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(faultReportForm.getItem("AA104").getValue() == ""){
                        DHXMessage({
                            text: "請填寫鎖定開始日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(faultReportForm.getItem("AA105").getValue() == ""){
                        DHXMessage({
                            text: "請填寫鎖定結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(faultReportForm.getItem("AA100").getValue() == 0 || faultReportForm.getItem("AA100").getValue() == ""){
                        DHXMessage({
                            text: "請選取故障對象",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }else if(faultReportForm.getItem("AA100").getValue() == 1 && mainPrdSelectedId == "0"){
                        DHXMessage({
                            text: "請選取主要產品",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }else if(faultReportForm.getItem("AA100").getValue() == 2 && (faultReportForm.getItem("AA005").getValue() == 0 || faultReportForm.getItem("AA005").getValue() == "")){
                        DHXMessage({
                            text: "請選取加價產品",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }else if(faultReportForm.getItem("AA100").getValue() == 2 && faultReportForm.getItem("AA005").getValue() !== 0 && faultReportForm.getItem("AA005").getValue() !== "" && (faultReportForm.getItem("AA108").getValue() == "" || faultReportForm.getItem("AA108").getValue() == 0)){
                        DHXMessage({
                            text: "請填寫加價產品數量",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(faultReportForm.getItem("AA006").getValue() == "0"){
                        DHXMessage({
                            text: "請選擇故障原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(faultReportForm.getItem("AA042").getValue() == 1 && faultReportForm.getItem("AA109").getValue() == ""){
                        DHXMessage({
                            text: "請填寫處理完成日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(faultReportForm.getItem("AA042").getValue() == 1 && (faultReportForm.getItem("AA007").getValue() == "" || faultReportForm.getItem("AA007").getValue() == "0")){
                        DHXMessage({
                            text: "請選擇維修處理人員",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(faultReportForm.getItem("AA042").getValue() == 1 && faultReportForm.getItem("AA110").getValue() == ""){
                        DHXMessage({
                            text: "請填寫處理完成備註",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    dhxProgressWindow1.show();
                    if(faultReportForm.getItem("AA100").getValue() == 1){
                        updateFaultReportData();
                    }else if(faultReportForm.getItem("AA100").getValue() == 2){
                        axios.post(apiServer+"/faultReport/checkRemained", {
                            itemId1: faultReportForm.getItem("AA005").getValue(),itemId2: faultReportForm.getItem("AA108").getValue(),itemId3: faultReportForm.getItem("AA001").getValue()
                        },headers).then((response1) =>{
                            if(response1.status === 200){
                                if(response1.data[0].result === 0){
                                    DHXMessage({
                                        text: response1.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                    dhxProgressWindow1.hide();
                                }else{
                                    updateFaultReportData();
                                }
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        });
                    }

                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxFaultReportWindow1.hide();
                    break;
                default:
                    break;
            }
        })

        const faultReportForm = new DHXForm(null,{
            rows: faultReportFormConfig
        });

        function updateFaultReportData(){

            axios.post(apiServer+"/faultReport/updateFaultReportData",
                faultReportForm.getValue(),headers
            ).then((response) =>{
                if(response.status === 200){
                    if(response.data){

                        if(response.data[0].result === 9){
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--warning",
                                expire:5000
                            });
                        }else if(response.data[0].result === 8){
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else if(response.data[0].result === 1){
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: response.data[0].message,
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                        }

                        let formData = new FormData();
                        let fileIdArray = [];

                        formData.append('itemId1', response.data[0].id);
                        if(faultReportForm.getItem("proofPhoto").getValue().length === 0){
                            fileIdArray = 0;

                            formData.append('itemId2', fileIdArray);

                        }else{
                            fileIdArray = [];

                            for(let i=0;i<faultReportForm.getItem("proofPhoto").getValue().length;i++){
                                fileIdArray.push(faultReportForm.getItem("proofPhoto").getValue()[i].id);
                                formData.append('file[]', faultReportForm.getItem("proofPhoto").getValue()[i].file);
                            }

                            formData.append('itemId2', fileIdArray);

                        }
                        axios.post(apiServer+"/faultReport/uploadProof", formData,headers)
                            .then((response2) =>{
                                if(response2.data[0].result){
                                    axios.post(apiServer+"/faultReport/loadFaultReportGrid", {
                                        itemId1: hotel
                                    },headers).then((response3) =>{
                                        if(response3.status === 200){
                                            dhxFaultReportGrid.data.removeAll();
                                            dhxFaultReportGrid.data.parse(response3.data);
                                            dhxFaultReportWindow1.hide();
                                        }else{
                                            console.log("Server Error")
                                        }
                                    })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        faultReportForm.clear();
                        faultReportForm.setValue({"AA001":"0","AA002":hotel,"AA004":"0","AA108":"0","note":"此功能會扣掉庫存，請謹慎選取"});

                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }

            })
                .catch((error) => {
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                });
        }

        faultReportForm.getItem("AA005").events.on("change", function(value) {
            if(faultReportForm.getItem("AA005").getValue() !== '0' && faultReportForm.getItem("AA005").getValue() !== ''){
                axios.post(apiServer+"/faultReport/loadOtherPrdRemained", {
                    itemId1: faultReportForm.getItem("AA005").getValue()
                },headers).then((response) =>{
                    faultReportForm.getItem("remained").setValue(response.data[0].remained);
                })
                    .catch((error) => {
                        console.log(error);
                    });
            }else{
                faultReportForm.getItem("remained").setValue("");
            }
        });

        faultReportForm.getItem("AA104").events.on("change", function(value) {
            if(faultReportForm.getItem("AA104").getValue() != '' && faultReportForm.getItem("AA105").getValue() != ''){
                mainPrdSelectedId = "0";
                faultReportForm.getItem("mainPrd").setValue("");
                faultReportForm.getItem("AA005").setValue("");
                faultReportForm.getItem("AA100").enable();
            }else{
                faultReportForm.getItem("AA100").disable();
                mainPrdSelectedId = "0";
                faultReportForm.getItem("mainPrd").setValue("");
                faultReportForm.getItem("AA005").setValue("");
            }
        });

        faultReportForm.getItem("AA105").events.on("change", function(value) {
            if(faultReportForm.getItem("AA104").getValue() != '' && faultReportForm.getItem("AA105").getValue() != ''){
                mainPrdSelectedId = "0";
                faultReportForm.getItem("mainPrd").setValue("");
                faultReportForm.getItem("AA004").setValue("0");
                faultReportForm.getItem("AA005").setValue("0");
                faultReportForm.getItem("AA100").enable();
            }else{
                faultReportForm.getItem("AA100").disable();
                mainPrdSelectedId = "0";
                faultReportForm.getItem("mainPrd").setValue("");
                faultReportForm.getItem("AA004").setValue("0");
                faultReportForm.getItem("AA005").setValue("0");
            }
        });

        faultReportForm.getItem("mainPrd").events.on("focus", (value) => {
            const dhxFaultReportFormTreeWindow1 = new DHXWindow({

                title: "主要產品視窗",
                modal: true,
                resizable: true,
                movable: true,
                closable:true,
                viewportOverflow:true,
                height: 400,
                width: 400,
                footer: true,
                css: "reserveFormTreeWindow1",
            });

            dhxFaultReportFormTreeWindow1.footer.data.add({type:"spacer"});
            dhxFaultReportFormTreeWindow1.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootClose)));
            dhxFaultReportFormTreeWindow1.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootAccept)));

            dhxFaultReportFormTreeWindow1.footer.events.on("click",(itemId1) =>{
                switch (itemId1){
                    case "WINDOW_FOOT_ACCEPT":
                        if(mainPrdSelectedId === "0"){
                            DHXMessage({
                                text: "請選取主要產品",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }
                        faultReportForm.getItem("mainPrd").setValue(faultReportFormTree.selection.getItem().value);
                        dhxFaultReportFormTreeWindow1.hide();
                        faultReportFormTree.destructor();
                        dhxFaultReportFormTreeWindow1.destructor();
                        break;
                    case "WINDOW_FOOT_CLOSE":
                        dhxFaultReportFormTreeWindow1.hide();
                        faultReportFormTree.destructor();
                        dhxFaultReportFormTreeWindow1.destructor();
                        break;
                    default:
                        break;
                }
            })

            const faultReportFormTree = new DHXTree(null, {
                data:[],
            });

            if(mainPrdSelectedId !== "0"){
                faultReportFormTree.selection.add(mainPrdSelectedId);
            }

            faultReportFormTree.selection.events.on("afterSelect", function(id){
                console.log(faultReportFormTree.getChecked());
                if(faultReportFormTree.selection.getItem().items == undefined){
                    mainPrdSelectedId = id;
                    faultReportForm.getItem("AA004").setValue(mainPrdSelectedId);
                }else{
                    mainPrdSelectedId = "0";
                    faultReportForm.getItem("AA004").setValue("0");
                    faultReportForm.getItem("mainPrd").setValue("");
                }
            });

            dhxProgressWindow1.show();

            axios.post(apiServer+"/faultReport/loadMainProductsTree", {
                itemId1: hotel,itemId2:faultReportForm.getItem("AA104").getValue(),itemId3:faultReportForm.getItem("AA105").getValue(),itemId4:faultReportForm.getItem("AA001").getValue()
            },headers).then((data) =>{

                if(data.status === 200){
                    if(data.data == 0){
                        dhxProgressWindow1.hide();
                        DHXMessage({
                            text: "目前沒有可通報故障的主要產品",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }else{

                        faultReportFormTree.data.removeAll();
                        faultReportFormTree.data.parse(data.data);

                        dhxFaultReportFormTreeWindow1.attach(faultReportFormTree);

                        dhxFaultReportFormTreeWindow1.show();
                        dhxProgressWindow1.hide();
                    }
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                    dhxProgressWindow1.hide();
                };
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
                dhxProgressWindow1.hide();
            });
        });

        let faultReportFormLockStartDate = faultReportForm.getItem("AA104").getWidget();
        let faultReportFormLockEndDate = faultReportForm.getItem("AA105").getWidget();
        faultReportFormLockStartDate.link(faultReportFormLockEndDate)

        faultReportForm.getItem("AA100").events.on("change", function(value) {
            faultReportForm.getItem("mainPrd").setValue("");
            faultReportForm.getItem("AA005").setValue("0");
            if(value == 2){
                mainPrdSelectedId = "0";
                faultReportForm.getItem("AA004").setValue("0");
                faultReportForm.getItem("mainPrd").disable();
                faultReportForm.getItem("AA005").enable();
                faultReportForm.getItem("AA108").enable();
                faultReportForm.getItem("remained").enable();
            }else if(value == 1){
                faultReportForm.getItem("mainPrd").enable();
                faultReportForm.getItem("AA005").disable();
                faultReportForm.getItem("AA108").disable();
                faultReportForm.getItem("remained").disable();
                faultReportForm.getItem("AA108").setValue("0");
                faultReportForm.getItem("remained").setValue("");
            }else{
                faultReportForm.getItem("mainPrd").disable();
                faultReportForm.getItem("AA005").disable();
                faultReportForm.getItem("AA108").disable();
                faultReportForm.getItem("remained").disable();
                faultReportForm.getItem("AA108").setValue("0");
                faultReportForm.getItem("remained").setValue("");
            }
        });

        dhxFaultReportWindow1.attach(faultReportForm);

        function getTotalSize() {
            return faultReportForm.getItem("proofPhoto").data.reduce(function (total, f) {
                return total + f.file.size;
            }, 0);
        }

        function fileSize(f) { return f.size || f.file.size }

        faultReportForm.getItem("proofPhoto").data.events.on("beforeAdd", function (item) {

            const extension = item.file.name.split(".").pop();
            const imgpex = /(jpg|png|jpeg|gif|jfif)$/i;

            if (faultReportForm.getItem("proofPhoto").data.getLength() >= 2) {
                DHXMessage({
                    text: "最多只能上傳2個檔案",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
                return false;
            }

            if (1048576 < fileSize(item) + getTotalSize()) {
                DHXMessage({
                    text: "檔案總計不能超過500MB",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
                return false;
            }

            if (!imgpex.test(extension)) {
                DHXMessage({
                    text: "請上傳JPG、PNG、JPEG、GIF格式的文件",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
                return false;
            }
        });

        const dhxFaultReportWindow2 = new DHXWindow({
            title: "刪除故障通報視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 630,
            footer: true,
            css:"dhxFaultReportWindow2"
        });

        dhxFaultReportWindow2.footer.data.add({type:"spacer"});
        dhxFaultReportWindow2.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootClose)));
        dhxFaultReportWindow2.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootAccept)));

        dhxFaultReportWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    if(faultReportDeleteForm.getItem("AA107").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/faultReport/deleteFaultReport",
                        faultReportDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            axios.post(apiServer+"/faultReport/loadFaultReportGrid", {
                                itemId1: hotel
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    dhxFaultReportGrid.data.removeAll();
                                    dhxFaultReportGrid.data.parse(response.data);
                                }else{
                                    console.log("Server Error")
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxFaultReportWindow2.hide();
                            faultReportDeleteForm.clear();
                            faultReportDeleteForm.setValue({"AA001": "0"});
                            faultReportDeleteForm.clear("validation");
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--danger",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxFaultReportWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let faultReportDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"faultReportDeleteFormImg",
                    height: 180,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "faultReportDeleteForm"},
            ]
        });

        const faultReportDeleteForm = new DHXForm(null,{
            rows: deleteFormConfig
        });

        dhxFaultReportWindow2.attach(faultReportDeleteFormLayout);
        faultReportDeleteFormLayout.getCell("faultReportDeleteForm").attach(faultReportDeleteForm);


        const dhxFaultReportWindow3 = new DHXWindow({
            title: "故障處理回報",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 350,
            footer: true,
            css: "dhxFaultReportWindow3"
        });

        dhxFaultReportWindow3.footer.data.add({type:"spacer"});
        dhxFaultReportWindow3.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootClose)));
        dhxFaultReportWindow3.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootAccept)));

        dhxFaultReportWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){

                case "WINDOW_FOOT_ACCEPT":
                    if(faultReportDoneForm.getItem("AA109").getValue() === ""){
                        DHXMessage({
                            text: "請填寫處理完成日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    if(faultReportDoneForm.getItem("AA007").getValue() === "0" || faultReportDoneForm.getItem("AA007").getValue() === ""){
                        DHXMessage({
                            text: "請選擇維修處理人員",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    if(faultReportDoneForm.getItem("AA110").getValue() === ""){
                        DHXMessage({
                            text: "請填寫處理完成備註",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    if(faultReportDoneForm.getItem("AA111").getValue() === true && faultReportDoneForm.getItem("AA112").getValue() === ""){
                        DHXMessage({
                            text: "請填寫打掃時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    dhxProgressWindow1.show();
                    // console.log(faultReportDoneForm.getItem("AA001").getValue());
                    // if(faultReportDoneForm.getItem("AA109").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填寫處理完成日期",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // };
                    // if(faultReportDoneForm.getItem("AA110").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填寫處理完成備註",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // };

                    dhxFaultReportGrid.data.removeAll();
                    axios.post(apiServer+"/faultReport/updateFaultReportDone",
                        faultReportDoneForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });
                                axios.post(apiServer+"/faultReport/loadFaultReportGrid", {
                                    itemId1: hotel
                                },headers).then((response2) =>{
                                    if(response2.status === 200){
                                        if(response2.data){
                                            dhxFaultReportGrid.data.parse(response2.data);
                                            dhxProgressWindow1.hide();
                                        }else{
                                            dhxProgressWindow1.hide();
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                    .catch((error) => {
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                                faultReportDoneForm.clear();
                                dhxFaultReportWindow3.hide();
                                faultReportDoneForm.setValue({"AA001": "0"});
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxFaultReportWindow3.hide();
                    break;
                default:
                    break;
            }
        })

        const faultReportDoneForm = new DHXForm(null,{
            rows: faultReportDoneFormConfig
        });

        dhxFaultReportWindow3.attach(faultReportDoneForm);

        const dhxFaultReportWindow4 = new DHXWindow({
            title: "預覽照片",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:700,
            height: 500,
            footer: true,
            css: "dhxFaultReportWindow4"
        });

        dhxFaultReportWindow4.footer.data.add({type:"spacer"});
        dhxFaultReportWindow4.footer.data.add( JSON.parse( JSON.stringify(faultReportWindowFootClose)));

        return () =>{
            dhxFaultReportGridLayout && dhxFaultReportGridLayout.destructor();
        }

    },[canAccess,canEdit,canDelete,canPrint,hotel]);


    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={2}>
                    <MDBox mb={2}>
                        <Grid container>
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <h4 className={"gridTitle"}>故障與維修統計表</h4>
                                    <MDBox py={1} px={1}>
                                        <div ref={DHXFaultReportGridLayout}></div>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            }

            {!canAccess && !isLoading &&
                <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default FaultReportSetting;
