export const hotelRoomNumberGridColumn = [
    {
        id: "action", header: [{ text: "動作", align: "center" }],
        htmlEnable: true, align: "center", width:100,
    },
    { id: "item_no", width:60, header: [{ text: "項次" }] },
    { id: "serial_number", width:60, header: [{ text: "排序" }] },
    { id: "status", width:80, header: [{ text: "狀態" },{content: "selectFilter"}] },
    { id: "room_name", width:120, header: [{ text: "房間名稱" },{content: "inputFilter"}] },
    { id: "hotel_name", width:120, header: [{ text: "飯店名稱" },{content: "selectFilter"}] },
    { id: "room_type", width:120, header: [{ text: "房型" },{content: "selectFilter"}] },
    { id: "reserve", width:110, header: [{ text: "是否為保留房" },{content: "inputFilter"}],htmlEnable: true,
        template: function (e) {
            if(e === "是"){
                return '<span style="color: green;font-weight: 800;">是</span>';
            }else{
                return '<span style="color: red;font-weight: 800;">否</span>';
            }
        }
    },
    { id: "stop_reason", width:200, header: [{ text: "停用原因" },{content: "inputFilter"}] },
    { width:200, id: "created_by", header: [{ text: "建立人員" },{content: "inputFilter"}] },
    { width:200, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:200, id: "updated_by", header: [{ text: "更新人員" },{content: "inputFilter"}] },
    { width:200, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]