let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

let dateTime = new Date();
dateTime = dateTime.setDate(dateTime.getDate()+1);
dateTime = new Date(dateTime);

export const productInfo_invoiceGridFormConfig = [
    { type: "input", name: "AL001", hidden: true, value: "0" },
    { type: "input", name: "AL002", hidden: true, value: "0" },
    { type: "input", name: "AL003", hidden: true, value: "0" },
    { type: "input", name: "TMPId", hidden: true, value: "0" },
    {
        cols:[
            {
                type: "datepicker",
                label: "*開立日期時間：",
                timePicker: true,
                timeFormat: 24,
                width: "49.7%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin",
                dateFormat: "%Y-%m-%d %H:%i",
                valueFormat:"string",
                name: "AL103",
            },
            {
                type: "input",
                label: "*發票號碼：",
                labelPosition: "top",
                width: "49.7%",
                css:"rightMargin",
                name: "AL101"
            },
        ],
    },
    {
        cols:[
            {
                type: "input",
                label: "*發票金額：",
                labelPosition: "top",
                width: "49.7%",
                css:"rightMargin",
                name: "AL102"
            },
            {
                type: "input",
                label: "接收Email信箱：",
                labelPosition: "top",
                width: "49.7%",
                css:"rightMargin",
                name: "AL107"
            },
        ],
    },
    {
        cols:[
            {
                type: "input",
                label: "買受人統編：",
                labelPosition: "top",
                width: "49.7%",
                css:"rightMargin",
                name: "AL104"
            },
            {
                type: "input",
                label: "買受人名稱：",
                labelPosition: "top",
                width: "49.7%",
                css:"rightMargin",
                name: "AL105"
            },
        ],
    },
    {
        align: "end",
        cols:[
            {
                // label: " ",
                width: "49.7%",
                type: "checkbox",
                text: "作廢",
                name: "AL109",
                // css: "noLabel"
            },
            {
                // label: " ",
                width: "49.7%",
                type: "checkbox",
                text: "折讓",
                name: "AL106",
                // css: "noLabel"
            },
        ],
    },
];