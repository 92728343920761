export const faultReportGridColumn = [
    { width:100, id: "action", header: [{ text: "動作" }],
        htmlEnable: true, align: "center",
    },
    { width:60, id: "item_no", header: [{ text: "項次" }] },
    { width:250, id: "fault_object", header: [{ text: "故障對象" },{ content: "inputFilter"}]},
    { width:300, id: "fault_reason", header: [{ text: "故障原因" },{ content: "inputFilter"}]},
    { width:100, id: "fault_progress", header: [{ text: "處理狀態" },{content: "selectFilter"}],htmlEnable: true,
        template: function (e) {
            if(e === "已修好"){
                return '<span style="color: green;font-weight: 800;">已修好</span>';
            }else{
                return '<span style="color: red;font-weight: 800;">故障中</span>';
            }
        }
    },
    { width:50, id: "photo", header: [{ text: "照片" }],htmlEnable: true,
    },
    { width:100, id: "fault_reportDate", header: [{ text: "故障通報日期" },{ content: "inputFilter"}]},
    { width:150, id: "fault_lockStartDate", header: [{ text: "鎖定開始日期" },{ content: "inputFilter"}]},
    { width:150, id: "fault_lockEndDate", header: [{ text: "鎖定結束日期" },{ content: "inputFilter"}]},
    { width:150, id: "fault_reportDoneDate", header: [{ text: "故障處理完成日期" },{ content: "inputFilter"}]},
    { width:100, id: "fault_repairman", header: [{ text: "維修處理人員" },{ content: "inputFilter"}]},
    { width:300, id: "fault_reportDoneDateNote", header: [{ text: "故障處理完成備註" },{ content: "inputFilter"}]},
    { width:100, id: "fault_cleanReport", header: [{ text: "清潔需求通報" },{ content: "inputFilter"}]},
    { width:300, id: "fault_note", header: [{ text: "備註" },{ content: "inputFilter"}]},
    { width:100, id: "created_by", header: [{ text: "建立人員" },{content: "selectFilter"}] },
    { width:150, id: "created_at", header: [{ text: "建立時間" },{content: "inputFilter"}] },
    { width:100, id: "updated_by", header: [{ text: "更新人員" },{content: "selectFilter"}] },
    { width:150, id: "updated_at", header: [{ text: "更新時間" },{content: "inputFilter"}] },
]