
let startDateValue = new Date(Date.now());
startDateValue = new Date(startDateValue.getTime() - 7*24*60*60*1000);
let year1 = startDateValue.getFullYear();
let month1 = startDateValue.getMonth()+1;
let day1 = startDateValue.getDate();

if (day1 < 10) {
    day1 = '0' + day1;
}

if (month1 < 10) {
    month1 = `0${month1}`;
}

let format1 = `${year1}-${month1}-${day1}`;

let endDateValue = new Date(Date.now());
let year2 = endDateValue.getFullYear();
let month2 = endDateValue.getMonth()+1;
let day2 = endDateValue.getDate();

if (day2 < 10) {
    day2 = '0' + day2;
}

if (month2 < 10) {
    month2 = `0${month2}`;
}

let format2 = `${year2}-${month2}-${day2}`;

export const toolbar1Config = [
    {
        id: "reload",
        type: "button",
        circle: true,
        value: "重新整理",
        size: "small",
        icon: "fa-solid fa-rotate-right"
    },
    {
        id:"add",
        type: "button",
        circle: true,
        value: "新增故障通報",
        size: "small",
        icon: "fa-solid fa-plus"
    },
    {
        id: "quickBtn",
        value: "快速篩選",
        items: [
            {
                id: "day",
                value: "日"
            },
            {
                id: "week",
                value: "週"
            },
            {
                id: "month",
                value: "月"
            },
            {
                id: "year",
                value: "年"
            }
        ],
    },
    {
        id:"startDate",
        type: "datePicker",
        value: format1,
        label: "開始日期",
        width: "200",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        dateFormat: "%Y-%m-%d",
        valueFormat:"string",
    },
    {
        id:"endDate",
        type: "datePicker",
        value: format2,
        label: "結束日期",
        width: "200",
        icon: "fa-solid fa-calendar-days",
        labelWidth: "50px",
        dateFormat: "%Y-%m-%d",
        valueFormat:"string",
    },
    {
        type: "title",
        value: "處理狀態："
    },
    {
        id: "progressStatus",
        type: "selectButton",
        value: "全部",
        items: [
            {
                value: "全部",
            },
            {
                value: "已修好",
            },
            {
                value: "故障中",
            },
        ]
    },
    {id:"search", type: "button", value: "搜尋", size: "small", icon: "fa-solid fa-magnifying-glass", circle:true},
    {id:"exportExcel", type: "button", value: "匯出Excel", size: "small", icon: "fa-solid fa-file-excel", circle:true},
]