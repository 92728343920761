import React, {useEffect, useRef, useState} from 'react';
// import {useTranslation} from "react-i18next";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import {CardHeader} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {
    Tabbar as DHXTabbar,
    Grid as DHXGrid,
    Toolbar as DHXToolbar,
    Layout as DHXLayout,
    Window as DHXWindow,
    Form as DHXForm,
    message as DHXMessage
} from "dhx-suite";
import {hotelVerbSetGridColumn} from "./componentsConfig/hotelVerbSetGridColumn";
import {
    hotelBookingCancelFormConfig,
    hotelFaultSetFormConfig,
    hotelMaintainTeamFormConfig,
    hotelVerbFormConfig,
    mainProductsSetFormConfig,
    unitsSetFormConfig,
    orderSourceSetFormConfig,
    orderSubSourceSetFormConfig
} from "./componentsConfig/globalForm";
import {
    hotelVerbSetStopFormConfig,
    unitsSetStopFormConfig,
    orderSourceSetStopFormConfig,
    orderSubSourceSetStopFormConfig
} from "./componentsConfig/globalStopForm";
import {
    hotelVerbSetDeleteFormConfig,
    hotelMaintainTeamDeleteFormConfig,
    hotelFaultSetDeleteFormConfig,
    hotelBookingCancelDeleteFormConfig,
    mainProductsSetDeleteFormConfig,
    serviceTypeDeleteFormConfig,
    serviceSubTypeDeleteFormConfig,
    unitsSetDeleteFormConfig,
    orderSourceSetDeleteFormConfig,
    orderSubSourceSetDeleteFormConfig
} from "./componentsConfig/globalDeleteForm";
import {
    globalWindowFootAccept,
    globalWindowFootClear,
    globalWindowFootClose
} from "./componentsConfig/globalWindowConfig";
import {toolbar1Config} from "./componentsConfig/toolbar1Config";
import axios from "axios";
import "./style.css";
import {toolbar2Config} from "./componentsConfig/toolbar2Config";
import {hotelMainTainTeamGridColumn} from './componentsConfig/hotelMainTainTeamGridColumn';
import {hotelFaultSetGridColumn} from './componentsConfig/hotelFaultSetGridColumn';
import {toolbar3Config} from "./componentsConfig/toolbar3Config";
import {hotelBookingCancelGridColumn} from "./componentsConfig/hotelBookingCancelGridColumn";
import {toolbar4Config} from "./componentsConfig/toolbar4Config";
import {mainProductsSetGridColumn} from "./componentsConfig/mainProductsGridColumn";
import {toolbar5Config} from "./componentsConfig/toolbar5Config";
import {toolbar6Config} from "./componentsConfig/toolbar6Config";
import {serviceTypeListGridColumn} from './componentsConfig/serviceTypeListGridColumn'
import {toolbar7Config} from "./componentsConfig/toolbar7Config";
import {serviceSubTypeListGridColumn} from './componentsConfig/serviceSubTypeListGridColumn'
import {toolbar8Config} from "./componentsConfig/toolbar8Config";
import {unitsSetListGridColumn} from './componentsConfig/unitsSetListGridColumn';
import {toolbar9Config} from "./componentsConfig/toolbar9Config";
import {orderSourceSetGridColumn} from './componentsConfig/orderSourceSetGridColumn';
import {toolbar10Config} from "./componentsConfig/toolbar10Config";
import {orderSubSourceSetGridColumn} from './componentsConfig/orderSubSourceSetGridColumn';
import Image_error from '../../../assets/images/error.png';
import Image_warning from '../../../assets/images/warning.png';
import api_url from "../../../context/UrlContext.js"
import {useNavigate} from "react-router-dom";


const GlobalVarSetting = props => {

    // const {t} = useTranslation();

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState('');
    const [canEdit ,setCanEdit] = useState('');
    const [canDelete ,setCanDelete] = useState('');
    const [canPrint ,setCanPrint] = useState('');

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXTabberGlobalTab = useRef(null);

    useEffect(() => {

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:12},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });

        let dhxTabbar = new DHXTabbar(DHXTabberGlobalTab.current,{
            views: [
                {tab: "飯店設定", id: "hotelVerbSet"},
                {tab: "維修對象設定", id: "hotelMaintSet"},
                {tab: "訂單來源設定", id: "orderSourceSet"},
                {tab: "故障常用片語設定", id: "hotelFaultSet"},
                {tab: "取消訂單常用片語設定", id: "hotelBookingCancelSet"},
                {tab: "主要產品設定", id: "mainProductSet"},
                {tab: "飯店提供服務設定", id: "hotelServiceSet"},
                {tab: "單位設定", id: "unitsSet"}
            ],
        });

        dhxTabbar.setActive("hotelVerbSet");

        let GlobalTabLayout = new DHXLayout(null,{
            rows:[
                {id:"hotelVerbSetToolbar", height: "content"},
                {id: "hotelVerbSetGrid"}
            ]
        });

        //飯店基本資料
        let hotelVerbSetGrid = new DHXGrid(null,{
            columns: hotelVerbSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "hotelVerbGrid_edit": function (e, data) {
                        if(canEdit){
                            dhxHotelVerbSetWindow.header.data.update("title", {value: "編輯飯店設定視窗"});
                            dhxHotelVerbSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                            axios.post(apiServer+"/globalVerb/loadHotelVerbFormData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                dhxHotelVerbSetWindow.show();
                                hotelVerbSetForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "hotelVerbGrid_stop": function (e, data) {
                        if(canEdit){
                            hotelVerbSetStopForm.clear();
                            hotelVerbSetStopForm.getItem("stopFormTitle").setValue("確定要停用嗎?");

                            axios.post(apiServer+"/globalVerb/loadHotelVerbFormData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                hotelVerbSetStopForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxHotelVerbSetWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "hotelVerbGrid_delete": function (e, data) {
                        if(canDelete){
                            hotelVerbSetDeleteForm.clear();
                            hotelVerbSetDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/globalVerb/loadHotelVerbFormData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                hotelVerbSetDeleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxHotelVerbSetWindow3.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });
        // hotelVerbSetGrid.data.load(apiServer+"/globalVerb/loadHotelVerbSetGrid");
        axios.get(apiServer+"/globalVerb/loadHotelVerbSetGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    hotelVerbSetGrid.data.removeAll();
                    hotelVerbSetGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const toolbar1 = new DHXToolbar(null, {
        });
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        dhxHotelVerbSetWindow.header.data.update("title", {value: "新增飯店設定視窗"});
                        dhxHotelVerbSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        hotelVerbSetForm.clear();
                        hotelVerbSetForm.setValue({"AA001": "0","AA041":1,"tip":"(若是官方Line請不要輸入開頭的@)"});
                        dhxHotelVerbSetWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.get(apiServer+"/globalVerb/loadHotelVerbSetGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                hotelVerbSetGrid.data.removeAll();
                                hotelVerbSetGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbar1Config)));

        dhxTabbar.getCell("hotelVerbSet").attach(GlobalTabLayout);
        GlobalTabLayout.getCell("hotelVerbSetToolbar").attach(toolbar1);
        GlobalTabLayout.getCell("hotelVerbSetGrid").attach(hotelVerbSetGrid);

        const dhxHotelVerbSetWindow = new DHXWindow({
            title: "新增飯店設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 600,
            width: 800,
            footer: true,
            css:"dhxHotelVerbSetWindow"
        });

        dhxHotelVerbSetWindow.footer.data.add({type:"spacer"});
        dhxHotelVerbSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxHotelVerbSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        dhxHotelVerbSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxHotelVerbSetWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    hotelVerbSetForm.clear();
                    hotelVerbSetForm.setValue({"AA001": "0"});
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(hotelVerbSetForm.getItem("AA101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫飯店名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    // if(hotelVerbSetForm.getItem("AA100").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請填寫飯店代號",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // };

                    if(hotelVerbSetForm.getItem("AA116").getValue() === ""){
                        DHXMessage({
                            text: "請填寫HotelNabe飯店編號",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelVerbSetForm.getItem("AA104").getValue() === "" || hotelVerbSetForm.getItem("AA104").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇飯店類型",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelVerbSetForm.getItem("AA112").getValue() === "" || hotelVerbSetForm.getItem("AA112").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇國家",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelVerbSetForm.getItem("AA113").getValue() === "" || hotelVerbSetForm.getItem("AA113").getValue() === "0"){
                        DHXMessage({
                            text: "請選擇縣市",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelVerbSetForm.getItem("AA102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫飯店地址",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelVerbSetForm.getItem("AA103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫飯店電話",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelVerbSetForm.getItem("AA105").getValue() === ""){
                        DHXMessage({
                            text: "請填寫營業時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelVerbSetForm.getItem("AA130").getValue() === ""){
                        DHXMessage({
                            text: "請填寫接收發票Email信箱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelVerbSetForm.getItem("AA041").getValue() === ""){
                        DHXMessage({
                            text: "請選擇飯店停啟用狀態",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }else if(hotelVerbSetForm.getItem("AA041").getValue() === 3 && hotelVerbSetForm.getItem("AA114").getValue() === ""){
                        DHXMessage({
                            text: "請填寫原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/checkHotelName",
                        hotelVerbSetForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "飯店名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{

                            axios.post(apiServer+"/globalVerb/checkHotelNo",
                                hotelVerbSetForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: "飯店編號重複",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    axios.post(apiServer+"/globalVerb/checkHotelnabeNo",
                                        hotelVerbSetForm.getValue(),headers
                                    ).then((response) =>{
                                        if(response.data[0].result){
                                            DHXMessage({
                                                text: "HotelNabe飯店編號重複",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }else{
                                            axios.post(apiServer+"/globalVerb/updateHotelVarSet",
                                                hotelVerbSetForm.getValue(),headers
                                            ).then((response) =>{
                                                if(response.data[0].result){
                                                    DHXMessage({
                                                        text: response.data[0].message,
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--success",
                                                        expire:5000
                                                    });

                                                    hotelVerbSetForm.clear();
                                                    hotelVerbSetForm.setValue({"AA001": "0"});

                                                    axios.get(apiServer+"/globalVerb/loadHotelVerbSetGrid",headers)
                                                        .then((response) =>{
                                                            if(response.status === 200){
                                                                dhxHotelVerbSetWindow.hide();
                                                                hotelVerbSetGrid.data.removeAll();
                                                                hotelVerbSetGrid.data.parse(response.data);
                                                            }else{
                                                                console.log("Server Error")
                                                            }
                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                }
                                            })
                                                .catch((error) => {
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                        }
                                    })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    hotelVerbSetForm.clear();
                    hotelVerbSetForm.setValue({"AA001": "0"});
                    dhxHotelVerbSetWindow.hide();
                    break;
                default:
                    break;
            }
        });

        const hotelVerbSetForm = new DHXForm(null,{
            rows: hotelVerbFormConfig
        });

        hotelVerbSetForm.getItem("AA041").events.on("change", function(value) {

            if(value === 1){
                hotelVerbSetForm.getItem("AA114").clear();
                hotelVerbSetForm.getItem("AA114").disable();
            }else if(value === 3){
                hotelVerbSetForm.getItem("AA114").clear();
                hotelVerbSetForm.getItem("AA114").enable();
            }else{
                hotelVerbSetForm.getItem("AA114").clear();
                hotelVerbSetForm.getItem("AA114").disable();
            }
        });

        dhxHotelVerbSetWindow.attach(hotelVerbSetForm);


        const dhxHotelVerbSetWindow2 = new DHXWindow({
            title: "飯店停用視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 545,
            footer: true,
            css:"dhxHotelVerbSetWindow2"
        });

        dhxHotelVerbSetWindow2.footer.data.add({type:"spacer"});
        dhxHotelVerbSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxHotelVerbSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxHotelVerbSetWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    hotelVerbSetStopForm.clear();
                    hotelVerbSetStopForm.setValue({"AA001": "0"});
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    // if(hotelVerbSetStopForm.getItem("AA041").getValue() === ""){
                    //     DHXMessage({
                    //         text: "請選擇停用類型",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // }else if((hotelVerbSetStopForm.getItem("AA041").getValue() === 5 && hotelVerbSetStopForm.getItem("AA110").getValue() === "") || (hotelVerbSetStopForm.getItem("AA041").getValue() === 5 && hotelVerbSetStopForm.getItem("AA111").getValue() === "")){
                    //     DHXMessage({
                    //         text: "請填寫日期",
                    //         icon:"dxi dxi-close",
                    //         css: "dhx_message--error",
                    //         expire:5000
                    //     });
                    //     break;
                    // };

                    if(hotelVerbSetStopForm.getItem("AA114").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/stopHotelVerbFormData",
                        hotelVerbSetStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            hotelVerbSetStopForm.clear();
                            hotelVerbSetStopForm.setValue({"AA001": "0"});

                            axios.get(apiServer+"/globalVerb/loadHotelVerbSetGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        dhxHotelVerbSetWindow2.hide();
                                        hotelVerbSetGrid.data.removeAll();
                                        hotelVerbSetGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    hotelVerbSetStopForm.clear();
                    hotelVerbSetStopForm.setValue({"AA001": "0"});
                    dhxHotelVerbSetWindow2.hide();
                    break;
                default:
                    break;
            }
        });

        let hotelVerbSetStopFormLayout = new DHXLayout(null,{
            rows:[
                {id: "hotelVerbSetStopFormImg",
                    height: 150,
                    html: "<div style='text-align: center;'><img width='30%' src="+Image_warning+" /></div>",
                    gravity: false
                },
                {id: "hotelVerbSetStopForm"},
            ]
        });

        const hotelVerbSetStopForm = new DHXForm(null,{
            rows: hotelVerbSetStopFormConfig
        });

        // let hotelVerbSetStopForm_StartDate = hotelVerbSetStopForm.getItem("AA110").getWidget();
        // let hotelVerbSetStopForm_EndDate = hotelVerbSetStopForm.getItem("AA111").getWidget();
        // hotelVerbSetStopForm_StartDate.link(hotelVerbSetStopForm_EndDate);

        // hotelVerbSetStopForm.getItem("AA041").events.on("change", function(value) {
        //     if(value === 5){
        //         hotelVerbSetStopForm.getItem("AA110").enable();
        //         hotelVerbSetStopForm.getItem("AA111").enable();
        //     }else{
        //         hotelVerbSetStopForm.getItem("AA110").clear();
        //         hotelVerbSetStopForm.getItem("AA111").clear();
        //         hotelVerbSetStopForm.getItem("AA110").disable();
        //         hotelVerbSetStopForm.getItem("AA111").disable();
        //     }
        // });

        dhxHotelVerbSetWindow2.attach(hotelVerbSetStopFormLayout);
        hotelVerbSetStopFormLayout.getCell("hotelVerbSetStopForm").attach(hotelVerbSetStopForm);


        const dhxHotelVerbSetWindow3 = new DHXWindow({
            title: "飯店刪除視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css:"dhxHotelVerbSetWindow3"
        });

        dhxHotelVerbSetWindow3.footer.data.add({type:"spacer"});
        dhxHotelVerbSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxHotelVerbSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxHotelVerbSetWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    hotelVerbSetDeleteForm.clear();
                    hotelVerbSetDeleteForm.setValue({"AA001": "0"});
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(hotelVerbSetDeleteForm.getItem("AA115").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/deleteHotelVerbFormData",
                        hotelVerbSetDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            hotelVerbSetDeleteForm.clear();
                            hotelVerbSetDeleteForm.setValue({"AA001": "0"});

                            axios.get(apiServer+"/globalVerb/loadHotelVerbSetGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        dhxHotelVerbSetWindow3.hide();
                                        hotelVerbSetGrid.data.removeAll();
                                        hotelVerbSetGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    hotelVerbSetDeleteForm.clear();
                    hotelVerbSetDeleteForm.setValue({"AA001": "0"});
                    dhxHotelVerbSetWindow3.hide();
                    break;
                default:
                    break;
            }
        });

        let hotelVerbSetDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "hotelVerbSetDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "hotelVerbSetDeleteForm"},
            ]
        });

        const hotelVerbSetDeleteForm = new DHXForm(null,{
            rows: hotelVerbSetDeleteFormConfig
        });

        dhxHotelVerbSetWindow3.attach(hotelVerbSetDeleteFormLayout);
        hotelVerbSetDeleteFormLayout.getCell("hotelVerbSetDeleteForm").attach(hotelVerbSetDeleteForm);


        //維修對象設定

        let maintainTeamTabLayout = new DHXLayout(null,{
            rows:[
                {id:"maintainTeamToolbar", height: "content"},
                {id: "hotelMainTainTeamGrid"}
            ]
        });

        const hotelMainTainTeamGrid = new DHXGrid(null,{
            columns: hotelMainTainTeamGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "hotelMainTainTeamGrid_edit": function (e, data) {
                        if(canEdit){
                            hotelMaintainTeamWindow.header.data.update("title", {value: "編輯維修對象設定視窗"});
                            hotelMaintainTeamWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.post(apiServer+"/globalVerb/loadHotelMaintainTeamFormData", {
                                itemId1: data.row.AC001
                            },headers).then((response) =>{
                                hotelMaintainTeamWindow.show();
                                hotelMaintainTeamForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "hotelMainTainTeamGrid_delete": function (e, data) {
                        if(canDelete){
                            hotelMaintainTeamDeleteForm.clear();
                            hotelMaintainTeamDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/globalVerb/loadHotelMaintainTeamFormData", {
                                itemId1: data.row.AC001
                            },headers).then((response) =>{
                                hotelMaintainTeamWindow2.show();
                                hotelMaintainTeamDeleteForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.get(apiServer+"/globalVerb/loadHotelMaintainTeamGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    hotelMainTainTeamGrid.data.removeAll();
                    hotelMainTainTeamGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const toolbar2 = new DHXToolbar(null, {
        });
        toolbar2.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        hotelMaintainTeamWindow.header.data.update("title", {value: "新增維修對象設定視窗"});
                        hotelMaintainTeamWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        hotelMaintainTeamForm.clear();
                        hotelMaintainTeamForm.setValue({"AC001": "0"});
                        hotelMaintainTeamWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.get(apiServer+"/globalVerb/loadHotelMaintainTeamGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                hotelMainTainTeamGrid.data.removeAll();
                                hotelMainTainTeamGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar2.data.parse(JSON.parse(JSON.stringify(toolbar2Config)));

        dhxTabbar.getCell("hotelMaintSet").attach(maintainTeamTabLayout);
        maintainTeamTabLayout.getCell("maintainTeamToolbar").attach(toolbar2);
        maintainTeamTabLayout.getCell("hotelMainTainTeamGrid").attach(hotelMainTainTeamGrid);

        const hotelMaintainTeamWindow = new DHXWindow({
            title: "維修對象設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width: 400,
            height: 280,
            footer: true,
            css: "hotelMaintainTeamWindow"
        });

        hotelMaintainTeamWindow.footer.data.add({type:"spacer"});
        hotelMaintainTeamWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        hotelMaintainTeamWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        hotelMaintainTeamWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        hotelMaintainTeamWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    hotelMaintainTeamForm.clear();
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(hotelMaintainTeamForm.getItem("AC100").getValue() === "0" || hotelMaintainTeamForm.getItem("AC100").getValue() === ""){
                        DHXMessage({
                            text: "請選擇維修片語類型",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(hotelMaintainTeamForm.getItem("AC101").getValue().replace(/\s*/g,"") === ""){
                        DHXMessage({
                            text: "請填寫維修片語名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/checkMaintainTeamName",
                        hotelMaintainTeamForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "維修片語名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            axios.post(apiServer+"/globalVerb/updateHotelMaintainTeamFormData",
                                hotelMaintainTeamForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    hotelVerbSetDeleteForm.clear();
                                    hotelVerbSetDeleteForm.setValue({"AA001": "0"});
                                    hotelMainTainTeamGrid.data.load(apiServer+"/globalVerb/loadHotelMaintainTeamGrid");

                                    axios.get(apiServer+"/globalVerb/loadHotelMaintainTeamGrid",headers)
                                        .then((response) =>{
                                            if(response.status === 200){
                                                hotelMaintainTeamWindow.hide();
                                                hotelMainTainTeamGrid.data.removeAll();
                                                hotelMainTainTeamGrid.data.parse(response.data);
                                            }else{
                                                console.log("Server Error")
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    hotelMaintainTeamWindow.hide();
                    break;
                default:
                    break;
            }
        })

        const hotelMaintainTeamForm = new DHXForm(null,{
            rows: hotelMaintainTeamFormConfig
        });

        hotelMaintainTeamForm.events.on("click", function (name, e){
            switch (name){
                case "send":
                    hotelMaintainTeamWindow.hide();
                    break;
                default:
                    break;
            }
        });

        hotelMaintainTeamWindow.attach(hotelMaintainTeamForm);

        const hotelMaintainTeamWindow2 = new DHXWindow({
            title: "刪除維修對象設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 610,
            footer: true,
            css: "hotelMaintainTeamWindow2"
        });

        hotelMaintainTeamWindow2.footer.data.add({type:"spacer"});
        hotelMaintainTeamWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        hotelMaintainTeamWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        hotelMaintainTeamWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        hotelMaintainTeamWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    hotelMaintainTeamForm.clear();
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(hotelMaintainTeamDeleteForm.getItem("AC102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/deleteHotelMaintainTeamFormData",
                        hotelMaintainTeamDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            hotelMaintainTeamDeleteForm.clear();
                            hotelMaintainTeamDeleteForm.setValue({"AC001": "0"});

                            axios.get(apiServer+"/globalVerb/loadHotelMaintainTeamGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        hotelMaintainTeamWindow2.hide();
                                        hotelMainTainTeamGrid.data.removeAll();
                                        hotelMainTainTeamGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    axios.post(apiServer+"/globalVerb/deleteHotelMaintainTeamFormData",
                        hotelMaintainTeamDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            hotelMaintainTeamDeleteForm.clear();
                            hotelMaintainTeamDeleteForm.setValue({"AC001": "0"});

                            axios.get(apiServer+"/globalVerb/loadHotelMaintainTeamGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        hotelMaintainTeamWindow2.hide();
                                        hotelMainTainTeamGrid.data.removeAll();
                                        hotelMainTainTeamGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "WINDOW_FOOT_CLOSE":
                    hotelMaintainTeamWindow2.hide();
                    break;
                default:
                    break;
            }
        })

        let hotelMaintainTeamDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "hotelMaintainTeamDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "hotelMaintainTeamDeleteForm"},
            ]
        });

        const hotelMaintainTeamDeleteForm = new DHXForm(null,{
            rows: hotelMaintainTeamDeleteFormConfig
        });

        hotelMaintainTeamWindow2.attach(hotelMaintainTeamDeleteFormLayout);
        hotelMaintainTeamDeleteFormLayout.getCell("hotelMaintainTeamDeleteForm").attach(hotelMaintainTeamDeleteForm);


        // 故障常用片語設定

        let hotelFaultSetTabLayout = new DHXLayout(null,{
            rows:[
                {id:"hotelFaultSetToolbar", height: "content"},
                {id: "hotelFaultSetGrid"}
            ]
        });

        const hotelFaultSetGrid = new DHXGrid(null,{
            columns: hotelFaultSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "hotelFaultSetGrid_edit": function (e, data) {
                        if(canEdit){
                            hotelFaultSetForm.clear();
                            hotelFaultSetWindow.header.data.update("title", {value: "編輯故障片語設定視窗"});
                            hotelFaultSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.post(apiServer+"/globalVerb/loadHotelFaultSetData", {
                                itemId1: data.row.AB001
                            },headers).then((response) =>{
                                hotelFaultSetWindow.show();
                                hotelFaultSetForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "hotelFaultSetGrid_delete": function (e, data) {
                        if(canDelete){
                            hotelFaultSetDeleteForm.clear();
                            hotelFaultSetDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/globalVerb/loadHotelFaultSetData", {
                                itemId1: data.row.AB001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    hotelFaultSetWindow2.show();
                                    hotelFaultSetDeleteForm.setValue( response.data[0]);
                                }else{
                                    console.log("Server Error")
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.get(apiServer+"/globalVerb/loadHotelFaultSetGridGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    hotelFaultSetGrid.data.removeAll();
                    hotelFaultSetGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const toolbar3 = new DHXToolbar(null, {
        });
        toolbar3.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        hotelFaultSetForm.clear();
                        hotelFaultSetForm.setValue({"AB001":"0"});
                        hotelFaultSetWindow.header.data.update("title", {value: "新增故障常用片語設定視窗"});
                        hotelFaultSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        hotelFaultSetWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.get(apiServer+"/globalVerb/loadHotelFaultSetGridGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                hotelFaultSetGrid.data.removeAll();
                                hotelFaultSetGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar3.data.parse(JSON.parse(JSON.stringify(toolbar3Config)));

        dhxTabbar.getCell("hotelFaultSet").attach(hotelFaultSetTabLayout);
        hotelFaultSetTabLayout.getCell("hotelFaultSetToolbar").attach(toolbar3);
        hotelFaultSetTabLayout.getCell("hotelFaultSetGrid").attach(hotelFaultSetGrid);

        const hotelFaultSetWindow = new DHXWindow({

            title: "故障常用片語設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width: 400,
            height: 210,
            footer: true,
            css: "hotelFaultSetWindow"
        });

        hotelFaultSetWindow.footer.data.add({type:"spacer"});
        hotelFaultSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        hotelFaultSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        hotelFaultSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        hotelFaultSetWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(hotelFaultSetForm.getItem("AB100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫故障片語名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/checkHotelFaultSetName",
                        hotelFaultSetForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "故障片語名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            axios.post(apiServer+"/globalVerb/updateHotelFaultSetFormData",
                                hotelFaultSetForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });
                                    hotelFaultSetForm.clear();

                                    axios.get(apiServer+"/globalVerb/loadHotelFaultSetGridGrid",headers)
                                        .then((response) =>{
                                            if(response.status === 200){
                                                hotelFaultSetWindow.hide();
                                                hotelFaultSetGrid.data.removeAll();
                                                hotelFaultSetGrid.data.parse(response.data);
                                            }else{
                                                console.log("Server Error")
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    hotelFaultSetWindow.hide();
                    break;
                default:
                    break;
            }
        })

        const hotelFaultSetForm = new DHXForm(null,{
            rows: hotelFaultSetFormConfig
        });

        hotelFaultSetWindow.attach(hotelFaultSetForm);

        const hotelFaultSetWindow2 = new DHXWindow({
            title: "刪除故障常用片語設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "hotelFaultSetWindow2"
        });

        hotelFaultSetWindow2.footer.data.add({type:"spacer"});
        hotelFaultSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        // hotelFaultSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        hotelFaultSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        hotelFaultSetWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                // case "WINDOW_FOOT_CLEAR":
                //     hotelFaultSetDeleteForm.clear();
                //     break;
                case "WINDOW_FOOT_ACCEPT":

                    if(hotelFaultSetDeleteForm.getItem("AB101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };


                    axios.post(apiServer+"/globalVerb/deleteHotelFaultSetFormData",
                        hotelFaultSetDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            hotelFaultSetDeleteForm.clear();
                            hotelFaultSetDeleteForm.setValue({"AB001": "0"});

                            axios.get(apiServer+"/globalVerb/loadHotelFaultSetGridGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        hotelFaultSetWindow2.hide();
                                        hotelFaultSetGrid.data.removeAll();
                                        hotelFaultSetGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "WINDOW_FOOT_CLOSE":
                    hotelFaultSetWindow2.hide();
                    break;
                default:
                    break;
            }
        })

        let hotelFaultSetDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "hotelFaultSetDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "hotelFaultSetDeleteForm"},
            ]
        });

        const hotelFaultSetDeleteForm = new DHXForm(null,{
            rows: hotelFaultSetDeleteFormConfig
        });

        hotelFaultSetWindow2.attach(hotelFaultSetDeleteFormLayout);
        hotelFaultSetDeleteFormLayout.getCell("hotelFaultSetDeleteForm").attach(hotelFaultSetDeleteForm);


        // 取消訂單常用片語

        let hotelBookingCancelTabLayout = new DHXLayout(null,{
            rows:[
                {id:"hotelBookingCancelToolbar", height: "content"},
                {id: "hotelBookingCancelGrid"}
            ]
        });

        const hotelBookingCancelGrid = new DHXGrid(null,{
            columns: hotelBookingCancelGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "hotelBookingCancelGrid_edit": function (e, data) {
                        if(canEdit){
                            hotelBookingCancelForm.clear();
                            hotelBookingCancelWindow.header.data.update("title", {value: "編輯取消訂單片語設定視窗"});
                            hotelBookingCancelWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.post(apiServer+"/globalVerb/loadHotelBookingCancelData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                hotelBookingCancelWindow.show();
                                hotelBookingCancelForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "hotelBookingCancelGrid_delete": function (e, data) {
                        if(canDelete){
                            hotelBookingCancelDeleteForm.clear();
                            hotelBookingCancelDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/globalVerb/loadHotelBookingCancelData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    hotelBookingCancelWindow2.show();
                                    hotelBookingCancelDeleteForm.setValue( response.data[0]);
                                }else{
                                    console.log("Server Error")
                                }
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.get(apiServer+"/globalVerb/loadHotelBookingCancelGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    hotelBookingCancelGrid.data.removeAll();
                    hotelBookingCancelGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const toolbar4 = new DHXToolbar(null, {
        });
        toolbar4.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        hotelBookingCancelForm.clear();
                        hotelBookingCancelForm.setValue({"AA001":"0"});
                        hotelBookingCancelWindow.header.data.update("title", {value: "新增取消訂單片語設定視窗"});
                        hotelBookingCancelWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        hotelBookingCancelWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.get(apiServer+"/globalVerb/loadHotelBookingCancelGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                hotelBookingCancelGrid.data.removeAll();
                                hotelBookingCancelGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar4.data.parse(JSON.parse(JSON.stringify(toolbar4Config)));

        dhxTabbar.getCell("hotelBookingCancelSet").attach(hotelBookingCancelTabLayout);
        hotelBookingCancelTabLayout.getCell("hotelBookingCancelToolbar").attach(toolbar4);
        hotelBookingCancelTabLayout.getCell("hotelBookingCancelGrid").attach(hotelBookingCancelGrid);

        const hotelBookingCancelWindow = new DHXWindow({
            title: "新增取消訂單常用片語視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 210,
            footer: true,
            css: "hotelBookingCancelWindow"
        });

        hotelBookingCancelWindow.footer.data.add({type:"spacer"});
        hotelBookingCancelWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        hotelBookingCancelWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        hotelBookingCancelWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        hotelBookingCancelWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    hotelBookingCancelForm.clear();
                    hotelBookingCancelForm.setValue({"AA001":"0"});
                    break;

                case "WINDOW_FOOT_ACCEPT":
                    if(hotelBookingCancelForm.getItem("AA100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫取消訂單片語名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };
                    axios.post(apiServer+"/globalVerb/checkHotelBookingCancelName",
                        hotelBookingCancelForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "取消訂單片語名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            axios.post(apiServer+"/globalVerb/updateHotelBookingCancelFormData",
                                hotelBookingCancelForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });
                                    hotelBookingCancelForm.clear();
                                    hotelBookingCancelForm.setValue({"AA001": "0"});

                                    axios.get(apiServer+"/globalVerb/loadHotelBookingCancelGrid",headers)
                                        .then((response) =>{
                                            if(response.status === 200){
                                                hotelBookingCancelWindow.hide();
                                                hotelBookingCancelGrid.data.removeAll();
                                                hotelBookingCancelGrid.data.parse(response.data);
                                            }else{
                                                console.log("Server Error")
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    hotelBookingCancelWindow.hide();
                    break;
                default:
                    break;
            }
        })

        const hotelBookingCancelForm = new DHXForm(null,{
            rows: hotelBookingCancelFormConfig
        });

        hotelBookingCancelWindow.attach(hotelBookingCancelForm);

        const hotelBookingCancelWindow2 = new DHXWindow({
            title: "刪除取消訂單常用片語設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "hotelBookingCancelWindow2"
        });

        hotelBookingCancelWindow2.footer.data.add({type:"spacer"});
        hotelBookingCancelWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        hotelBookingCancelWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        hotelBookingCancelWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                // case "WINDOW_FOOT_CLEAR":
                //     hotelFaultSetDeleteForm.clear();
                //     break;
                case "WINDOW_FOOT_ACCEPT":

                    if(hotelBookingCancelDeleteForm.getItem("AA101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/deleteHotelBookingCancelFormData",
                        hotelBookingCancelDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            hotelBookingCancelDeleteForm.clear();
                            hotelBookingCancelDeleteForm.setValue({"AB001": "0"});

                            axios.get(apiServer+"/globalVerb/loadHotelBookingCancelGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        hotelBookingCancelWindow2.hide();
                                        hotelBookingCancelGrid.data.removeAll();
                                        hotelBookingCancelGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "WINDOW_FOOT_CLOSE":
                    hotelBookingCancelWindow2.hide();
                    break;
                default:
                    break;
            }
        })

        let hotelBookingCancelDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "hotelBookingCancelDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "hotelBookingCancelDeleteForm"},
            ]
        });

        const hotelBookingCancelDeleteForm = new DHXForm(null,{
            rows: hotelBookingCancelDeleteFormConfig
        });

        hotelBookingCancelWindow2.attach(hotelBookingCancelDeleteFormLayout);
        hotelBookingCancelDeleteFormLayout.getCell("hotelBookingCancelDeleteForm").attach(hotelBookingCancelDeleteForm);



        // 主要產品設定

        const mainProductsSetTabLayout = new DHXLayout(null,{
            rows:[
                {id:"mainProductSetToolbar", height: "content"},
                {id: "mainProductsSetGrid"}
            ]
        });

        const mainProductsSetGrid = new DHXGrid(null,{
            columns: mainProductsSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "mainProductsSetGrid_edit": function (e, data) {
                        if(canEdit){
                            mainProductsSetWindow.header.data.update("title", {value: "編輯主要產品設定視窗"});
                            mainProductsSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });

                            axios.post(apiServer+"/globalVerb/loadMainProductsData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                mainProductsSetWindow.show();
                                mainProductsSetForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "mainProductsSetGrid_delete": function (e, data) {
                        if(canDelete){
                            mainProductsSetDeleteForm.clear();
                            mainProductsSetDeleteForm.getItem("deleteFormTitle").setValue("確定要刪除嗎?");

                            axios.post(apiServer+"/globalVerb/loadMainProductsData", {
                                itemId1: data.row.AA001
                            },headers).then((response) =>{
                                mainProductsSetWindow2.show();
                                mainProductsSetDeleteForm.setValue( response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.get(apiServer+"/globalVerb/loadMainProductsSetGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    mainProductsSetGrid.data.removeAll();
                    mainProductsSetGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        const toolbar5 = new DHXToolbar(null, {
        });

        toolbar5.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        mainProductsSetWindow.header.data.update("title", {value: "新增主要產品設定視窗"});
                        mainProductsSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        mainProductsSetWindow.show();
                        mainProductsSetForm.clear();
                        mainProductsSetForm.setValue({"AA001": "0"});
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.get(apiServer+"/globalVerb/loadMainProductsSetGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                mainProductsSetGrid.data.removeAll();
                                mainProductsSetGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar5.data.parse(JSON.parse(JSON.stringify(toolbar5Config)));

        dhxTabbar.getCell("mainProductSet").attach(mainProductsSetTabLayout);
        mainProductsSetTabLayout.getCell("mainProductSetToolbar").attach(toolbar5);
        mainProductsSetTabLayout.getCell("mainProductsSetGrid").attach(mainProductsSetGrid);

        const mainProductsSetWindow = new DHXWindow({
            title: "主要產品設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 280,
            width: 450,
            footer: true,
            css: "mainProductsSetWindow"
        });

        mainProductsSetWindow.footer.data.add({type:"spacer"});
        mainProductsSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        mainProductsSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        mainProductsSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        mainProductsSetWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    mainProductsSetForm.clear();
                    mainProductsSetForm.setValue({"AA001": "0"});
                    break;
                case "WINDOW_FOOT_ACCEPT":

                    if(mainProductsSetForm.getItem("AA100").getValue().replace(/^\s|\s$/g,"") === ""){
                        DHXMessage({
                            text: "請填寫主要產品代號",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(mainProductsSetForm.getItem("AA101").getValue().replace(/^\s|\s$/g,"") === ""){
                        DHXMessage({
                            text: "請填寫主要產品名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/checkMainProductsNo",
                        mainProductsSetForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "主要產品代號重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            axios.post(apiServer+"/globalVerb/checkMainProductsName",
                                mainProductsSetForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: "主要產品名稱重複",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }else{
                                    axios.post(apiServer+"/globalVerb/updateMainProductsFormData",
                                        mainProductsSetForm.getValue(),headers
                                    ).then((response) =>{
                                        if(response.data[0].result){
                                            DHXMessage({
                                                text: response.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--success",
                                                expire:5000
                                            });

                                            axios.get(apiServer+"/globalVerb/loadMainProductsSetGrid",headers)
                                                .then((response) =>{
                                                    if(response.status === 200){
                                                        mainProductsSetWindow.hide();
                                                        mainProductsSetGrid.data.removeAll();
                                                        mainProductsSetGrid.data.parse(response.data);
                                                    }else{
                                                        console.log("Server Error")
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    })
                                        .catch((error) => {
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    mainProductsSetWindow.hide();
                    break;
                default:
                    break;
            }
        })

        const mainProductsSetForm = new DHXForm(null,{
            rows: mainProductsSetFormConfig
        });

        mainProductsSetWindow.attach(mainProductsSetForm);

        const mainProductsSetWindow2 = new DHXWindow({
            title: "刪除主要產品設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "mainProductsSetWindow2"
        });

        mainProductsSetWindow2.footer.data.add({type:"spacer"});
        mainProductsSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        // hotelFaultSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        mainProductsSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        mainProductsSetWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                // case "WINDOW_FOOT_CLEAR":
                //     hotelFaultSetDeleteForm.clear();
                //     break;
                case "WINDOW_FOOT_ACCEPT":

                    if(mainProductsSetDeleteForm.getItem("AA107").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/deleteMainProductsFormData",
                        mainProductsSetDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            mainProductsSetDeleteForm.clear();
                            mainProductsSetDeleteForm.setValue({"AB001": "0"});

                            axios.get(apiServer+"/globalVerb/loadMainProductsSetGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        mainProductsSetWindow2.hide();
                                        mainProductsSetGrid.data.removeAll();
                                        mainProductsSetGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "WINDOW_FOOT_CLOSE":
                    mainProductsSetWindow2.hide();
                    break;
                default:
                    break;
            }
        })

        let mainProductsSetDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "mainProductsSetDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "mainProductsSetDeleteForm"},
            ]
        });

        const mainProductsSetDeleteForm = new DHXForm(null,{
            rows: mainProductsSetDeleteFormConfig
        });

        mainProductsSetWindow2.attach(mainProductsSetDeleteFormLayout);
        mainProductsSetDeleteFormLayout.getCell("mainProductsSetDeleteForm").attach(mainProductsSetDeleteForm);

        // 飯店提供服務設定
        let updateServiceTypeListGridId = "0";
        let serviceTypeListGridId = "0";
        let serviceSubTypeListGridId = "0";

        const hotelServiceTabLayout = new DHXLayout(null,{
            type: "none",
            cols:[
                {
                    type:"none",
                    rows:[
                        {id:"serviceTypeToolbarLayout", height: "content", width: "content"},
                        {id:"serviceTypeLayout", width: "content"}
                    ]
                },
                {
                    type:"none",
                    rows:[
                        {id:"serviceSubTypeToolbarLayout", height: "content", width: "content"},
                        {id:"serviceSubTypeLayout", width: "content"}
                    ]
                }
            ]
        });

        const toolbar6 = new DHXToolbar(null, {});

        toolbar6.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        if(toolbar6.getState('typeInput').replace(/\s*/g,"") === ""){
                            DHXMessage({
                                text: "請填寫服務類別名稱",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }
                        axios.post(apiServer+"/globalVerb/checkServiceTypeName",{
                                itemId1: toolbar6.getState('typeInput'),
                                itemId2: updateServiceTypeListGridId
                        },headers).then((response) =>{
                            if(response.data[0].result){
                                DHXMessage({
                                    text: "服務類別名稱重複",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{
                                axios.post(apiServer+"/globalVerb/updateServiceType",{
                                    itemId1: toolbar6.getState('typeInput'),
                                    itemId2: updateServiceTypeListGridId
                                },headers).then((response) => {

                                    if(response.status === 200) {
                                        if (response.data[0].result) {

                                            DHXMessage({
                                                text: response.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--success",
                                                expire:5000
                                            });

                                            toolbar6.setState({"typeInput": ""});
                                            updateServiceTypeListGridId = "0";

                                            axios.post(apiServer+"/globalVerb/loadHotelServiceTypeGrid",{
                                                itemId1:hotel
                                            },headers)
                                                .then((response) =>{
                                                    if(response.status === 200){
                                                        serviceTypeListGrid.data.removeAll();
                                                        serviceTypeListGrid.data.parse(response.data);
                                                    }else{
                                                        console.log("Server Error")
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });

                                            axios.post(apiServer+"/globalVerb/loadHotelServiceSubTypeGrid",{
                                                itemId1:hotel
                                            },headers)
                                                .then((response) =>{
                                                    if(response.status === 200){
                                                        serviceSubTypeListGrid.data.removeAll();
                                                        serviceSubTypeListGrid.data.parse(response.data);
                                                    }else{
                                                        console.log("Server Error")
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }

                                }).catch((error) =>{
                                    console.log(error);
                                })
                            }
                        })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });

                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }

                    if(toolbar6.getState("typeInput").replace(/\s*/g,"") === ""){
                        DHXMessage({
                            text: "請填寫服務類別名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    break;
                case "reload":
                    toolbar6.setState({"typeInput": ""});
                    updateServiceTypeListGridId = "0";
                    serviceTypeListGridId = "0";
                    axios.post(apiServer+"/globalVerb/loadHotelServiceTypeGrid",{
                        itemId1:hotel
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                serviceTypeListGrid.data.removeAll();
                                serviceTypeListGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar6.data.parse(JSON.parse(JSON.stringify(toolbar6Config)));

        const serviceTypeListGrid = new DHXGrid(null,{
            columns: serviceTypeListGridColumn,
            selection: "row",
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "serviceTypeListGrid_edit": function (e, data) {
                        if(canEdit){
                            toolbar6.setState({"typeInput": ""});
                            toolbar6.setState({"typeInput": data.row.hotel_service_type_name});
                            updateServiceTypeListGridId = data.row.AE001;
                            // toolbar6.setState({"AE001": data.row.AE001});
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "serviceTypeListGrid_delete": function (e, data) {
                        if(canEdit){
                            serviceTypeDeleteForm.clear();
                            serviceTypeDeleteForm.setValue({"AE001":data.row.AE001,"AE101":data.row.hotel_service_type_name});
                            serviceTypeWindow.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }

                    }
                }
            }
        });
        serviceTypeListGrid.selection.events.on("AfterSelect", function(row, col){
            serviceTypeListGridId = row.AE001;
        });
        axios.post(apiServer+"/globalVerb/loadHotelServiceTypeGrid",{
            itemId1:hotel
        },headers)
            .then((response) =>{
                if(response.status === 200){
                    serviceTypeListGrid.data.removeAll();
                    serviceTypeListGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        hotelServiceTabLayout.getCell("serviceTypeToolbarLayout").attach(toolbar6);
        hotelServiceTabLayout.getCell("serviceTypeLayout").attach(serviceTypeListGrid);



        const serviceTypeWindow = new DHXWindow({
            title: "刪除提供服務類別視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "serviceTypeWindow"
        });

        serviceTypeWindow.footer.data.add({type:"spacer"});
        serviceTypeWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        // hotelFaultSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        serviceTypeWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        serviceTypeWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                // case "WINDOW_FOOT_CLEAR":
                //     hotelFaultSetDeleteForm.clear();
                //     break;
                case "WINDOW_FOOT_ACCEPT":

                    if(serviceTypeDeleteForm.getItem("AE103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/deleteServiceTypeFormData",{
                            itemId1: serviceTypeDeleteForm.getItem('AE001').getValue(),
                            itemId2: serviceTypeDeleteForm.getItem('AE103').getValue()
                        },headers).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            updateServiceTypeListGridId = "0";
                            serviceTypeListGridId = "0";

                            serviceTypeDeleteForm.clear();
                            serviceTypeDeleteForm.setValue({"AE001": "0"});

                            axios.post(apiServer+"/globalVerb/loadHotelServiceTypeGrid",{
                                itemId1:hotel
                            },headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        serviceTypeListGrid.data.removeAll();
                                        serviceTypeListGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            axios.post(apiServer+"/globalVerb/loadHotelServiceSubTypeGrid",{
                                itemId1:hotel
                            },headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        serviceTypeWindow.hide();
                                        serviceSubTypeListGrid.data.removeAll();
                                        serviceSubTypeListGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "WINDOW_FOOT_CLOSE":
                    serviceTypeWindow.hide();
                    break;
                default:
                    break;
            }
        })

        let serviceTypeDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "serviceTypeDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "serviceTypeDeleteForm"},
            ]
        });

        const serviceTypeDeleteForm = new DHXForm(null,{
            rows: serviceTypeDeleteFormConfig
        });

        serviceTypeWindow.attach(serviceTypeDeleteFormLayout);
        serviceTypeDeleteFormLayout.getCell("serviceTypeDeleteForm").attach(serviceTypeDeleteForm);




        const toolbar7 = new DHXToolbar(null, {});
        toolbar7.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        if(serviceTypeListGridId === "0" && serviceSubTypeListGridId === "0"){
                            DHXMessage({
                                text: "請先選擇左邊服務類別",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        if(toolbar7.getState('typeInput').replace(/\s*/g,"") === ""){
                            DHXMessage({
                                text: "請填寫服務項目名稱",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        axios.post(apiServer+"/globalVerb/checkServiceSubTypeName",{
                            itemId1: toolbar7.getState('typeInput'),
                            itemId2: serviceTypeListGridId,
                            itemId3: serviceSubTypeListGridId
                        },headers).then((response) =>{
                            if(response.data[0].result){
                                DHXMessage({
                                    text: "服務類別名稱重複",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{
                                axios.post(apiServer+"/globalVerb/updateServiceSubType",{
                                    itemId1: toolbar7.getState('typeInput'),
                                    itemId2: serviceTypeListGridId,
                                    itemId3: serviceSubTypeListGridId
                                },headers).then((response) => {

                                    if(response.status === 200) {
                                        if (response.data[0].result) {

                                            DHXMessage({
                                                text: response.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--success",
                                                expire:5000
                                            });

                                            toolbar7.setState({"typeInput": ""});
                                            serviceSubTypeListGridId = "0";

                                            axios.post(apiServer+"/globalVerb/loadHotelServiceSubTypeGrid",{
                                                itemId1:hotel
                                            },headers)
                                                .then((response) =>{
                                                    if(response.status === 200){
                                                        serviceSubTypeListGrid.data.removeAll();
                                                        serviceSubTypeListGrid.data.parse(response.data);
                                                    }else{
                                                        console.log("Server Error")
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "Service Sub Type Insert/Update Error",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }

                                }).catch((error) =>{
                                    console.log(error);
                                })
                            }
                        })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }

                    break;
                case "reload":

                    toolbar7.setState({"typeInput": ""});
                    serviceSubTypeListGridId = "0";
                    axios.post(apiServer+"/globalVerb/loadHotelServiceSubTypeGrid",{
                        itemId1:hotel
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                serviceSubTypeListGrid.data.removeAll();
                                serviceSubTypeListGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    break;
                default:
                    break;
            }
        });
        toolbar7.data.parse(JSON.parse(JSON.stringify(toolbar7Config)));

        const serviceSubTypeListGrid = new DHXGrid(null,{
            columns: serviceSubTypeListGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "serviceSubTypeListGrid_edit": function (e, data) {
                        if(canEdit){
                            toolbar7.setState({"typeInput": ""});
                            toolbar7.setState({"typeInput": data.row.hotel_service_sub_type_name});
                            serviceSubTypeListGridId = data.row.AE001;
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "serviceSubTypeListGrid_delete": function (e, data) {
                        if(canDelete){
                            serviceSubTypeDeleteForm.clear();
                            serviceSubTypeDeleteForm.setValue({"AE001":data.row.AE001,"type":data.row.hotel_service_type_name,"subType":data.row.hotel_service_sub_type_name});
                            serviceSubTypeWindow.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });
        serviceSubTypeListGrid.selection.events.on("AfterSelect", function(row, col){
            serviceSubTypeListGridId = row.AE001;
        });

        axios.post(apiServer+"/globalVerb/loadHotelServiceSubTypeGrid",{
            itemId1:hotel
        },headers)
            .then((response) =>{
                if(response.status === 200){
                    serviceSubTypeListGrid.data.removeAll();
                    serviceSubTypeListGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        dhxTabbar.getCell("hotelServiceSet").attach(hotelServiceTabLayout);
        hotelServiceTabLayout.getCell("serviceSubTypeToolbarLayout").attach(toolbar7);
        hotelServiceTabLayout.getCell("serviceSubTypeLayout").attach(serviceSubTypeListGrid);

        const serviceSubTypeWindow = new DHXWindow({
            title: "刪除提供服務項目視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "serviceTypeWindow"
        });

        serviceSubTypeWindow.footer.data.add({type:"spacer"});
        serviceSubTypeWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        serviceSubTypeWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        serviceSubTypeWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                // case "WINDOW_FOOT_CLEAR":
                //     hotelFaultSetDeleteForm.clear();
                //     break;
                case "WINDOW_FOOT_ACCEPT":

                    if(serviceSubTypeDeleteForm.getItem("AE103").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/deleteServiceSubTypeFormData",{
                            itemId1: serviceSubTypeDeleteForm.getItem('AE001').getValue(),
                            itemId2: serviceSubTypeDeleteForm.getItem('AE103').getValue()
                        },headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });
                            serviceSubTypeListGridId = "0";
                            serviceSubTypeDeleteForm.clear();
                            serviceSubTypeDeleteForm.setValue({"AE001": "0"});

                            axios.post(apiServer+"/globalVerb/loadHotelServiceSubTypeGrid",{
                                itemId1:hotel
                            },headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        serviceSubTypeWindow.hide();
                                        serviceSubTypeListGrid.data.removeAll();
                                        serviceSubTypeListGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;

                case "WINDOW_FOOT_CLOSE":
                    serviceSubTypeWindow.hide();
                    break;
                default:
                    break;
            }
        })

        let serviceSubTypeDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id: "serviceSubTypeDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "serviceSubTypeDeleteForm"},
            ]
        });

        const serviceSubTypeDeleteForm = new DHXForm(null,{
            rows: serviceSubTypeDeleteFormConfig
        });

        serviceSubTypeWindow.attach(serviceSubTypeDeleteFormLayout);
        serviceSubTypeDeleteFormLayout.getCell("serviceSubTypeDeleteForm").attach(serviceSubTypeDeleteForm);


        // 單位設定
        let unitsSetListGridId = "0";

        const unitsTabLayout = new DHXLayout(null,{
            rows:[
                {id:"unitsToolbarLayout", height: "content"},
                {id:"unitsLayout"}
            ],
        });

        const toolbar8 = new DHXToolbar(null, {});

        toolbar8.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        unitsSetForm.clear();
                        unitsSetForm.setValue({"AD001":"0","AD022":"1"})
                        dhxUnitsSetWindow.header.data.update("title", {value: "新增單位設定視窗"});
                        dhxUnitsSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        dhxUnitsSetWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.get(apiServer+"/globalVerb/loadUnitsSetListGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                unitsSetListGrid.data.removeAll();
                                unitsSetListGrid.data.parse(response.data);
                            }else{
                                console.log("Server Error")
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar8.data.parse(JSON.parse(JSON.stringify(toolbar8Config)));

        const unitsSetListGrid = new DHXGrid(null,{
            columns: unitsSetListGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "unitsSetListGrid_edit": function (e, data) {
                        if(canEdit){
                            unitsSetForm.clear();
                            dhxUnitsSetWindow.header.data.update("title", {value: "編輯單位設定視窗"});
                            dhxUnitsSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                            axios.post(apiServer+"/globalVerb/loadUnitsSetFormData", {
                                itemId1: data.row.AD001
                            },headers).then((response) =>{
                                unitsSetForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxUnitsSetWindow.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "unitsSetListGrid_stop": function (e, data) {
                        if(canEdit){
                            unitsSetStopForm.clear();
                            unitsSetStopForm.getItem("stopFormTitle").setValue("確定要停用嗎?");
                            axios.post(apiServer+"/globalVerb/loadUnitsSetFormData", {
                                itemId1: data.row.AD001
                            },headers).then((response) =>{
                                unitsSetStopForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxUnitsSetWindow2.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "unitsSetListGrid_delete": function (e, data) {
                        if(canEdit){
                            unitsSetDeleteForm.clear();
                            unitsSetDeleteForm.getItem("deleteFormTitle").setValue("確定要停用嗎?");
                            axios.post(apiServer+"/globalVerb/loadUnitsSetFormData", {
                                itemId1: data.row.AD001
                            },headers).then((response) =>{
                                unitsSetDeleteForm.setValue(response.data[0]);
                            })
                                .catch((error) => {
                                    console.log(error);
                                });
                            dhxUnitsSetWindow3.show();
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.get(apiServer+"/globalVerb/loadUnitsSetListGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    unitsSetListGrid.data.removeAll();
                    unitsSetListGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        dhxTabbar.getCell("unitsSet").attach(unitsTabLayout);
        unitsTabLayout.getCell("unitsToolbarLayout").attach(toolbar8);
        unitsTabLayout.getCell("unitsLayout").attach(unitsSetListGrid);

        const dhxUnitsSetWindow = new DHXWindow({
            title: "新增單位視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 270,
            width: 400,
            footer: true,
            css: "dhxUnitsSetWindow",
        });

        dhxUnitsSetWindow.footer.data.add({type:"spacer"});
        dhxUnitsSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxUnitsSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        dhxUnitsSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxUnitsSetWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    unitsSetForm.clear();
                    unitsSetForm.setValue({"AD001": "0"});
                    unitsSetForm.getItem("AD022").setValue("1");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(unitsSetForm.getItem("AD100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫單位名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(unitsSetForm.getItem("AD022").getValue() === ""){
                        DHXMessage({
                            text: "請選擇狀態",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/checkUnitsName",
                        unitsSetForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "單位名稱重複",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }else{
                            axios.post(apiServer+"/globalVerb/updateUnitsSetFormData",
                                unitsSetForm.getValue(),headers
                            ).then((response) =>{
                                if(response.data[0].result){
                                    DHXMessage({
                                        text: response.data[0].message,
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--success",
                                        expire:5000
                                    });

                                    unitsSetForm.clear();
                                    unitsSetForm.setValue({"AD001": "0"});
                                    unitsSetForm.getItem("AD022").setValue("1");
                                    unitsSetForm.clear("validation");

                                    axios.get(apiServer+"/globalVerb/loadUnitsSetListGrid",headers)
                                        .then((response) =>{
                                            if(response.status === 200){
                                                dhxUnitsSetWindow.hide();
                                                unitsSetListGrid.data.removeAll();
                                                unitsSetListGrid.data.parse(response.data);
                                            }else{
                                                console.log("Server Error")
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    unitsSetForm.clear();
                    unitsSetForm.setValue({"AD001": "0"});
                    dhxUnitsSetWindow.hide();
                    break;
                default:
                    break;
            }
        });

        const unitsSetForm = new DHXForm(null,{
            rows: unitsSetFormConfig
        });

        dhxUnitsSetWindow.attach(unitsSetForm);

        const dhxUnitsSetWindow2 = new DHXWindow({
            title: "停用單位視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "dhxUnitsSetWindow2",
        });

        dhxUnitsSetWindow2.footer.data.add({type:"spacer"});
        dhxUnitsSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxUnitsSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxUnitsSetWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    unitsSetStopForm.validate(false);

                    if(unitsSetStopForm.getItem("AD101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/stopUnits",
                        unitsSetStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            unitsSetStopForm.clear();
                            unitsSetStopForm.setValue({"AD001": "0"});
                            unitsSetStopForm.clear("validation");

                            axios.get(apiServer+"/globalVerb/loadUnitsSetListGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        dhxUnitsSetWindow2.hide();
                                        unitsSetListGrid.data.removeAll();
                                        unitsSetListGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxUnitsSetWindow2.hide();
                    unitsSetStopForm.clear();
                    unitsSetStopForm.setValue({"AD001": "0"});
                    unitsSetStopForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let unitsSetStopFormLayout = new DHXLayout(null,{
            rows:[
                {id:"unitsSetStopFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_warning+" /></div>",
                    gravity: false
                },
                {id: "unitsSetStopForm"},
            ]
        });

        const unitsSetStopForm = new DHXForm(null,{
            rows: unitsSetStopFormConfig
        });

        dhxUnitsSetWindow2.attach(unitsSetStopFormLayout);
        unitsSetStopFormLayout.getCell("unitsSetStopForm").attach(unitsSetStopForm);

        const dhxUnitsSetWindow3 = new DHXWindow({
            title: "刪除單位視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "dhxUnitsSetWindow3",
        });

        dhxUnitsSetWindow3.footer.data.add({type:"spacer"});
        dhxUnitsSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxUnitsSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxUnitsSetWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    unitsSetDeleteForm.validate(false);

                    if(unitsSetDeleteForm.getItem("AD102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/deleteUnits",
                        unitsSetDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.data[0].result){
                            DHXMessage({
                                text: "更新成功",
                                icon:"dxi dxi-close",
                                css: "dhx_message--success",
                                expire:5000
                            });

                            unitsSetDeleteForm.clear();
                            unitsSetDeleteForm.setValue({"AD001": "0"});
                            unitsSetDeleteForm.clear("validation");

                            axios.get(apiServer+"/globalVerb/loadUnitsSetListGrid",headers)
                                .then((response) =>{
                                    if(response.status === 200){
                                        dhxUnitsSetWindow3.hide();
                                        unitsSetListGrid.data.removeAll();
                                        unitsSetListGrid.data.parse(response.data);
                                    }else{
                                        console.log("Server Error")
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxUnitsSetWindow3.hide();
                    unitsSetDeleteForm.clear();
                    unitsSetDeleteForm.setValue({"AD001": "0"});
                    unitsSetDeleteForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let unitsSetDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"unitsSetDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "unitsSetDeleteForm"},
            ]
        });

        const unitsSetDeleteForm = new DHXForm(null,{
            rows: unitsSetDeleteFormConfig
        });

        dhxUnitsSetWindow3.attach(unitsSetDeleteFormLayout);
        unitsSetDeleteFormLayout.getCell("unitsSetDeleteForm").attach(unitsSetDeleteForm);

        // 訂單來源設定
        let updateOrderSourceGridId = "0";
        let orderSourceGridId = "0";
        let orderSubSourceGridId = "0";
        let orderSourceName = "";

        const orderSourceSetTabLayout = new DHXLayout(null,{
            type: "none",
            cols:[
                {
                    type:"none",
                    rows:[
                        {id:"orderSourceSetToolbarLayout", height: "content", width: "content"},
                        {id:"orderSourceSetLayout", width: "content"}
                    ]
                },
                {
                    type:"none",
                    rows:[
                        {id:"orderSubSourceSetToolbarLayout", height: "content", width: "content"},
                        {id:"orderSubSourceSetLayout", width: "content"}
                    ]
                }
            ]
        });

        const toolbar9 = new DHXToolbar(null, {});

        toolbar9.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        orderSourceSetForm.clear();
                        orderSourceSetForm.setValue({"AF001":"0","AF022":"1"})
                        dhxOrderSourceSetWindow.header.data.update("title", {value: "新增訂單來源設定視窗"});
                        dhxOrderSourceSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        dhxOrderSourceSetWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    updateOrderSourceGridId = "0";
                    orderSourceGridId = "0";
                    axios.get(apiServer+"/globalVerb/loadOrderSourceSetGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    orderSourceSetGrid.data.removeAll();
                                    orderSourceSetGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar9.data.parse(JSON.parse(JSON.stringify(toolbar9Config)));

        const orderSourceSetGrid = new DHXGrid(null,{
            columns: orderSourceSetGridColumn,
            selection: "row",
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "orderSourceSetGrid_edit": function (e, data) {
                        if(canEdit){
                            orderSourceSetForm.clear();
                            dhxOrderSourceSetWindow.header.data.update("title", {value: "編輯訂單來源設定視窗"});
                            dhxOrderSourceSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                            axios.post(apiServer+"/globalVerb/loadOrderSourceSetFormData", {
                                itemId1: data.row.AF001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        updateOrderSourceGridId = data.row.AF001;
                                        orderSourceSetForm.setValue(response.data[0]);
                                        dhxOrderSourceSetWindow.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "orderSourceSetGrid_stop": function (e, data) {
                        if(canEdit){
                            orderSourceSetStopForm.clear();
                            orderSourceSetStopForm.getItem("stopFormTitle").setValue("確定要停用嗎?");
                            axios.post(apiServer+"/globalVerb/loadOrderSourceSetFormData", {
                                itemId1: data.row.AF001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        orderSourceSetStopForm.setValue(response.data[0]);
                                        dhxOrderSourceSetWindow2.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "orderSourceSetGrid_delete": function (e, data) {
                        if(canEdit){
                            orderSourceSetDeleteForm.clear();
                            orderSourceSetDeleteForm.getItem("deleteFormTitle").setValue("確定要停用嗎?");
                            axios.post(apiServer+"/globalVerb/loadOrderSourceSetFormData", {
                                itemId1: data.row.AF001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        orderSourceSetDeleteForm.setValue(response.data[0]);
                                        dhxOrderSourceSetWindow3.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        orderSourceSetGrid.selection.events.on("AfterSelect", function(row, col){
            orderSourceGridId = row.AF001;
            orderSourceName = row.orderSource_name;
        });

        axios.get(apiServer+"/globalVerb/loadOrderSourceSetGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    if(response.data){
                        orderSourceSetGrid.data.removeAll();
                        orderSourceSetGrid.data.parse(response.data);
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });

        dhxTabbar.getCell("orderSourceSet").attach(orderSourceSetTabLayout);
        orderSourceSetTabLayout.getCell("orderSourceSetToolbarLayout").attach(toolbar9);
        orderSourceSetTabLayout.getCell("orderSourceSetLayout").attach(orderSourceSetGrid);

        const dhxOrderSourceSetWindow = new DHXWindow({
            title: "新增訂單來源視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 270,
            width: 400,
            footer: true,
            css: "dhxOrderSourceSetWindow",
        });

        dhxOrderSourceSetWindow.footer.data.add({type:"spacer"});
        dhxOrderSourceSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxOrderSourceSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        dhxOrderSourceSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxOrderSourceSetWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    orderSourceSetForm.clear();
                    orderSourceSetForm.setValue({"AF001": "0"});
                    orderSourceSetForm.getItem("AF022").setValue("1");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(orderSourceSetForm.getItem("AF100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫訂單來源名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(orderSourceSetForm.getItem("AF022").getValue() === ""){
                        DHXMessage({
                            text: "請選擇狀態",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/checkOrderSourceName",
                        orderSourceSetForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result){
                                DHXMessage({
                                    text: "訂單來源名稱重複",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{
                                axios.post(apiServer+"/globalVerb/updateOrderSourceSetFormData",
                                    orderSourceSetForm.getValue(),headers
                                ).then((response2) =>{
                                    if(response2.status === 200){
                                        if(response2.data[0].result){
                                            DHXMessage({
                                                text: response2.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--success",
                                                expire:5000
                                            });

                                            orderSourceSetForm.clear();
                                            orderSourceSetForm.setValue({"AF001": "0"});
                                            orderSourceSetForm.getItem("AF022").setValue("1");
                                            orderSourceSetForm.clear("validation");

                                            axios.get(apiServer+"/globalVerb/loadOrderSourceSetGrid",headers)
                                                .then((response3) =>{
                                                    if(response3.status === 200){
                                                        if(response3.data){
                                                            dhxOrderSourceSetWindow.hide();
                                                            orderSourceSetGrid.data.removeAll();
                                                            orderSourceSetGrid.data.parse(response3.data);
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }

                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    orderSourceSetForm.clear();
                    orderSourceSetForm.setValue({"AF001": "0"});
                    dhxOrderSourceSetWindow.hide();
                    break;
                default:
                    break;
            }
        });

        const orderSourceSetForm = new DHXForm(null,{
            rows: orderSourceSetFormConfig
        });

        dhxOrderSourceSetWindow.attach(orderSourceSetForm);

        const dhxOrderSourceSetWindow2 = new DHXWindow({
            title: "停用訂單來源視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "dhxOrderSourceSetWindow2",
        });

        dhxOrderSourceSetWindow2.footer.data.add({type:"spacer"});
        dhxOrderSourceSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxOrderSourceSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxOrderSourceSetWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    orderSourceSetStopForm.validate(false);

                    if(orderSourceSetStopForm.getItem("AF101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/stopOrderSource",
                        orderSourceSetStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                orderSourceSetStopForm.clear();
                                orderSourceSetStopForm.setValue({"AF001": "0"});
                                orderSourceSetStopForm.clear("validation");

                                axios.get(apiServer+"/globalVerb/loadOrderSourceSetGrid",headers)
                                    .then((response) =>{
                                        if(response.status === 200){
                                            dhxOrderSourceSetWindow2.hide();
                                            orderSourceSetGrid.data.removeAll();
                                            orderSourceSetGrid.data.parse(response.data);
                                        }else{
                                            console.log("Server Error")
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderSourceSetWindow2.hide();
                    orderSourceSetStopForm.clear();
                    orderSourceSetStopForm.setValue({"AF001": "0"});
                    orderSourceSetStopForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let orderSourceSetStopFormLayout = new DHXLayout(null,{
            rows:[
                {id:"orderSourceSetStopFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_warning+" /></div>",
                    gravity: false
                },
                {id: "orderSourceSetStopForm"},
            ]
        });

        const orderSourceSetStopForm = new DHXForm(null,{
            rows: orderSourceSetStopFormConfig
        });

        dhxOrderSourceSetWindow2.attach(orderSourceSetStopFormLayout);
        orderSourceSetStopFormLayout.getCell("orderSourceSetStopForm").attach(orderSourceSetStopForm);

        const dhxOrderSourceSetWindow3 = new DHXWindow({
            title: "刪除訂單來源視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "dhxUnitsSetWindow3",
        });

        dhxOrderSourceSetWindow3.footer.data.add({type:"spacer"});
        dhxOrderSourceSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxOrderSourceSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxOrderSourceSetWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    orderSourceSetDeleteForm.validate(false);

                    if(orderSourceSetDeleteForm.getItem("AF102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/deleteOrderSource",
                        orderSourceSetDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                orderSourceSetDeleteForm.clear();
                                orderSourceSetDeleteForm.setValue({"AF001": "0"});
                                orderSourceSetDeleteForm.clear("validation");

                                axios.get(apiServer+"/globalVerb/loadOrderSourceSetGrid",headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                dhxOrderSourceSetWindow3.hide();
                                                orderSourceSetGrid.data.removeAll();
                                                orderSourceSetGrid.data.parse(response2.data);
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderSourceSetWindow3.hide();
                    orderSourceSetDeleteForm.clear();
                    orderSourceSetDeleteForm.setValue({"AF001": "0"});
                    orderSourceSetDeleteForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let orderSourceSetDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"orderSourceSetDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "orderSourceSetDeleteForm"},
            ]
        });

        const orderSourceSetDeleteForm = new DHXForm(null,{
            rows: orderSourceSetDeleteFormConfig
        });

        dhxOrderSourceSetWindow3.attach(orderSourceSetDeleteFormLayout);
        orderSourceSetDeleteFormLayout.getCell("orderSourceSetDeleteForm").attach(orderSourceSetDeleteForm);

        //銷售來源
        const toolbar10 = new DHXToolbar(null, {});

        toolbar10.events.on("click", function(id,e){
            switch (id) {
                case "add":
                    if(canEdit){
                        if(orderSourceGridId === "0"){
                            DHXMessage({
                                text: "請先選擇左邊隸屬的訂單來源",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                            break;
                        }

                        orderSubSourceSetForm.clear();
                        orderSubSourceSetForm.setValue({"AF001":"0","AF022":"1","AF030":orderSourceGridId})
                        dhxOrderSubSourceSetWindow.header.data.update("title", {value: "新增銷售來源設定視窗"});
                        dhxOrderSubSourceSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: false });
                        dhxOrderSubSourceSetWindow.show();
                    }else{
                        DHXMessage({
                            text: "請先確認擁有編輯的權限",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                    break;
                case "reload":
                    axios.get(apiServer+"/globalVerb/loadOrderSubSourceSetGrid",headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    orderSubSourceSetGrid.data.removeAll();
                                    orderSubSourceSetGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                default:
                    break;
            }
        });
        toolbar10.data.parse(JSON.parse(JSON.stringify(toolbar10Config)));

        const orderSubSourceSetGrid = new DHXGrid(null,{
            columns: orderSubSourceSetGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "orderSubSourceSetGrid_edit": function (e, data) {
                        if(canEdit){
                            orderSubSourceSetForm.clear();
                            dhxOrderSubSourceSetWindow.header.data.update("title", {value: "編輯銷售來源設定視窗"});
                            dhxOrderSubSourceSetWindow.footer.data.update("WINDOW_FOOT_CLEAR", { disabled: true });
                            axios.post(apiServer+"/globalVerb/loadOrderSubSourceSetFormData", {
                                itemId1: data.row.AF001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        orderSubSourceSetForm.setValue(response.data[0]);
                                        dhxOrderSubSourceSetWindow.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }

                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "orderSubSourceSetGrid_stop": function (e, data) {
                        if(canEdit){
                            orderSubSourceSetStopForm.clear();
                            orderSubSourceSetStopForm.getItem("stopFormTitle").setValue("確定要停用嗎?");
                            axios.post(apiServer+"/globalVerb/loadOrderSubSourceSetFormData", {
                                itemId1: data.row.AF001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        orderSubSourceSetStopForm.setValue(response.data[0]);
                                        dhxOrderSubSourceSetWindow2.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    },
                    "orderSubSourceSetGrid_delete": function (e, data) {
                        if(canEdit){
                            orderSubSourceSetDeleteForm.clear();
                            orderSubSourceSetDeleteForm.getItem("deleteFormTitle").setValue("確定要停用嗎?");
                            axios.post(apiServer+"/globalVerb/loadOrderSubSourceSetFormData", {
                                itemId1: data.row.AF001
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        orderSubSourceSetDeleteForm.setValue(response.data[0]);
                                        dhxOrderSubSourceSetWindow3.show();
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                            .catch((error) => {
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有刪除的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        axios.get(apiServer+"/globalVerb/loadOrderSubSourceSetGrid",headers)
            .then((response) =>{
                if(response.status === 200){
                    if(response.data){
                        orderSubSourceSetGrid.data.removeAll();
                        orderSubSourceSetGrid.data.parse(response.data);
                    }else{
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    }
                }else{
                    DHXMessage({
                        text: "連線異常，請重新整理",
                        icon:"dxi dxi-close",
                        css: "dhx_message--error",
                        expire:5000
                    });
                }
            })
            .catch((error) => {
                DHXMessage({
                    text: "連線異常，請重新整理",
                    icon:"dxi dxi-close",
                    css: "dhx_message--error",
                    expire:5000
                });
            });
        orderSourceSetTabLayout.getCell("orderSubSourceSetToolbarLayout").attach(toolbar10);
        orderSourceSetTabLayout.getCell("orderSubSourceSetLayout").attach(orderSubSourceSetGrid);

        const dhxOrderSubSourceSetWindow = new DHXWindow({
            title: "新增銷售來源設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 420,
            width: 550,
            footer: true,
            css: "dhxOrderSubSourceSetWindow",
        });

        dhxOrderSubSourceSetWindow.footer.data.add({type:"spacer"});
        dhxOrderSubSourceSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxOrderSubSourceSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClear)));
        dhxOrderSubSourceSetWindow.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxOrderSubSourceSetWindow.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_CLEAR":
                    orderSubSourceSetForm.clear();
                    orderSubSourceSetForm.setValue({"AF001": "0"});
                    orderSubSourceSetForm.getItem("AF022").setValue("1");
                    orderSubSourceSetForm.getItem("AF030").setValue("0");
                    break;
                case "WINDOW_FOOT_ACCEPT":
                    if(orderSubSourceSetForm.getItem("AF100").getValue() === ""){
                        DHXMessage({
                            text: "請填寫銷售來源名稱",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    if(orderSubSourceSetForm.getItem("AF022").getValue() === ""){
                        DHXMessage({
                            text: "請選擇狀態",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/checkOrderSubSourceName",
                        orderSubSourceSetForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result){
                                DHXMessage({
                                    text: "銷售來源名稱重複",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }else{
                                axios.post(apiServer+"/globalVerb/updateOrderSubSourceSetFormData",
                                    orderSubSourceSetForm.getValue(),headers
                                ).then((response2) =>{
                                    if(response2.status === 200){
                                        if(response2.data[0].result){
                                            DHXMessage({
                                                text: response2.data[0].message,
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--success",
                                                expire:5000
                                            });

                                            orderSubSourceSetForm.clear();
                                            orderSubSourceSetForm.setValue({"AF001": "0"});
                                            orderSubSourceSetForm.getItem("AF022").setValue("1");
                                            orderSubSourceSetForm.clear("validation");

                                            axios.get(apiServer+"/globalVerb/loadOrderSubSourceSetGrid",headers)
                                                .then((response3) =>{
                                                    if(response3.status === 200){
                                                        if(response3.data){

                                                            dhxOrderSubSourceSetWindow.hide();
                                                            orderSubSourceSetGrid.data.removeAll();
                                                            orderSubSourceSetGrid.data.parse(response3.data);
                                                        }else{
                                                            DHXMessage({
                                                                text: "連線異常，請重新整理",
                                                                icon:"dxi dxi-close",
                                                                css: "dhx_message--error",
                                                                expire:5000
                                                            });
                                                        }
                                                    }else{
                                                        DHXMessage({
                                                            text: "連線異常，請重新整理",
                                                            icon:"dxi dxi-close",
                                                            css: "dhx_message--error",
                                                            expire:5000
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    DHXMessage({
                                                        text: "連線異常，請重新整理",
                                                        icon:"dxi dxi-close",
                                                        css: "dhx_message--error",
                                                        expire:5000
                                                    });
                                                });
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    }else{
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                })
                                .catch((error) => {
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    orderSubSourceSetForm.clear();
                    orderSubSourceSetForm.setValue({"AF001": "0"});
                    dhxOrderSubSourceSetWindow.hide();
                    break;
                default:
                    break;
            }
        });

        const orderSubSourceSetForm = new DHXForm(null,{
            rows: orderSubSourceSetFormConfig
        });

        dhxOrderSubSourceSetWindow.attach(orderSubSourceSetForm);

        const dhxOrderSubSourceSetWindow2 = new DHXWindow({
            title: "停用銷售來源視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "dhxOrderSubSourceSetWindow2",
        });

        dhxOrderSubSourceSetWindow2.footer.data.add({type:"spacer"});
        dhxOrderSubSourceSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxOrderSubSourceSetWindow2.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxOrderSubSourceSetWindow2.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    orderSubSourceSetStopForm.validate(false);

                    if(orderSubSourceSetStopForm.getItem("AF101").getValue() === ""){
                        DHXMessage({
                            text: "請填寫停用原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/stopOrderSource",
                        orderSubSourceSetStopForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                orderSubSourceSetStopForm.clear();
                                orderSubSourceSetStopForm.setValue({"AF001": "0"});
                                orderSubSourceSetStopForm.clear("validation");

                                axios.get(apiServer+"/globalVerb/loadOrderSubSourceSetGrid",headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                dhxOrderSubSourceSetWindow2.hide();
                                                orderSubSourceSetGrid.data.removeAll();
                                                orderSubSourceSetGrid.data.parse(response2.data);
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderSubSourceSetWindow2.hide();
                    orderSubSourceSetStopForm.clear();
                    orderSubSourceSetStopForm.setValue({"AF001": "0"});
                    orderSubSourceSetStopForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let orderSubSourceSetStopFormLayout = new DHXLayout(null,{
            rows:[
                {id:"orderSubSourceSetStopFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_warning+" /></div>",
                    gravity: false
                },
                {id: "orderSubSourceSetStopForm"},
            ]
        });

        const orderSubSourceSetStopForm = new DHXForm(null,{
            rows: orderSubSourceSetStopFormConfig
        });

        dhxOrderSubSourceSetWindow2.attach(orderSubSourceSetStopFormLayout);
        orderSubSourceSetStopFormLayout.getCell("orderSubSourceSetStopForm").attach(orderSubSourceSetStopForm);

        const dhxOrderSubSourceSetWindow3 = new DHXWindow({
            title: "刪除銷售來源設定視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            width:550,
            height: 570,
            footer: true,
            css: "dhxUnitsSetWindow3",
        });

        dhxOrderSubSourceSetWindow3.footer.data.add({type:"spacer"});
        dhxOrderSubSourceSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootClose)));
        dhxOrderSubSourceSetWindow3.footer.data.add( JSON.parse( JSON.stringify(globalWindowFootAccept)));

        dhxOrderSubSourceSetWindow3.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":
                    orderSubSourceSetDeleteForm.validate(false);

                    if(orderSubSourceSetDeleteForm.getItem("AF102").getValue() === ""){
                        DHXMessage({
                            text: "請填寫刪除原因",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    axios.post(apiServer+"/globalVerb/deleteOrderSubSource",
                        orderSubSourceSetDeleteForm.getValue(),headers
                    ).then((response) =>{
                        if(response.status === 200){
                            if(response.data[0].result){
                                DHXMessage({
                                    text: "更新成功",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                orderSubSourceSetDeleteForm.clear();
                                orderSubSourceSetDeleteForm.setValue({"AF001": "0"});
                                orderSubSourceSetDeleteForm.clear("validation");

                                axios.get(apiServer+"/globalVerb/loadOrderSubSourceSetGrid",headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                dhxOrderSubSourceSetWindow3.hide();
                                                orderSubSourceSetGrid.data.removeAll();
                                                orderSubSourceSetGrid.data.parse(response2.data);
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }
                        }

                    })
                    .catch((error) => {
                        DHXMessage({
                            text: "連線異常，請重新整理",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                    });
                    break;
                case "WINDOW_FOOT_CLOSE":
                    dhxOrderSubSourceSetWindow3.hide();
                    orderSubSourceSetDeleteForm.clear();
                    orderSubSourceSetDeleteForm.setValue({"AF001": "0"});
                    orderSubSourceSetDeleteForm.clear("validation");
                    break;
                default:
                    break;
            }
        });

        let orderSubSourceSetDeleteFormLayout = new DHXLayout(null,{
            rows:[
                {id:"orderSubSourceSetDeleteFormImg",
                    height: 170,
                    html: "<div style='text-align: center;'><img width='35%' src="+Image_error+" /></div>",
                    gravity: false
                },
                {id: "orderSubSourceSetDeleteForm"},
            ]
        });

        const orderSubSourceSetDeleteForm = new DHXForm(null,{
            rows: orderSubSourceSetDeleteFormConfig
        });

        dhxOrderSubSourceSetWindow3.attach(orderSubSourceSetDeleteFormLayout);
        orderSubSourceSetDeleteFormLayout.getCell("orderSubSourceSetDeleteForm").attach(orderSubSourceSetDeleteForm);

        return () =>{
            dhxTabbar && dhxTabbar.destructor();
        }

    }, [canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
                <MDBox py={3}>
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <CardHeader title={"公用參數設定"}/>
                                <MDBox py={1} px={1}>
                                    <div
                                        ref={DHXTabberGlobalTab}
                                        style={{height:"700px", width:"100%"}}>
                                    </div>

                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>

                </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

GlobalVarSetting.propTypes = {

};

export default GlobalVarSetting;
