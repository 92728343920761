let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

let dateTime = new Date();
dateTime = dateTime.setDate(dateTime.getDate()+1);
dateTime = new Date(dateTime);

export const changeRoom_roomFormConfig = [
    { type: "input", name: "AG001", hidden: true, value: "0" },
    { type: "input", name: "AG002", hidden: true, value: "0" },
    { type: "input", name: "AG003", hidden: true, value: "0" },
    { type: "input", name: "AG131", hidden: true, value: "0" },
    { type: "input", name: "AG132", hidden: true, value: "0" },
    { type: "input", name: "AG133", hidden: true, value: "0" },
    { type: "input", name: "AG134", hidden: true, value: "0" },
    { type: "input", name: "AA002", hidden: true, value: "0" },
    { type: "input", name: "AA003", hidden: true, value: "0" },
    { type: "input", name: "AA004", hidden: true, value: "0" },
    { type: "input", name: "roomTypeName", hidden: true, value: "" },
    {
        cols: [
            {
                type: "datepicker",
                label: "*入住日期：",
                // timePicker: true,
                timeFormat: 24,
                dateFormat: "%Y-%m-%d 15:00",
                valueFormat:"string",
                width: "49.7%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin dateRange",
                name: "AG119",
            },
            {
                type: "datepicker",
                label: "*退房日期：",
                // timePicker: true,
                timeFormat: 24,
                dateFormat: "%Y-%m-%d 11:00",
                valueFormat:"string",
                // value:`${getDate(dateTime)} 11:00`,
                width: "49.7%",
                mark: function (date) {
                    if (getDate(date) === getDate(new Date)) {
                        return "highlight-date";
                    }
                },
                css:"rightMargin dateRange",
                name: "AG120",
            }
        ],
    },
    {
        cols: [
            {
                type: "combo",
                multiselection: false,
                label: "*房型：",
                labelPosition: "top",
                css:"rightMargin",
                data: [
                    { value: "---請選擇---", id: "0" },
                ],
                width: "49.7%",
                readOnly:true,
                disabled:true,
                name: "AG004",
            },
            {
                type: "combo",
                multiselection: false,
                label: "*房間名稱：",
                labelPosition: "top",
                css:"rightMargin",
                data: [
                    { value: "---請選擇---", id: "0" },
                ],
                width: "49.7%",
                readOnly:true,
                disabled:true,
                name: "AG005",
            },
        ],
    },
    {
        type: "checkbox",
        label: "",
        labelWidth: "0px",
        labelPosition: "left",
        text: "故障通報(房間狀態為待入住或已入住才可使用)",
        // checked: true,
        name: "needReportFault",
        id: "needReportFault",
    },
    {
        css: "row1",
        rows:[
            {
                cols:[
                    {
                        type: "input",
                        label: "房間名稱：",
                        css:"rightMargin",
                        disabled:true,
                        placeholder: "",
                        readOnly: true,
                        width: "49.7%",
                        name: "roomName",
                    },
                    {
                        type: "datepicker",
                        label: "*通報日期：",
                        dateFormat: "%Y-%m-%d",
                        width: "49.7%",
                        mark: function (date) {
                            if (getDate(date) === getDate(new Date)) {
                                return "highlight-date";
                            }
                        },
                        css:"rightMargin",
                        name: "AA103",
                    },
                ],
            },
            {
                cols:[
                    {
                        type: "datepicker",
                        label: "*鎖定開始日期：",
                        dateFormat: "%Y-%m-%d 00:00:00",
                        width: "49.7%",
                        mark: function (date) {
                            if (getDate(date) === getDate(new Date)) {
                                return "highlight-date";
                            }
                        },
                        css:"rightMargin",
                        name: "AA104",
                    },
                    {
                        type: "datepicker",
                        label: "*鎖定結束日期：",
                        dateFormat: "%Y-%m-%d 23:59:59",
                        width: "49.7%",
                        mark: function (date) {
                            if (getDate(date) === getDate(new Date)) {
                                return "highlight-date";
                            }
                        },
                        css:"rightMargin",
                        name: "AA105",
                    },
                ],
            },
            {
                type: "select",
                label: "*故障原因：",
                options: [
                    {
                        value: "0",
                        content: "---請選擇---",
                        disabled: true
                    },
                ],
                name: "AA006",
            },
            {
                type: "textarea",
                label: "備註：",
                value: "",
                height: 100,
                name: "AA106",
            },
        ],

    },
];