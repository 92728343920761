import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import DashboardNavbar from "../../../pages/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import MDAlert from "components/MDAlert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {CardHeader} from "@mui/material";
import DashboardLayout from "../../../pages/LayoutContainers/DashboardLayout";
import {
    Layout as DHXLayout,
    Toolbar as DHXToolbar,
    Grid as DHXGrid,
    message as DHXMessage,
    Window as DHXWindow,
    Form as DHXForm,
} from 'dhx-suite';
import 'dhx-suite/codebase/suite.min.css'
import axios from "axios";
import {housekeepingReportGridColumn} from "./componentsConfig/housekeepingReportGridColumn";
import {toolbarConfig} from "./componentsConfig/toolbarConfig";
import Image_error from '../../../assets/images/error.png'
import Image_warning from '../../../assets/images/warning.png'
import api_url from "../../../context/UrlContext.js"
import './style.css';
import {
    WindowFootAccept,
    WindowFootClose
} from "../../handOverControl/housekeepingReport/componentsConfig/windowConfig";
import {housekeepingReportFormConfig} from "./componentsConfig/housekeepingReportFormConfig";
import Progress from "../../../assets/images/icons8-iphone-spinner.gif";

let getDate = (date)=> {
    let startDateValue = new Date(date);
    let year1 = startDateValue.getFullYear();
    let month1 = startDateValue.getMonth()+1;
    let day1 = startDateValue.getDate();

    return `${year1}-${month1.toString().padStart(2,'0')}-${day1.toString().padStart(2,'0')}`;
}

const HousekeepingReport = props => {

    const apiServer = api_url;
    const headers = {headers:{authorization: "Bearer "+JSON.parse(localStorage.getItem("digital_token"))}};
    const navigate = useNavigate();

    const [canAccess ,setCanAccess] = useState(false);
    const [canEdit ,setCanEdit] = useState(false);
    const [canDelete ,setCanDelete] = useState(false);
    const [canPrint ,setCanPrint] = useState(false);

    let hotelsListId = "0";

    //飯店切換
    const [hotel, setHotel] = useState("0");

    const changeHotelHandler = (hotel) => {
        setHotel(hotel);
        hotelsListId = hotel;
    };

    const [isLoading,setIsloading] = useState(true);

    const DHXHousekeepingReportLayout = useRef(null);

    let currentDate = "";
    let searchType = "day";

    useEffect(() => {

        currentDate = getDate(Date.now());

        axios.post(apiServer+"/checkLoginState",{itemId1:JSON.parse(localStorage.getItem("digital_token"))},headers)
            .then((response)=>{
                if(response.data == 0){
                    navigate('/login');
                }else{
                    axios.post(apiServer+"/userProfileSet/loadPermiss",{itemId1:20},headers)
                        .then((response2)=>{
                            if(response2.status == 200){
                                let p = response2.data.split(',');

                                (p[0] == 1)?setCanAccess(true):setCanAccess(false);
                                (p[1] == 1)?setCanEdit(true):setCanEdit(false);
                                (p[2] == 1)?setCanDelete(true):setCanDelete(false);
                                (p[3] == 1)?setCanPrint(true):setCanPrint(false);

                                setIsloading(false);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });
        
        const dhxProgressWindow1 = new DHXWindow({
            modal: true,
            resizable: true,
            closable:false,
            height: 150,
            width:150,
            html:"<div style='text-align: center;'><img src="+Progress+" /><br/><span>資料載入中</span></div>",
            css: "dhxProgressWindow1"
        });

        const dhxHousekeepingReportLayout = new DHXLayout(DHXHousekeepingReportLayout.current,{
            rows:[
                {id:"housekeepingReportGridToolbar", height: "content"},
                {id: "housekeepingReportGrid", height:"600px"},
            ]
        });

        const toolbar1 = new DHXToolbar(null, {});
        toolbar1.events.on("click", function(id,e){
            switch (id) {
                case "reload":
                    axios.post(apiServer+"/reports/searchHousekeepingReportGrid",{
                        itemId1:hotel,itemId2:searchType,itemId3:toolbar1.getState("cleanStatus"),itemId4:toolbar1.getState("lostPropertyStatus"),itemId5:toolbar1.getState("startDate"),itemId6:toolbar1.getState("endDate")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    housekeepingReportGrid.data.removeAll();
                                    housekeepingReportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "day":
                    searchType = "day";
                    axios.post(apiServer+"/reports/searchHousekeepingReportGrid",{
                        itemId1:hotel,itemId2:"day",itemId3:toolbar1.getState("cleanStatus"),itemId4:toolbar1.getState("lostPropertyStatus")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    housekeepingReportGrid.data.removeAll();
                                    housekeepingReportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "week":
                    searchType = "week";
                    axios.post(apiServer+"/reports/searchHousekeepingReportGrid",{
                        itemId1:hotel,itemId2:"week",itemId3:toolbar1.getState("cleanStatus"),itemId4:toolbar1.getState("lostPropertyStatus")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    housekeepingReportGrid.data.removeAll();
                                    housekeepingReportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "month":
                    searchType = "month";
                    axios.post(apiServer+"/reports/searchHousekeepingReportGrid",{
                        itemId1:hotel,itemId2:"month",itemId3:toolbar1.getState("cleanStatus"),itemId4:toolbar1.getState("lostPropertyStatus")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    housekeepingReportGrid.data.removeAll();
                                    housekeepingReportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "year":
                    searchType = "year";
                    axios.post(apiServer+"/reports/searchHousekeepingReportGrid",{
                        itemId1:hotel,itemId2:"year",itemId3:toolbar1.getState("cleanStatus"),itemId4:toolbar1.getState("lostPropertyStatus")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    housekeepingReportGrid.data.removeAll();
                                    housekeepingReportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "search":
                    if((toolbar1.getState("startDate") === "" || toolbar1.getState("endDate") === "" || toolbar1.getState("startDate") === undefined || toolbar1.getState("endDate") === undefined)){
                        DHXMessage({
                            text: "請選擇日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }

                    if(toolbar1.getState("startDate") !== "" && toolbar1.getState("endDate") !== "" && toolbar1.getState("startDate") !== undefined && toolbar1.getState("endDate") !== undefined && (toolbar1.getState("startDate") > toolbar1.getState("endDate"))){
                        DHXMessage({
                            text: "開始日期不得大於結束日期",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    }
                    searchType = "range";
                    axios.post(apiServer+"/reports/searchHousekeepingReportGrid",{
                        itemId1:hotel,itemId2:"range",itemId3:toolbar1.getState("cleanStatus"),itemId4:toolbar1.getState("lostPropertyStatus"),itemId5:toolbar1.getState("startDate"),itemId6:toolbar1.getState("endDate")
                    },headers)
                        .then((response) =>{
                            if(response.status === 200){
                                if(response.data){
                                    housekeepingReportGrid.data.removeAll();
                                    housekeepingReportGrid.data.parse(response.data);
                                }else{
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            }else{
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        })
                        .catch((error) => {
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });
                    break;
                case "exportExcel":
                    housekeepingReportGrid.export.xlsx({
                        name:"匯出房務管理報表"+Date.now(),
                        url: "//export.dhtmlx.com/excel"
                    });
                    // axios.post(apiServer+"/reports/loadHousekeepingReportGrid",{itemId1:hotel,itemId2:currentDate},headers)
                    //     .then((response) =>{
                    //         if(response.status === 200){
                    //             housekeepingReportGrid.data.removeAll();
                    //             housekeepingReportGrid.data.parse(response.data);
                    //         }else{
                    //             console.log("Server Error")
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         console.log(error);
                    //     });
                    break;
                default:
                    break;
            }
        });
        toolbar1.data.parse(JSON.parse(JSON.stringify(toolbarConfig)));

        toolbar1.events.on("inputChange", function(id, newValue){
            if(id == "searchTime"){
                currentDate = getDate(toolbar1.getState("searchTime"));
                axios.post(apiServer+"/reports/loadHousekeepingReportGrid",{itemId1:hotel,itemId2:currentDate},headers)
                    .then((response) =>{
                        if(response.status === 200){
                            housekeepingReportGrid.data.removeAll();
                            housekeepingReportGrid.data.parse(response.data);
                        }else{
                            console.log("Server Error")
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });

        const housekeepingReportGrid = new DHXGrid(null,{
            columns: housekeepingReportGridColumn,
            autoWidth: true,
            resizable: true,
            eventHandlers: {
                onclick: {
                    "housekeepingReportGrid_edit": function (e, data) {
                        if(canEdit){
                            housekeepingReportForm.clear();
                            dhxProgressWindow1.show();
                            axios.post(apiServer+"/reports/loadHousekeepingReportFormData", {
                                itemId1: data.row.id
                            },headers).then((response) =>{
                                if(response.status === 200){
                                    if(response.data){
                                        housekeepingReportForm.setValue(response.data[0]);
                                        housekeepingReportForm.getItem("AK107").setValue(response.data[0].AK107);
                                        dhxProgressWindow1.hide();
                                        dhxHousekeepingReportWindow1.show();
                                    }else{
                                        dhxProgressWindow1.hide();
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    }
                                }else{
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                }
                            })
                                .catch((error) => {
                                    dhxProgressWindow1.hide();
                                    DHXMessage({
                                        text: "連線異常，請重新整理",
                                        icon:"dxi dxi-close",
                                        css: "dhx_message--error",
                                        expire:5000
                                    });
                                });
                        }else{
                            DHXMessage({
                                text: "請先確認擁有編輯的權限",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    }
                }
            }
        });

        const dhxHousekeepingReportWindow1 = new DHXWindow({
            title: "編輯打掃視窗",
            modal: true,
            resizable: true,
            movable: true,
            closable:true,
            viewportOverflow:true,
            height: 530,
            width: 635,
            footer: true,
            css: "dhxHousekeepingReportWindow1",
        });

        dhxHousekeepingReportWindow1.footer.data.add({type:"spacer"});
        dhxHousekeepingReportWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootClose)));
        dhxHousekeepingReportWindow1.footer.data.add( JSON.parse( JSON.stringify(WindowFootAccept)));

        dhxHousekeepingReportWindow1.footer.events.on("click",(itemId1) =>{
            switch (itemId1){
                case "WINDOW_FOOT_ACCEPT":

                    if(housekeepingReportForm.getItem("AK107").getValue() === true && housekeepingReportForm.getItem("AK108").getValue() === ""){
                        DHXMessage({
                            text: "請填寫歸還時間",
                            icon:"dxi dxi-close",
                            css: "dhx_message--error",
                            expire:5000
                        });
                        break;
                    };

                    console.log(housekeepingReportForm.getValue());

                    dhxProgressWindow1.show();
                    axios.post(apiServer+"/reports/updateHousekeepingReport", housekeepingReportForm.getValue()
                    ,headers).then((response1) =>{
                        if(response1.status === 200){
                            if(response1.data){
                                DHXMessage({
                                    text: response1.data[0].message,
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--success",
                                    expire:5000
                                });

                                housekeepingReportForm.clear();
                                dhxHousekeepingReportWindow1.hide();

                                axios.post(apiServer+"/reports/searchHousekeepingReportGrid",{
                                    itemId1:hotel,itemId2:searchType,itemId3:toolbar1.getState("cleanStatus"),itemId4:toolbar1.getState("lostPropertyStatus"),itemId5:toolbar1.getState("startDate"),itemId6:toolbar1.getState("endDate")
                                },headers)
                                    .then((response2) =>{
                                        if(response2.status === 200){
                                            if(response2.data){
                                                housekeepingReportGrid.data.removeAll();
                                                housekeepingReportGrid.data.parse(response2.data);
                                                dhxProgressWindow1.hide();
                                            }else{
                                                DHXMessage({
                                                    text: "連線異常，請重新整理",
                                                    icon:"dxi dxi-close",
                                                    css: "dhx_message--error",
                                                    expire:5000
                                                });
                                            }
                                        }else{
                                            DHXMessage({
                                                text: "連線異常，請重新整理",
                                                icon:"dxi dxi-close",
                                                css: "dhx_message--error",
                                                expire:5000
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        DHXMessage({
                                            text: "連線異常，請重新整理",
                                            icon:"dxi dxi-close",
                                            css: "dhx_message--error",
                                            expire:5000
                                        });
                                    });
                            }else{
                                dhxProgressWindow1.hide();
                                DHXMessage({
                                    text: "連線異常，請重新整理",
                                    icon:"dxi dxi-close",
                                    css: "dhx_message--error",
                                    expire:5000
                                });
                            }
                        }else{
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        }
                    })
                        .catch((error) => {
                            dhxProgressWindow1.hide();
                            DHXMessage({
                                text: "連線異常，請重新整理",
                                icon:"dxi dxi-close",
                                css: "dhx_message--error",
                                expire:5000
                            });
                        });

                    break;
                case "WINDOW_FOOT_CLOSE":
                    housekeepingReportForm.clear();
                    dhxHousekeepingReportWindow1.hide();
                    break;
                default:
                    break;
            }
        });

        const housekeepingReportForm = new DHXForm(null,{
            rows: housekeepingReportFormConfig
        });

        housekeepingReportForm.getItem("AK107").events.on("change", function(value) {
            housekeepingReportForm.getItem("AK108").setValue("");
            if(value === true){
                housekeepingReportForm.getItem("AK108").enable();
            }else{
                housekeepingReportForm.getItem("AK108").disable();
                housekeepingReportForm.getItem("AK108").clear();
            }
        });

        dhxHousekeepingReportWindow1.attach(housekeepingReportForm);

        axios.post(apiServer+"/reports/loadHousekeepingReportGrid",{itemId1:hotel},headers)
            .then((response) =>{
                if(response.status === 200){
                    housekeepingReportGrid.data.removeAll();
                    housekeepingReportGrid.data.parse(response.data);
                }else{
                    console.log("Server Error")
                }
            })
            .catch((error) => {
                console.log(error);
            });

        dhxHousekeepingReportLayout.getCell("housekeepingReportGridToolbar").attach(toolbar1);
        dhxHousekeepingReportLayout.getCell("housekeepingReportGrid").attach(housekeepingReportGrid);

        return () => {
            dhxHousekeepingReportLayout && dhxHousekeepingReportLayout.destructor();
        };
    }, [canAccess,canEdit,canDelete,canPrint,hotel]);

    return (
        <DashboardLayout>
            <DashboardNavbar onHotelChange={changeHotelHandler}/>
            {canAccess &&
            <MDBox py={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <h4 className={"gridTitle"}>房務管理報表</h4>
                            <MDBox py={1} px={1}>
                                <div ref={DHXHousekeepingReportLayout}></div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>

            </MDBox>
            }

            {!canAccess && !isLoading &&
            <MDAlert color={"error"}>請確認是否擁有檢視的權限</MDAlert>
            }

            {isLoading &&
            <MDAlert color={"warning"}>權限確認中，請稍後...</MDAlert>
            }
        </DashboardLayout>
    );
};

export default HousekeepingReport;
