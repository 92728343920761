export const housekeepingReportGridColumn = [
    { width:80, id: "item_no", header: [{ text: "項次" }],tooltip: false },
    { width:80, id: "action", header: [{ text: "動作" }],
        htmlEnable: true, align: "center"
    },
    { width:150, id: "checkOutDate", header: [{ text: "退房日期" }] },
    { width:150, id: "noticeDate", header: [{ text: "通知打掃時間" }] },
    { width:150, id: "room", header: [{ text: "房號" }] },
    { width:150, id: "clearnReason",align:"right", header: [ { text: "打掃原因" }] },
    { width:120, id: "housekeeper",align:"right", header: [ { text: "清潔人員" }] },
    { width:150, id: "cleanReportDate",align:"right", header: [ { text: "清潔後回報時間" }] },
    { width:150, id: "lostPropertyReport", header: [ { text: "遺失物通報" }] },
    { width:150, id: "lostPropertyReturnTime", header: [ { text: "遺失物歸還時間" }] },
    { width:150, id: "note", header: [ { text: "備註" }] },
]